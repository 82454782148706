import React from 'react';
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
const routes = require('../../../../routes'); // Use CommonJS require

function decodeHTMLEntities(encodedString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(encodedString, 'text/html');
  return doc.documentElement.textContent;
}

const Sitemap = () => {
  return (
    <>
    <Header en="/sitemap" fr="/plan-du-site" lang="fr" />
    <div className="container mainDiv ab aboutUs">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="about-us">Plan du site</h1>
          <div>
            <h2>Des pages</h2>
            <ul>
            {
              routes.staticFr.map((route, index) => {
                if(route.childs){
                  return(
                    <li key={index}>
                      <p><a href={route.path}>{route.title}</a></p>
                      <ul>
                      {
                        route.childs.map((child, i) => {
                          var indexi = index.toString()+i.toString()
                          if(child.childs){
                            return(
                              <li key={indexi}>
                                <p><a href={child.path}>{child.title}</a></p>
                                <ul>
                                {
                                  child.childs.map((children, j) => {
                                    var indexij = indexi.toString()+j.toString()
                                    return(
                                      <li key={indexij}>
                                        <p><a href={children.path}>{decodeHTMLEntities(children.title)}</a></p>
                                      </li>
                                    )
                                  })
                                }
                                </ul>
                              </li>
                            )
                          }else{
                            return(
                              <li key={indexi}>
                                <p><a href={child.path}>{decodeHTMLEntities(child.title)}</a></p>
                              </li>
                            )
                          }
                        })
                      }
                      </ul>
                    </li>
                  )
                }else{
                  return(
                    <li key={index}>
                      <p><a href={route.path}>{decodeHTMLEntities(route.title)}</a></p>
                    </li>
                  )
                }

              })
            }
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer lang="fr" />
  </>
  );
};

export default Sitemap;
