import React, { useState,useRef } from 'react';
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import $ from 'jquery';
import ReCAPTCHA from "react-google-recaptcha";
import Cf7FormWrapper from "./../../../cf7-form-wrapper"

function Form({ handler, isLoading, isSent, hasError }) {
  const [formState, setFormState] = useState({})
  const captchaRef = useRef(null);

  const handleFieldChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
    })
  }

  const handleFormSubmit = async(e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    if(!token){
      alert('Please click on the reCAPTCHA box.');
    }else{
      captchaRef.current.reset();
      const dataToSubmit = $(e.target).serializeArray().reduce(function(prev, curr) {
        prev[curr.name] = curr.value;
        return prev;
      }, {});
      dataToSubmit.grecaptcharesponse = token;
      dataToSubmit._wpcf7_recaptcha_response = token;
      formState._wpcf7_recaptcha_response = token;
      formState['g-recaptcha-response'] = token;
      formState._wpcf7 = '23861';
      formState['cf7sr-recaptcha'] = token;
      formState._wpcf7_unit_tag = "wpcf7-f23861-p23671-o1";
      handler(e, formState);
    }
  }

  return (
      <form id="ClientReffreal" action="" method="POST" encType="multipart/form-data" name="ClientReffreal" onSubmit={handleFormSubmit} >
        <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f23861-p23671-o1" />
        <Row>
          <Col xl={12}>
            <div className="form-group">
              <label>Client's Name: <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="client_name" type="text" name="client_name" placeholder="" onChange={(e) => handleFieldChange("contact-name", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Client's Email <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="client_email" type="email" name="client_email" placeholder="" onChange={(e) => handleFieldChange("contact_subject", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Client's Phone <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="client_phone" type="text" name="client_phone" placeholder="" onChange={(e) => handleFieldChange("contact_subject", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Client's Home Province <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="client_home_province" type="text" name="client_home_province" placeholder="" onChange={(e) => handleFieldChange("contact_subject", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Travelling to (Country?) <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="travelling_to" type="text" name="travelling_to" placeholder="" onChange={(e) => handleFieldChange("contact_subject", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Your Name <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="your_name" type="text" name="your_name" placeholder="" onChange={(e) => handleFieldChange("contact_subject", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Your Email <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="your_email" type="text" name="your_email" placeholder="" onChange={(e) => handleFieldChange("contact_subject", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <ReCAPTCHA sitekey="6LeUkOAUAAAAAKjgWJg-4bZ7lFiXG4hj7bW5ikBo" ref={captchaRef} />
          </Col>
          <br />
          <Col xl={12}>
            <input className="btn btn-primary" type="submit" name="Submit" value="Submit" />
            {
              isSent ?
              (<div className="wpcf7-response-output" >Thank you for your message. It has been sent.</div>):""
            }
            {hasError ?
                (<div className="wpcf7-response-output-erron" >{hasError}</div>)
              :""}
          </Col>
        </Row>
      </form>
  )
}

const ClientReffreal = (props) => {
  return (
    <>
      <Header en="/partner/client-referral-program" fr="/fr" lang="en" />
      <div className="container mainDiv ab">
        <div className="row">
          <div className="col-xs-12 content">
            <h1>Client Referral Program</h1>
            <Container>
              <Row>
                <Col xl={8}>
                  <h3>Have clients who are travelling?</h3>
                  <p>Refer them to the travel insurance experts! Complete the form to your right and we will contact your clients directly to help them select the insurance plan right for them.</p>
                  <h3>What’s in it for you? A 10% referral fee.</h3>
                  <p>If your clients know what they want, they can buy insurance directly from our website using our <a href="https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&amp;returnurl=step1.aspx">‘Get a Quote’</a> tool. Send us a copy of their policy number for a referral fee.</p>
                  <p>We welcome any questions – <a href="/contact-us/">call, click, or live-chat</a> with us to learn about our products in more detail.</p>
                  <p>Referral fees are paid on expiry of the policy and will be sent by direct deposit. Accumulated referral fees in excess of $500 in one year will be subject to a tax receipt.</p>
                </Col>
                <Col xl={4}>
                  <Cf7FormWrapper siteUrl="https://www.travelance.ca/backend" formId="23861">
                    <Form />
                  </Cf7FormWrapper>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <Footer lang="en" />
    </>
  );
}

export default ClientReffreal;
