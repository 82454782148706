import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from "../../../commons/Header/Header";
import Banner from "../../../commons/Banner/Banner";
import Counter from "../../../commons/Counter/Counter";
import AccordianSection from "../../../commons/AccordianSection/AccordianSection";
import ContactSection from "../../../commons/ContactSection/ContactSection";
import GetQuoteSection from "../../../commons/GetQuoteSection/GetQuoteSection";
import Faq from "../../../commons/Faq/Faq";
import Reviews from "../../../commons/Reviews/Reviews";
import Footer from "../../../commons/Footer/Footer";

const Home = (props) => {
  const location = useLocation();
  useEffect(() => {
  if (location.hash) {
    setTimeout(() => {
      const element = document.getElementById(location.hash.replace('#', ''));
      if (element){
        const offset = 220;
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }, 500);
  }
},[location.hash]);
  return (
    <>
      <Header en="/" fr="/fr" lang="en" />
      <Banner lang="en" />
      <Counter  lang="en" />
      <AccordianSection  lang="en"  />
      <ContactSection  lang="en" />
      <GetQuoteSection  lang="en" />
      <Faq lang="en" />
      <Reviews lang="en" />
      <Footer lang="en" />
    </>
  );
}

export default Home;
