import React from 'react';
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const PartnerPageFr = (props) => {

  return (
    <>
      <Header en='/partner' fr='/partenaires' lang='fr' />
      <Container className="mainDiv partnerPage">
        <Row className="">
          <Col xl={12} className="content">
            <h1>Partner</h1>
            <h3>Vos clients demandent spécifiquement l’assurance Travelance. Êtes-vous prêt(e) à l’offrir ?</h3>
            <Container>
              <Row>
                <Col lg={8}>
                  <div className="outline_box">
                    <h2><i className="fa-6x why"></i>Pourquoi Travelance ?</h2>
                    <p>Les agents recherchent un produit compétitif qui est facile à gérer, appuyé d’un service exceptionnel et fourni par un assureur fiable; toutes des qualités qui vous distingueront parmi les autres !<br />En s’associant avec Travelance, c’est exactement ça que vous recevrez !</p>
                    <p><a href="/partenaires/sassocier-avec-travelance/" className="btn-2">Suite</a></p>
                  </div>
                  <div className="outline_box">
                    <h2><i className="fa-6x product"></i>Produits</h2>
                    <p>L’assurance voyage n’est pas un produit à configuration unique. La diversité des produits aide à trouver le régime qui répond le mieux aux besoins de vos clients.</p>
                    <p>Apprenez plus sur notre gamme complète de produits d’assurance voyage !</p>
                    <p><a href="/produits/" className="btn-2">Suite</a></p>
                  </div>
                  <div className="outline_box">
                    <h2><i className="fa-6x partner"></i>S’associer avec Travelance !</h2>
                    <p>Vos clients demandent spécifiquement l’assurance Travelance.</p>
                    <p>Êtes-vous prêt(e) à l’offrir ?</p>
                    <p><a href="/partenaires/sassocier-avec-travelance/" className="btn-2">Suite</a></p>
                  </div>
                  <div className="outline_box">
                    <p><iframe title="YT" src="https://www.youtube.com/embed/zZv2JgvLNoU" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" width="100%" height="250" frameBorder="0"></iframe></p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="stickyDiv">
                    <div className="holder_block">
                      <h2>Tous les produits</h2>
                      <ul className="list">
                        <li><a href="/produits/l-assurance-voyage/voyage/">Voyage</a></li>
                        <li><a href="/produits/l-assurance-voyage/jeunes/">Jeunesse</a></li>
                        <li><a href="/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence/">Visiteurs au Canada Assurance médicale d’urgence</a></li>
                        <li><a href="/produits/regime-dassurance-accidents-pour-etudiants/">Assurance Accident pour étudiants</a></li>
                      </ul>
                    </div>
                    <div className="holder_block">
                      <h2>Liens rapides</h2>
                      <ul className="list">
                        <li><a href="/au-sujet-de-notre-assureur/">Notre assureur</a></li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer lang="fr" />
    </>
  );
}

export default PartnerPageFr;
