import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";
import $ from 'jquery';

const Resources = (props) => {

  $(document).ready(function(){
    $('.accordion-header button').on('click',function(e){
      e.preventDefault();
      $('.accordion-header button').not(this).addClass('collapsed');
      $(this).toggleClass('collapsed');
      var id = $(this).attr('data-target');
      $('.collapse').not(id).removeClass('show');
      $(id).toggleClass('show');
    });
  });

  return (
    <>
    <Header en={GLobalLinks["partner/resources"]['en']} fr={GLobalLinks["partner/resources"]['fr']} lang={GLobalLinks["partner/resources"]['lang']} />
    <div className="container mainDiv resources">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="resources">Resources</h1>
          <div>
            <div className="row">
              <div className="col-lg-8">
                <h2>Welcome to our Partner Resource Page</h2>
                <p>Use the left-hand-side navigation bar to quickly access complete policy wordings, rate charts, steps to selling guides, links to useful webpages, and more!<br />If you have any questions, our Support Services Team is available to assist you Monday – Friday 9:00 a.m. – 7:00 p.m. (ET). Call 1-855-566-8555, email info@travelance.ca or use the live chat feature in the bottom right hand corner of your screen.</p>
                <h2>Products</h2>
                <div id="accordionExample" className="accordion">
                  <h2><strong>All Products</strong></h2>
                  <div className="card">
                    <div id="headingOne" className="card-header">
                      <h2 className="mb-0 accordion-header"><button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">All Products</button></h2>
                    </div>
                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body extended">
                        <h3>Sales Tools</h3>
                        <p>Travelance Summary of Plans</p>
                        <ul className="list">
                          <li>A quick reference summary in an easy to scan table format.</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/all-plans-summary">Download</a>
                        <p>Plans Overview</p>
                        <ul className="list">
                          <li>A descriptive overview of our plans</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/plan-overview">Download</a>
                        <p>Claims Overview</p>
                        <a className="btn-2" href="https://travelance.app.box.com/v/claimoverview">Download</a>
                        <p>Overview Brochure</p>
                        <ul className="list">
                          <li>A marketing brochure introducing VTC and TRIPs plans to clients</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/overview-brochure">Download</a>
                        <p style={{fontSize:'12px'}}>Visitors to Canada Emergency Medical Insurance Plans, Travel Right Insurance Plans, and Student Accident Plan are underwritten by Old Republic Insurance Company of Canada. International Student Travel Insurance Policy is underwritten by Zurich Insurance Company Ltd (Canadian Branch).</p>
                      </div>
                    </div>
                  </div>
                  <h2><strong>Travel Right Insurance Plans (TRIPs)</strong></h2>
                  <div className="card">
                    <div id="headingTwo" className="card-header">
                      <h2 className="mb-0 accordion-header"><button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Travel</button></h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body extended">
                        <h3>Policy Wording</h3>
                        <a className="btn-2" href="https://travelance.box.com/v/trips-travel-policy">TRIPs All-Inclusive Worldwide Plan</a>
                        <a className="btn-2" href="https://travelance.box.com/v/emergency-medical-policy-trips">TRIPs Emergency Medical Plan</a>
                        <a className="btn-2" href="https://travelance.box.com/v/interruption-policy-trips">TRIPs Trip Cancellation/Interruption Plan</a>

                        <h3>Rate Chart</h3>
                        <p>TRIPs Travel Rates</p>
                        <ul className="list">
                          <li>Select a plan from the TRIPs rate chart table of contents</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/trips-rate-chart">Download</a>
                        <h3>Sales Tools</h3>
                        <p>Steps to Selling a TRIPs Policy</p>
                        <ul className="list">
                          <li>Guide to selling a TRIPs policy</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/trips-steps-to-selling">Download</a>
                        <p>Eligibility Questionnaire<br />
                        Use this questionnaire if:</p>
                        <ul className="list">
                          <li>Your client is under 70 years old and wants to insure a trip cost of more than <strong>$25,000;</strong> or</li>
                          <li>Your client is over 69 years old and wants to insure a trip cost of more than <strong>$15,000;</strong> or</li>
                          <li>Your client is over 69 and under 90 years old and wants to buy the <strong>All Inclusive Worldwide Plan</strong> for more than <strong>16 travel days.</strong></li>
                        </ul>
                        <p>To download the eligibility questionnaire, <a href="http://www.getreliable.com/getreliable">log in to the online portal</a> and select the ‘Forms’ tab.</p>
                        <p style={{fontSize:'12px'}}>Travel Right Insurance Plans are underwritten by Old Republic Insurance Company of Canada.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div id="headingThree" className="card-header">
                      <h2 className="mb-0 accordion-header"><button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">TRIPs All-Inclusive Canada Plan</button></h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body extended">
                        <h3>Policy Wording</h3>
                        <p>TRIPs All-Inclusive Canada Plan</p>
                        <ul className="list">
                          <li>Complete All-Inclusive Canada Plan Travel Insurance policy wording</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/all-inclusive-canada-policy">Download</a>
                        <h3>Rate Chart</h3>
                        <p>TRIPs All-Inclusive Canada Plan Rates</p>
                        <ul className="list">
                          <li>Select ‘All-Inclusive Canada Plan’ from the TRIPs rate chart table of contents</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/trips-rate-chart">Download</a>
                        <h3>Sales Tools</h3>
                        <p>Steps to Selling a TRIPs Policy</p>
                        <ul className="list">
                          <li>Guide to selling a TRIPs policy</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/trips-steps-to-selling">Download</a>
                        <h3>Eligibility Questionnaire</h3>
                        <p>Use this questionnaire if:</p>
                        <ul className="list">
                          <li>Required to insure a trip cost of more than $25,000</li>
                        </ul>
                        <p>To download the eligibility questionnaire, <a href="http://www.getreliable.com/getreliable">log in to the online portal</a> and select the ‘Forms’ tab.</p>
                        <p style={{fontSize:'12px'}}>Travel Right Insurance Plans are underwritten by Old Republic Insurance Company of Canada.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div id="headingFour" className="card-header">
                      <h2 className="mb-0 accordion-header"><button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Youth</button></h2>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                      <div className="card-body extended">
                        <h3>Policy Wording</h3>
                        <p>TRIPs Youth Premier Plan Policy Wording</p>
                        <ul className="list">
                          <li>Complete Youth Premier Plan Travel Insurance policy wording</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/youth-policy-trips">Download</a>
                        <h3>Rate Chart</h3>
                        <p>TRIPs Youth Rates</p>
                        <ul className="list">
                          <li>Select ‘Youth Premier Plan’ from the TRIPs rate chart table of contents</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/trips-rate-chart">Download</a>
                        <h3>Sales Tools</h3>
                        <p>Steps to Selling a TRIPs Policy</p>
                        <ul className="list">
                          <li>Guide to selling a TRIPs policy</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/trips-steps-to-selling">Download</a>
                        <p>Eligibility Questionnaire</p>
                        <p>Use this questionnaire if:</p>
                        <ul className="list">
                          <li>Required to insure a trip cost of more than $25,000</li>
                        </ul>
                        <p>To download the eligibility questionnaire, <a href="http://www.getreliable.com/getreliable">log in to the online portal</a> and select the ‘Forms’ tab.</p>
                        <p style={{fontSize:'12px'}}>Travel Right Insurance Plans are underwritten by Old Republic Insurance Company of Canada.</p>
                      </div>
                    </div>
                  </div>
                  <h2><strong>Visitors to Canada Emergency Medical Insurance Plans (VTC)</strong></h2>
                  <div className="card">
                    <div id="headingFive" className="card-header">
                      <h2 className="mb-0 accordion-header"><button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">VTC</button></h2>
                    </div>
                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                      <div className="card-body extended">
                        <h3>Policy Wording</h3>
                        <p>VTC Premier</p>
                        <ul className="list">
                          <li>Visitors to Canada Emergency Medical Insurance policy wording</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/VTC-PremierPlan">Download</a>
                        <p>VTC Essential</p>
                        <ul className="list">
                          <li>Visitors to Canada Emergency Medical Insurance policy wording</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/VTC-EssentialPlan">Download</a>
                        <h3>Rate Chart</h3>
                        <p>VTC Premier and Essential</p>
                        <ul className="list">
                          <li>Visitors to Canada Emergency Medical Insurance policy rate chart</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/vtc-rate-chart">Download</a>
                        <h3>Sales Tools</h3>
                        <p>VTC Steps to Selling</p>
                        <ul className="list">
                          <li>Guide to selling a Visitors to Canada Emergency Medical Insurance policy</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/vtc-steps-to-selling">Download</a>
                        <p>VTC Comparison Table with Blank 3rd Column <em>(Legal Size)</em></p>
                        <ul className="list">
                          <li>This benefit comparison table includes our Visitors to Canada Emergency Medical Insurance plans and a blank column which can be used to compare our products with a competitor’s product</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/VTC-Comparison-Table">Download</a>
                        <h3>How to Make a Change to a VTC Policy in the Online Portal</h3>
                        <ul className="list">
                          <li>Learn about the changes you can make yourself to a client’s Visitors to Canada (VTC) policy in the online portal, both before the policy is in effect and after the start date (but before expiry).</li>
                        </ul>
                        <a className="btn-2" href="https://www.youtube.com/watch?v=oBPau0GyCKY&amp;feature=youtu.be">Watch Video</a>
                        <p style={{fontSize:'12px'}}>Visitors to Canada Emergency Medical Insurance Plans are underwritten by Old Republic Insurance Company of Canada.</p>
                      </div>
                    </div>
                  </div>
                  <h2><strong>Student Accident Plan (SAP)</strong></h2>
                  <div className="card">
                    <div id="headingSix" className="card-header">
                      <h2 className="mb-0 accordion-header"><button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">SAP</button></h2>
                    </div>
                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                      <div className="card-body extended">
                        <h3>Policy Wording</h3>
                        <p>SAP Policy</p>
                        <ul className="list">
                          <li>Complete Student Accident Plan policy wording</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/sap-policy">Download</a>
                        <h3>Sales Tools</h3>
                        <p>SAP Steps to Selling</p>
                        <ul className="list">
                          <li>A guide to selling a Student Accident Plan</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/sap-steps-to-selling">Download</a>
                        <p>SAP Overview</p>
                        <ul className="list">
                          <li>An overview of the Student Accident Plan</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/sap-plan-overview">Download</a>
                        <p>SAP Buckslip</p>
                        <ul className="list">
                          <li>A marketing piece to introduce the SAP to your clients</li>
                        </ul>
                        <a className="btn-2" href="https://travelance.box.com/v/sap-insert">Download</a>
                        <p style={{fontSize:'12px'}}>Student Accident Plan is underwritten by Old Republic Insurance Company of Canada. Not available in Quebec.</p>
                      </div>
                    </div>
                  </div>
                  <h2><strong>International Student Travel Insurance Policy (ISP)</strong></h2>
                  <div className="card">
                    <div id="headingSeven" className="card-header">
                      <h2 className="mb-0 accordion-header"><button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">ISP</button></h2>
                    </div>
                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                      <div className="card-body extended">
                        <h3>Policy Wording</h3>
                        <p>International Student Travel Insurance Policy</p>
                        <ul className="list">
                          <li>Complete International Student Travel Insurance Policy (ISP) policy wording</li>
                        </ul>
                        <a className="btn-2" target="_blank" rel="noreferrer" href="https://travelance.box.com/v/isp-policy-eng">Download</a>
                        <h3>Brochure and Rate Chart</h3>
                        <ul className="list">
                          <li>Review rates and unique selling points for the ISP.</li>
                        </ul>
                        <a className="btn-2" target="_blank" rel="noreferrer" href="https://travelance.app.box.com/v/ISPBrochureJul24">Download</a>
                        <h3>ISP Steps to Selling</h3>
                        <ul className="list">
                          <li>A guide to selling the International Student Plan</li>
                        </ul>
                        <a className="btn-2" target="_blank" rel="noreferrer" href="https://travelance.box.com/v/steps-to-selling-isp" download="Steps to Selling ISP (Jan 2023).pdf">Download</a>
                        <p style={{fontSize:'12px'}}>International Student Travel Insurance Policy is underwritten by Zurich Insurance Company Ltd (Canadian Branch).</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="stickyDiv">
                  <div className="holder_block">
                    <h2>Products</h2>
                    <ul className="list">
                      <li><a href="/products" rel="noopener noreferrer">All Products</a></li>
                      <li><a href="/products/visitors-canada-insurance/">VTC</a></li>
                      <li><a href="/products/trips/">TRIPs</a></li>
                      <li><a href="/products/student-accident-plan/">SAP</a></li>
                      <li><a href="/products/international-student/">ISP</a></li>
                    </ul>
                  </div>
                  <div className="holder_block">
                    <h2>Quick Links</h2>
                    <ul className="list">
                      <li><a href="/claims/">Claims</a></li>
                      <li><a href="https://www.getreliable.com/getreliable/">Online Portal Login</a></li>
                      <li><a href="/about-our-underwriter/">Our Underwriter</a></li>
                      <li><a href="/#faqSection">FAQs</a></li>
                      <li><a href="/partner/online-training-sessions/">Register for Online Training</a></li>
                      <li><a href="https://www.travel.gc.ca/travelling/advisories/">Travel Advisories</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["partner/resources"]['lang']} />
    </>
  );
}

export default Resources;
