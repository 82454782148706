import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const TermsConditions = (props) => {
  return (
    <>
    <Header en={GLobalLinks["terms-conditions"]['en']} fr={GLobalLinks["terms-conditions"]['fr']} lang={GLobalLinks["terms-conditions"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="terms-conditions">Terms and Conditions</h1>
          <div>
            <p>Welcome, These Terms of Use constitute an agreement between you (“you”, “your”, “User”) and Travelance.ca® is an online web property, lead generation insurance site, more specifically a intellectual web property. (“us”, “we”, “Travelance.ca”), the owner and operator of the website located at http://www.Travelance.ca (the “Site”) Please read these Terms of Use carefully to be sure that you understand them. By viewing, accessing, using or browsing the Site, or by registering as a consumer of the Service (as defined herein), you agree to these Terms of Use as a binding legal agreement between you and Travelance.ca, without limitation or qualification. <b>This web property is a lead generation insurance website, in the Country of Canada, Inclusive herein, all information contained, hereafter.</b></p>
            <h5>Implied Covenant:</h5>
            <p>We offer the opportunity to purchase insurance and or to get an insurance quote/s, the results are not guaranteed due to certain factors being outside the control of any one company, specifically Travelance.ca. You agree to indemnify and hold harmless Travelance.ca, owners, co-owners, agents, representatives, assignees, officers, employees, et.al, inclusive, herein, of Travelance.ca, harmless if you are not given a satisfactory or unsatisfactory quote/s.</p>
            <h5>Application of these Terms of Use:</h5>
            <p>This web property is operated by Travelance.ca to deliver a service for adults to make purchases of services or receive an insurance quote/s. (the “Service”), is as identified in the aforementioned. Travelance.ca is a lead generation insurance site for potential customers to purchase insurance. Not all quote/s will be the same as all geographical locations are different from one another and people have different driving records which may change any given result. Now then, if you are unsure of any quote please contact the individual agency for clarification through their individual policies. We cannot and shall not be responsible for any mis-quote by any insurance agency. We invite you to inquire from the agency and to  use due diligence prior to making any purchases. Any and all discounts are subject to change. Each consumer agrees and understands quotes may change daily and without notice, we cannot possibly control the quotes made by 3rd party insurance carriers. We hold no authority to dictate any insurance binder and or policy.</p>
            <h5>In this agreement:</h5>
            <ul>
              <li><b>“Consumer”</b> means any natural person who, in connection with this agreement, is acting for purposes which are outside his/her business;</li>
              <li><b>“Our Website”</b> means the entire computing hardware and software installation that is or supports Our Website, including but not limited to insurance quote/s.</li>
              <li><b>“Services”</b> means any of the services we offer for sale on our Website, which generally include available updates and support services so far as specified for each service.</li>
              <li><b>“Content”</b> means any and all material in any form published on Our Website by us or any third party with our expressed or implied consent.</li>
              <li><b>“Material”</b> means Content of any sort posted by you on Our Website, including any and all lead generation insurance quote/s.</li>
            </ul>
            <h5> Access:</h5>
            <p>Travelance.ca grants you permission to access and use the Site in accordance with these Terms of Use. No other use is permitted. Access to the Site is permitted within our sole discretion, and we reserve the right to withdraw or amend the Service or the Site, or any other products or services offered on the Site, with or without notice. Any rights not expressly granted herein are reserved. You are responsible for making any arrangements necessary for you to have access to the Site. You are also responsible for ensuring that all persons who access the Site through your internet connection are aware of these Terms of Use and that they comply with them.</p>
            <p>These Terms and Conditions also govern your consumer ship to Travelance.ca if you become a (paying) consumer which will be described herein. Access to this electronic record requires a simple browser program such as Internet Explorer, Firefox or Chrome, any other Internet or Mobile browser and a computer. These Terms and Conditions are subject to change by Travelance.ca without prior notice, at any time, in its discretion. Notification of any changes will be posted on this page. The current date above will let you know the most recent changes. You agree to review this page periodically to be aware of such changes.</p>
            <p>If these changes are unacceptable to you, you must terminate your consumer ship as provided below. Your continued use of Travelance.ca following the effective date of any such changes. If you do not agree to these Terms and Conditions, you may not enter nor use Travelance.ca. You must exit Travelance.ca immediately, you may not use or access Travelance.ca at all, and you may not print or download any materials from the site. You may use and access Travelance.ca only in accordance with these Terms and Conditions. Please consult the Terms and Conditions regularly and read them carefully before using Travelance.ca. You affirm that you have read this Agreement and understand, agree and consent to its Terms and Conditions stated herein.</p>
            <p>You are solely responsible for obtaining access to Travelance.ca and that access may involve third party fees (such as Internet service provider or airtime charges, or internet access). You are responsible for fees, including those fees associated with the display or delivery of all images, graphics, programs, advertisements and such. In addition, you must provide and are responsible for all equipment to access Travelance.ca. We are not responsible for you gaining access to the internet. You are solely responsible, and liable for your matters connecting to the internet.</p>
            <ol type="I">
              <li>
                <p>
                  <b>Images and Content </b>
                  <br />
                  Travelance.ca may contain images and content, including but not limited to: text, software, images, graphics, data, messages, videos, flash, stories and or any other information, and any other World Wide Web Site owned, operated, licensed, or controlled by Travelance.ca. (collectively called, “Materials”). All Materials displayed on Travelance.ca are protected by the First Amendment rights to Free Speech, Free Expression and Freedom of the Press, and parallel provisions of other and all constitutions.
                  <br />
                  <b>Contractual Terms</b>
                  <br />
                  So far as to you as a buyer or prospective buyer of our Services. We will accept any and all orders through e-mail confirmation. Our message will also confirm details of your purchase receipt. We do not guarantee every Service advertised on our website is available. If at any time a Service for which you have paid for becomes unavailable, we will immediately refund any money you have paid. Our refund will cover the period of unavailability and not any period for which Services have been made available. We may change these terms from time to time. The terms that apply to you are those posted here on Our Website on the day you order our Services. If in the future, you purchase Services from us under any arrangement which does not involve your payment via Our Website, these terms still apply so far as they can be applied. If we owe you money (for this or any other reason), we will credit your credit or debit our access card as soon as reasonably practicable but in any event no later than 30 days from the date of your order.
                </p>
              </li>
              <li>
                <p>
                  <b>Age of Majority and Consumership </b>
                </p>
                <ol type="A">
                  <li>
                    <b>Age of Majority </b>
                    <br />
                    You represent and warrant you are at least 18 or 21 years of age, depending on the age of majority in your jurisdiction, and that you have the legal capacity to enter into this Agreement. If you are not at least 18 or 21 years of age, depending on the age of majority in your jurisdiction, you must exit Travelance.ca immediately and may not use or access Travelance.ca. You agree not to bypass any security and/or access feature on Travelance.ca.<br />Additionally, Travelance.ca does not assume any responsibility or liability for any misrepresentations regarding a user’s age.
                  </li>
                  <li>
                    <b>Consumership </b>
                    <br />
                    If a user fraudulently obtains access, Travelance.ca may terminate consumer ship immediately and take all necessary and appropriate actions under applicable federal, state, and international laws to do so.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <b>Purchasing</b><br />
                  Travelance.ca grants all rights to determine what is appropriate for the website. All sales inquiries are to be forwarded to our customer support team.  With sufficient notice, we will respond accordingly. <b>We do not offer refunds, services are non-refundable.</b> You agree to pay the monthly charge for the Services, from PayPal account or credit card, information for which you have or will have supplied to us. You authorize us to arrange withdrawal of funds on this card each month without further reference to you. Payments are billed in advance on the same day as your initial order each month.
                  <br />
                  <br />
                  Our Services may be provided by email or made available for you to download. If we are not able to provide your Services within 30 days of the date of your order, we shall notify you by e-mail to inform you of the likely provision date. Once Service provision has started, you may cancel the Services at any time, with 30 days prior notice. Payment is due until the expiration of the notice period. We may change the nature or provision of the Services at any time. We may or may not notify you about any such changes by email or by posting details on Our Website. If a change we make in the provision of the Services, involves action on your part, and you do not perform such action, we are entitled to terminate any and all Services without sufficient notice. You may not share or allow others to use the Services in your name. We will do our best to maintain Our Website so that you have constant use, but there will be times when your use may be interrupted. Interruption to the Services for reasonable periods for maintenance or causes beyond our control is not a ground for repayment of money. When we are aware of the likelihood of down time, we will attempt to notify you in advance.
                </p>
              </li>
              <li>
                <p>
                  <b>Access to, Limited License, and Interference with, SITE</b>
                </p>
                  <ol type="A">
                    <li>
                      <b>Access</b>
                      <br />
                      To access Travelance.ca or some of the resources it has to offer, you may be asked to provide certain registration details or other information. It is a condition of your use of Travelance.ca that all information you provide must be correct, current, and complete. If Travelance.ca believes the information you provide is not correct, current, or complete at any time, Travelance.ca has the right to refuse you access to Travelance.ca or any of its resources, and to terminate or suspend your access at any time without an explanation or notification.
                    </li>
                    <li>
                      <b>Limited License</b>
                      <br />
                      Limited license can be defined as a license. Subject to these Terms and Conditions and in consideration of using Travelance.ca, Travelance.ca hereby grants you a limited, nonexclusive, nontransferable personal license to access and use Travelance.ca and the website contained therein.Travelance.ca provides personal, non-commercial use by viewers, fans, visitors, subscribers and/or potential subscribers of the site. Users are defined as (Consumers) of the site and are granted a single access to the website. Sharing your consumer account with other people is grounds for immediate termination. By stealing, copying, sharing, or granting consumer based information to other people will be considered violators of this limited license may be prosecuted to the fullest extent under the applicable law.
                    </li>
                    <li>
                      <b>Interference</b>
                      <br />
                      Except where expressly permitted by law, you may not translate (either language or coding), reverse-engineer, decompile, disassemble or make derivative works from our Travelance.ca. You hereby agree not to use any automatic device or manual process to monitor or reproduce Travelance.ca, and will not use any device, software, computer code, hardware or virus to interfere or attempt to disrupt or damage Travelance.ca or any communications on it. All attempts are logged, including IP address and all other identifiable means and we will contact authorities and take full legal action.
                    </li>
                  </ol>
              </li>
              <li>
                <p>
                  <b>Restrictions on Use of SITE </b>
                  <br />
                  Restrictions included but are not limited to, amendments and or changes to the site. You may use Travelance.ca only for purposes expressly permitted by the Terms and Conditions of Travelance.ca, specifically, to receive an insurance quote/s.
                  <br /><br />
                  You may not use Travelance.ca for any other purpose, including any commercial purpose, without Travelance.ca’s express prior written consent. Without the express prior written consent of Travelance.ca, you may not: duplicate Travelance.ca or any of the services contained on the website (except as expressly provided, create derivative works based on Travelance.ca or any of the Services contained on the website; use of Travelance.ca or any of the Materials contained on the website for any public display, public performance, copy, website, sale or rental; re-distribute may be a direct violation of our terms.
                  <br /><br />
                  Travelance.ca or any of the Services contained on the website including mobile content without the written permission; remove any copyright or other proprietary notices from Travelance.ca or any of the Services contained on the website including taking videos or graphics from the site.; frame or utilize any html, php, java, or any other framing techniques in connection with Travelance.ca or any of the Services contained on the website; use any meta-tags or any other “hidden text” using Travelance.ca’ name or marks registered or not; circumvent any encryption or other security tools or measures used anywhere on Travelance.ca (including the theft of user names and passwords or using another person’s user name and password or credit card in order to gain access to a restricted area of Travelance.ca (both for consumers and for friends); use any data mining, robots, viruses, or similar data gathering and extraction tools on Travelance.ca; decompile, reverse engineer, modify or disassemble any of the software aspect of the site; sell, rent, lease, license, sublicense, transfer, distribute, re-transmit, time-share, use as a service bureau or otherwise assign to any third party the materials or any of your rights to access and use the materials as granted, herein, above including photos, images, graphics, profiles, text.
                  <br /><br />
                  You agree to cooperate with Travelance.ca in causing any unauthorized use to cease immediately. At any time, if Travelance.ca provides a service enabling users to share information or communicate with other users, you hereby agree not to publish, disseminate or submit any defamatory, offensive or illegal material while using Travelance.ca or other services included on Travelance.ca. You are solely responsible for submitting any material that violates any Country of Canada, United States, or International laws even if a claim arises after your service is terminated, and, by doing so, your actions shall constitute a material breach of this Agreement and Travelance.ca shall terminate all your rights under this Agreement.
                </p>
              </li>
              <li>
                <p>
                  <b>Consumer/Member</b>
                </p>
                  <ol type="A">
                    <li>
                      <b>Registration </b>
                      <br />
                      You are responsible for providing all equipment and the computer, mobile technology necessary to access Travelance.ca. Please check back often to check the date located on the bottom of the terms and conditions page. You may become a consumer of Travelance.ca by completing an online registration form, which must be accepted by Travelance.ca.
                      <br />
                      <br />
                      Upon submission of the online registration form, Travelance.ca or its authorized agent will process the application, forthwith.  In connection with completing the online registration form, you agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the registration form (such information being the “Registration Data”) and (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete at all times while you are a consumer.
                    </li>
                    <li>
                      <b>Consumer Account, Password and Security</b>
                      <br />
                      As part of the registration process, you may be issued a unique user name and password which you must provide in order to gain access to the non-public portion of Travelance.ca. You certify that when asked to choose a username you will not choose a name which may falsely represent you as somebody else or a name which may otherwise be in violation of the rights of a third party. We reserve the right to disallow the use of usernames that we, at our sole discretion, that we deem inappropriate.
                      <br />
                      <br />
                      We reserve the right to cancel at any time the consumer ship of any consumer who uses their selected username in violation of these Terms and Conditions or in any other way we, in our sole discretion, deem inappropriate. Your consumer ship, the ID and password are nontransferable and non-assignable.
                      <br />
                      <br />
                      You represent and warrant that you will not disclose to any other person your unique user name and password and that you will not provide access to Travelance.ca to anyone who is below the age of majority in your state, province, or country, or otherwise does not wish to access Travelance.ca. You are solely responsible for maintaining the confidentiality of your user name and password and are fully responsible for all activities that occur under your user name and password. Travelance.ca will not release your password for security reasons. You agree to (a) immediately notify Travelance.ca of any unauthorized use of your user name and password or any other breach of security, and (b) ensure that you exit from your account at the end of each session. You are liable and responsible for any unauthorized use of Travelance.ca until you notify Travelance.ca by email us regarding that unauthorized use. Unauthorized access to Travelance.ca is illegal and a breach of this Agreement. You indemnify Travelance.ca against all activities conducted through your account. You may obtain access to your billing records regarding charges of your use of Travelance.ca upon request.
                    </li>
                    <li>
                      <b>Consumer/Member payment gateway</b>
                      <br />
                      At the time of registration, you must select a payment method. Travelance.ca reserves the right to contract with a third party to process all payments. Such third party may impose additional terms and conditions governing payment processing. Your credit card issuer agreement may contain additional terms with respect to your rights and liabilities as a card holder. You agree to pay all amounts due to us immediately upon cancellation or termination of your account. We reserve the right to make changes to our fees and billing methods, including the addition of supplemental charges for any content or services provided by Travelance.ca, with or without prior notice to you, at any time. This website uses an automatic recurring cycle according to the users selected payment option.
                    </li>
                    <li>
                      <b>Billing Errors</b>
                      <br />
                      If you believe that you have been erroneously billed, please notify us immediately of such error. If we do not hear from you within thirty (30) days after such billing error first appears on any account statement, such fee will be deemed acceptable by you for all purposes, including resolution of inquiries made by your credit card issuer.
                      <br />
                      <br />
                      You release us from all liabilities and claims of loss resulting from any error or discrepancy that is not reported to us within thirty (30) days of its publication.
                    </li>
                    <li>
                      <b>Consumer/Member access</b>
                      <br />
                      Travelance.ca monitors all activities by IPN’s.
                    </li>
                  </ol>
              </li>
              <li>
                <p>
                  <b>Termination </b>
                </p>
                  <br />
                  In the event that your account is canceled by you, no refund, including any owed fees, will be granted; no online time or other credits will be credited to you or can be converted to cash or other form of reimbursement.
                  <br /><br />
                  This Agreement’s provisions shall survive its termination, unless otherwise stated. Without limiting other remedies, Travelance.ca may immediately issue a warning, temporarily suspend, indefinitely suspend, or terminate your access and use of Travelance.ca and refuse to provide our services to you at any time, with or without advance notice,
                  <br />
                  if:
                  <ol type="a">
                    <li>Travelance.ca believes that you have breached any material term of these Terms and Conditions or the documents it incorporates by reference,</li>
                    <li>you fail to pay any amount due by the payment due date,</li>
                    <li>we are unable to verify or authenticate any information you provide to us,</li>
                    <li>we believe that your actions may cause legal liability for you, our users or us, or</li>
                    <li>Travelance.ca decides to cease operations or to otherwise discontinue any of Travelance.ca or parts thereof.</li>
                  </ol>
              </li>
              <li>
                <p>
                  <b>Disclaimer of Warranty</b>
                  <br />
                  You expressly agree that use of travelance.Ca or any of the services contained therein is at your own and sole risk. Travelance.Ca and all services contained therein are provided “as is” without warranty of any kind, either express or implied, including but not limited to, any implied warranties of merchantability, fitness for a particular purpose, title, or non-infringement. Travelance.Ca makes no representations or warranties that travelance.Ca or any materials contained therein will be uninterrupted, timely, secure, or error free; nor does site make any representations or warranties as to the quality, suitability, truth, usefulness, accuracy or completeness of travelance.Ca or any of the materials contained therein. You also understand and agree that any material and/or data downloaded or otherwise obtained through the use of travelance.Ca, or any of the materials contained therein is done at your own discretion and risk and that you will be solely responsible for any damage to your computer system or loss of data that results from the download of such material and/or data and or use. You understand that site cannot and does not guarantee or warrant that all friends or consumers are available.
                  <br />
                  <br />
                  Travelance.Ca does not assume any responsibility or risk for your use of the internet or the services of a consumer of travelance.Ca travelance.Ca makes no warranty regarding any goods or services purchased or obtained through travelance.Ca or any transaction entered into through travelance.Ca and is not responsible for any use of confidential or private information by sellers or third parties.
                  <br />
                  <br />
                  Site ownership may change any of the information found at this site at any time without notice including the terms of service without notice. Site owner makes no commitment to update the information found at this site. Site makes no commitment to update the materials. The warranties and representations set forth in this agreement are the only warranties and representations with respect to this agreement, and are in lieu of any and all other warranties, written or oral, express or implied, that may arise either by agreement between the parties or by operation of law, including warranties of merchantability and fitness for a particular purpose. None of these warranties and representations will extend to any third person. Some jurisdictions do not allow the exclusion of certain warranties, so some of the above exclusions may not apply to you. Travelance.Ca takes no responsablity whatsoever and is to be held harmless for all claims made. Travelance.Ca includes its, owners, agents, representatives, employees, assignees, officers, president, vice president, corporate officers, and general work force.
                </p>
              </li>
              <li>
                <p>
                  <b>Disclaimer</b>
                  <br />
                  The provision of any services, which are in violation of any laws, is strictly prohibited. If we determine that you or any user has provided or intends to purchase or provide any services in violation of any law, your ability to use travelance.Ca will be terminated immediately. We do hereby disclaim any liability for damages that may arise from any user providing any services for any purpose that violates any law. You do hereby agree to defend, indemnify and hold us harmless from any liability that may arise should you violate any law. You do also hereby agree to defend and indemnify us should any third party be harmed by your illegal actions or should we be obligated to defend any claims including, without limitation, any criminal action brought by any party not affiliated with this site. If you are seeking services that are in violation of any applicable laws whatsoever, you may not use this site and do hereby agree to exit it immediately.
                </p>
              </li>
              <li>
                <p>
                  <b>Limitation of Liability</b>
                  <br />
                  In no event shall travelance.Ca (or its licensors, agents, suppliers, resellers, service providers, or any other subscribers or suppliers) be liable to you, or any other third party for any direct, special, indirect, incidental, consequential, exemplary, or punitive damages, including without limitation, damages for loss of profits, loss of information, business interuption, revenue, or goodwill, which may arise from any person’s use, misuse, or inability to use travelance.Ca or any of the materials contained therein, even if site has been advised of the probability of such damages. This is for any matter arising out of or relating to this agreement, whether such liability is asserted on the basis of contract, tort or otherwise, even if site has been advised of the possibility of such damages. In no event shall site’s maximum total aggregrate liability hereunder for direct damages exceed the total fees actually paid by you for use of a site or site for a period of no more than one (1) month from the accrual of the applicable cause or causes of action. Because some jurisdictions prohibit the exclusion or limitation of liability for consequential or incidental damages, the above limitation may not apply to you.
                </p>
              </li>
              <li>
                <p>
                  <b>Indemnity</b>
                  <br />
                  You agree to defend, indemnify, and hold harmless Travelance.ca, its officers, directors, shareholders, employees, independent contractors, telecommunication providers, hosts and agents, from and against any and all claims, actions, loss, liabilities, expenses, costs, or demands, including without limitation legal and accounting fees, for all damages directly, indirectly, and/or consequentially resulting or allegedly resulting from your, or you under another person’s authority including without limitation to governmental agencies, use, misuse, or inability to use Travelance.ca or any of the Materials contained therein, or your breach of any of these Terms and Conditions. Travelance.ca shall promptly notify you by electronic mail of any such claim or suit, and cooperate fully (at your expense) in the defense of such claim or suit. We reserve the right to participate in the defense of such claim or defense at its own expense, and choose its own legal counsel, but are not obligated to do so.
                </p>
              </li>
              <li>
                <p>
                  <b>Links and Linking </b>
                  <br />
                  Some websites, which are linked to Travelance.ca, are owned and operated by third parties.Because Travelance.ca has no control over such websites and resources, you acknowledge and agree that Travelance.ca is not responsible or liable for the availability of such external websites or resources, and does not screen or endorse them, and is not responsible or liable for any content, advertising, services, products, or other materials on or available from such websites or resources.
                  <br />
                  <br />
                  You further acknowledge and agree that Travelance.ca shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such third-party content, goods or services available on or through any such website or resource. If you decide to access any such third party website, you do so entirely at your own risk and subject to any terms and conditions and privacy policies posted therein.
                  <br />
                  <br />
                  Users further acknowledge that use of any website controlled, owned or operated by third parties is governed by the terms and conditions of use for those websites, and not by Travelance.ca’s Terms and Conditions, Spam Policy, Webmaster Agreement, or Privacy Policy, which are incorporated by reference. Links to external websites or the featured model’s linked websites (including external websites that are framed by Travelance.ca) or inclusions of advertisements do not constitute an endorsement by Travelance.ca of such websites or the content, products, advertising or other materials presented on Travelance.ca, but are for user’s convenience. Users access them at their own risk.
                  <br />
                  <br />
                  Travelance.ca expressly disclaims any liability for any damages whatsoever incurred by any user in connection with the use of any website, the access to which was found through this Travelance.ca. Travelance.ca expressly disclaims any liability derived from the use and/or viewing of any links that may appear on this SITE. All users do hereby agree to hold Travelance.ca harmless from any and all damages and liability that may result from the use of links that may appear on Travelance.ca. Travelance.ca reserves the right to terminate any link or linking program at anytime without any notification.
                </p>
              </li>
              <li>
              <p>
              <b>Trademarks </b>
              <br />
              Travelance.ca and aforementioned names of Travelance.ca are protected marks of Travelance Inc. We aggressively defend our intellectual property rights.Other manufacturers’ product and service names referenced herein may be trademarks and service marks of their respective companies and are the exclusive property of such respective owners, and may not be used publicly without the express written consent of the owners and/or holders of such trademarks and service marks.
              <br />
              <br />
              Travelance.ca’s marks, logos, domains, and trademarks may not be used publicly except with express written permission from Travelance.ca, and may not be used in any manner that is likely to cause confusion among consumers, or in any manner that disparages or discredits Travelance.ca.  Travelance.ca, inclusive, herein, protects its mark by using these symbols: ©®.
              </p>
              </li>
              <li>
              <p>
              <b>Copyright Information </b>
              <br />
              The Services accessible from Travelance.ca, and any other World Wide Web Site owned, operated, licensed, or controlled by Travelance.ca, is the proprietary information and valuable intellectual property of Travelance.ca or the party that provided the Materials to Travelance.ca, and Travelance.ca or the party that provided the Services to Travelance.ca retains all right, title, and interest in the Materials. Accordingly, the Materials may not be copied, distributed, republished, modified, uploaded, posted, or transmitted in any way without the prior written consent of Travelance.ca. Modification or use of the Content except as expressly provided in these Terms and Conditions violates Travelance.ca’s intellectual property rights. Neither title nor intellectual property rights are transferred to you by access to Travelance.ca.
              <br />
              <br />
              All Materials included on Travelance.ca, such as text, graphics, photographs, video and audio clips, music, soundtracks, button icons, streaming data, animation, images, downloadable materials, data compilations, profiles and software is the property of Travelance.ca or its content suppliers and is protected by United States, and international copyright laws. The compilation of all Materials on Travelance.ca is the exclusive property of Travelance.ca or its content suppliers and protected by United States, and international copyright laws, as well as other laws and regulations.
              </p>
              </li>
              <li>
              <p>
              <b>Notice of Claimed Infringement</b>
              <br />
              Travelance.ca respects the intellectual property of others, and we ask our users to do the same. We voluntarily observe and comply with the United States’ Digital Millennium Copyright Act (DMCA). If you believe that your work has been copied in a way that constitutes copyright infringement, or your intellectual property rights have been otherwise violated, please provide Travelance.ca support team, Designated Copyright Agent the following information:</p>
              <ol type="a">
              <li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest,</li>
              <li>description of the copyrighted work or other intellectual property that you claim has been infringed,</li>
              <li>a description of where the material that you claim is infringing is located on a SITE,</li>
              <li>your address, telephone number, and email address.</li>
              <li>a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law, and</li>
              <li>a statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner’s behalf.</li>
              </ol>
              </li>
              <li>
                <p>
                  <b>Notice and Takedown Procedures</b>
                  <br />
                  Travelance.ca implement the following “notice and takedown” procedure upon receipt of any notification of claimed copyright infringement. Travelance.ca reserve the right at any time to disable access to, or remove any material or activity accessible on or from Travelance.ca or any Materials claimed to be infringing or based on facts or circumstances from which infringing activity is apparent.
                  <br />
                  <br />
                  It is the firm policy of Travelance.ca to terminate the account of repeat copyright infringers, when appropriate, and Travelance.ca will act expeditiously to remove access to all material that infringes on another’s copyright, according to the procedure set forth in 17 U.S.C. §512 of the Digital Millennium Copyright Act (“DMCA”).
                  <br />
                  <br />
                  Travelance.ca’s DMCA Notice Procedures are set forth in the preceding paragraph. If the notice does not comply with Paragraph 19 and §512 of the DMCA, but does comply with three requirements for identifying Travelance.ca that are infringing according to §512 of the DMCA, Travelance.ca shall attempt to contact or take other reasonable steps to contact the complaining party to help that party comply with the notice requirements.
                  <br />
                  <br />
                  When the Designated Agent receives a valid notice, Travelance.ca will expeditiously remove and/or disable access to the infringing material and shall notify the affected user. Then, the affected user may submit a counter-notification to the Designated Agent containing a statement made under penalty of perjury that the user has a good faith belief that the material was removed because of misidentification of the material.
                  <br />
                  <br />
                  After the Designated Agent receives the counter-notification, it will replace the material at issue within 10-14 days after receipt of the counter-notification unless the Designated Agent receives notice that the complaining party seeking an injunction against the infringing activity has filed a court action.
                  <br />
                  <br />
                  Travelance.ca reserve the right to modify, alter or add to this policy, and all users should regularly check back to these Terms and Conditions to stay current on any such changes.
                </p>
              </li>
              <li>
              <p>
              <b>Export Control </b>
              <br />
              You understand and acknowledge the services on Travelance.ca may be subject to regulation by agencies of the United States Government, including the United States Department of Commerce, which prohibits export or diversion of software to certain countries and third parties. Diversion of such services contrary to the, United States’ or international law is prohibited. You will not assist or participate in any such diversion or other violation of applicable laws and regulations. You warrant that you will not license or otherwise permit anyone not approved to receive controlled commodities under applicable laws and regulations and that you will abide by such laws and regulations. You agree that none of the Materials are being or will be acquired for, shipped, transferred, or re-exported, directly or indirectly, to proscribed or embargoed countries or their nationals or be used for proscribed activities.
              </p>
              </li>
              <li>
              <p>
              <b>No Agency Relationship </b>
              <br />
              Nothing in this Agreement shall be deemed to constitute, create, imply, give effect to, or otherwise recognize a partnership, employment, joint venture or formal business entity of any kind; and the rights and obligations of the parties shall be limited to those expressly set forth herein.
              </p>
              </li>
              <li>
              <p>
              <b>Notice </b>
              </p>
              <ol type="A">
              <li><b>Notice</b>
              <br />
              Any notice required to be given under this Agreement may be provided by email to a functioning email address of the party to be noticed, by a general posting on Travelance.ca, or personal delivery by commercial carrier such as FedEx.
              <br />
              Notices by customers to Travelance.ca shall be given by electronic messages unless otherwise specified in the Agreement.</li>
              <li><b>Change of Address </b>
              <br />
              Either party may change the address to which notice is to be sent by written/electronic notice to the other party pursuant to this provision of the Agreement.</li>
              <li><b>When Notice is Effective </b>
              <br />
              Notices delivered by any other method shall be deemed given upon receipt. Notices by email and facsimile transmission, with confirmation from the transmitting machine that the transmission was completed, are acceptable under this Agreement provided that they are delivered one (1) hour after transmission if sent during the recipient’s business hours, or 9:00 a.m. (recipient’s time) the next business day. Either Party may, by giving the other Party appropriate written notice, change the designated address; fax number and/or recipient for any notice or courtesy copy, hereunder.</li>
              <li><b>Refused, Unclaimed, or Undeliverable Notice </b>
              <br />
              Any correctly addressed notice that is refused, unclaimed, or undeliverable, because of an act or omission of the Party to be notified shall be deemed effective as of the first date that said notice was refused or deemed undeliverable by the postal authorities, messenger, facsimile machine, email server, or overnight delivery service.</li>
              </ol>
              </li>
              <li>
              <p>
              <b>Force Majeure </b>
              <br />
              Travelance.ca shall not be responsible for any failure to perform due to unforeseen circumstances or to causes beyond our reasonable control, including but not limited to: acts of God, such as fire, flood, earthquakes, hurricanes, tropical storms or other natural disasters; war, riot, arson, embargoes, acts of civil or military authority, or terrorism; fiber cuts; strikes, or shortages in transportation, facilities, fuel, energy, labor or materials; failure of the telecommunications or information services infrastructure; hacking, SPAM, or any failure of a computer, server or software, for so long as such event continues to delay Travelance.ca’s performance.
              </p>
              </li>
              <li>
              <p>
              <b>General Provisions</b>
              </p>
              <ol type="A">
              <li>
              <b>Governing Law </b>
              <br />
              The laws of Ontario, Canada shall govern these Terms and Conditions and all matters arising out of or otherwise relating to these Terms and Conditions, excluding its conflict of law provisions. The parties agree that the United Nations Convention on Contracts for the International Sale of Goods is specifically excluded from application to these Terms and Conditions.
              <br />
              The parties hereby submit to the personal jurisdiction of Ontario, Canada. Exclusive venue for any litigation or arbitration permitted under this Agreement shall be with the state and federal courts located in Canada.
              </li>
              <li>
              <b>Rights to Injunctive Relief </b>
              <br />
              Both parties acknowledge that remedies at law may be inadequate to provide an aggrieved party with full compensation in the event of the other party’s breach, and that an aggrieved party shall therefore be entitled to seek injunctive relief in the event of any such breach, in addition to seeking all other remedies available at law or in equity.
              </li>
              <li>
              <b>Binding Arbitration </b>
              <br />
              If there is a dispute between the Parties arising out of or otherwise relating to this Agreement, the Parties shall meet and negotiate in good faith to attempt to resolve the dispute. If the Parties are unable to resolve the dispute through direct negotiations, then, except as otherwise provided herein, either Party may submit the issue to binding arbitration in accordance with the then-existing Commercial Arbitration Rules of the Canadian/American Arbitration Association. Arbitral Claims shall include, but are not limited to, contract and tort claims of all kinds, and all claims based on any federal, state or local law, statute, or regulation, excepting only claims under applicable worker’s compensation law, unemployment insurance claims, actions for injunctions, attachment, garnishment, and other equitable relief.
              <br />
              The arbitration shall be conducted in Canadaand conducted by a single arbitrator, knowledgeable in Internet and website disputes. The arbitrator shall have no authority to award any punitive or exemplary damages; certify a class action; add any parties; vary or ignore the provisions of these Terms and Conditions; and shall be bound by governing and applicable law. The arbitrator shall render a written opinion setting forth all material facts and the basis of his or her decision within thirty (30) days of the conclusion of the arbitration proceeding. THE PARTIES HEREBY WAIVE ANY RIGHTS THEY MAY HAVE TO TRIAL BY JURY IN REGARD TO ARBITRAL CLAIMS.
              </li>
              <li>
              <b>Assignment </b>
              <br />
              The rights and liabilities of the parties hereto will bind and inure to the benefit of their respective assignees, successors, executors, and administrators, as the case may be.
              </li>
              <li>
              <b>Severability </b>
              <br />
              If for any reason a court of competent jurisdiction or an arbitrator finds any provision of these Terms and Conditions, or any portion thereof, to be unenforceable, that provision will be enforced to the maximum extent permissible and the remainder of these Terms and Conditions will continue in full force and effect.
              </li>
              <li>
              <b>Attorney’s Fees </b>
              <br />
              In the event any Party shall commence any claims, actions, formal legal action, or arbitration to interpret and/or enforce the terms and conditions of this Agreement, or relating in any way to this Agreement, including without limitation asserted breaches of representations and warranties, the prevailing party in any such action or proceeding shall be entitled to recover, in addition to all other available relief, its reasonable attorney’s fees and costs incurred in connection therewith, including attorney’s fees incurred on appeal. Any and all attorney fee’s will be reasonable and not to exceed exorbitant or over extended fee’s.  Each party should negotiate prior to entering into any arbitration, in where, each party agrees on a location and arbiter.
              </li>
              <li>
              <b>No Waiver </b>
              <br />
              No waiver of Travelance.ca shall be deemed a waiver of any subsequent default of the same provision of these Terms and Conditions. If any term, clause or provision hereof is held invalid or unenforceable by a court of competent jurisdiction, such invalidity shall not affect the validity or operation of any other term, clause or provision and such invalid term, clause or provision shall be deemed to be severed from these Terms and Conditions.
              </li>
              <li>
              <b>Headings </b>
              <br />
              All headings are solely for the convenience of reference and shall not affect the meaning, construction or effect of these Terms and Conditions.
              </li>
              <li>
              <b>Complete Agreement </b>
              <br />
              These Terms and Conditions constitute the entire agreement between the parties with respect to your access and use of Travelance.ca and the Services contained therein, and your consumer ship with Travelance.ca, and supersede and replace all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter. No amendment to or modification of these Terms and Conditions will be binding unless in writing and signed by a duly authorized representative of both parties.
              </li>
              <li>
              <b>Modifications</b>
              <br />
              Travelance.ca reserves the right to change, amend any of the provisions posted herein and you agree to review these Terms and Conditions each time you visit Travelance.ca. Your continued use of Travelance.ca following Travelance.ca’s posting of any changes to these Terms and Conditions constitutes your acceptance of such changes. Travelance.ca does not and will not assume any obligation to provide you with notice of any change to these Terms and Conditions. Unless accepted by Travelance.ca in writing, you may not amend these Terms and Conditions.
              </li>
              <li>
              <b>Other Jurisdictions </b>
              <br />
              User(s) on Travelance.ca applies to all (CONSUMER (S) of the website. As part of the services on Travelance.ca we collect and, in some cases, may disclose information about you. This Privacy Policy describes the information we collect about you and what may happen to that information.
              </li>
              </ol>
              <br />
              <p>By accepting our Terms and Conditions, you expressly consent to our use and disclosure of your personal information in the manner prescribed in our Privacy Policy. We can and you authorize us to disclose any information about you to law enforcement and other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose you or us to legal liability.
              </p>
              </li>
            </ol>
            <p><b>Cookies</b>. In the course of serving advertisements to this site, our third-party advertiser may place or recognize a unique cookie on your browser. External Service Providers. There may be a number of services offered by external service providers that help you use our Websites. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or grant them permission to collect information about you, then their use of your information is governed by their private policy. Other Corporate Entities. We share much of our data, including personally identifiable information about you, with our parent and/or subsidiaries that are committed to serving your online needs and related services, throughout the world. To the extent that these entities have access to your information, they will treat it at least as protectively as they treat information they obtain from their other consumers. Our parent and/or subsidiaries will follow privacy practices no less protective of all consumers than our practices described in this document, to the extent allowed by applicable law. It is possible that Travelance.ca and/or its subsidiaries, or any combination of such, could merge with or be acquired by another business entity.</p>
            <p><b>Cooperate with Government Authorities.</b> TRAVELANCE.CA reserves the right to cooperate fully with governmental authorities, private investigators and/or injured third parties in the investigation of any suspected criminal or civil wrongdoing.  TRAVELANCE.CA may disclose the User’s identity, contact and any other information, if requested by a government or law enforcement body, an injured third party, or as a result of a subpoena or other legal action, and TRAVELANCE.CA® shall not be liable for damages or results thereof and User agrees not to bring any action or claim against TRAVELANCE.CA for such disclosure.</p>
            <p><b>No Consequential Damages.</b> In no event shall TRAVELANCE.CA be liable for any damages including, without limitation: special, direct, indirect, incidental or consequential damages, including but not limited to damages from loss of profits, goodwill, use, data or other intangible losses, business interruption, whether or not TRAVELANCE.CA has been advised of the possibility of such damages, arising out of or in connection with the use of the Site; inability to use by any party, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus, system failure or unauthorized access by third parties to data or private information of any User.</p>
            <p><b>Aggregate Liability.</b> Without prejudice to the above and subject to applicable laws, the aggregate liability of TRAVELANCE.CA, its employees, agents, directors, officers, affiliates, representatives, assigns, third party suppliers of information or documents or anyone acting on its behalf with respect to each User for all claims relating to the use of the Site services.</p>
            <h5> Confidential Information</h5>
            <p>You agree to keep safe the Confidential Information and not to disclose or make available for disclosure to any person, any part of it. We will defend the intellectual property rights in connection with our Product and Our Website, including copyright in the Content whether provided by us or by any other content provider (including copyright in: text, graphics, logos, icons, images, audio clips, digital data). We also claim copyright in the designs and compilation of all Content of Our Website. Title, ownership rights, and shall remain the sole property of us and / or the other content provider.</p>
            <p>We will strongly protect those rights in all countries. Except as set out below, you may not copy, modify, publish, transmit, transfer or sell, reproduce, create derivative works from, distribute, perform, display, or in any way exploit any of the Content, in whole or in part. You may not use our name or logos or trademarks or any other Content on any website of yours or that of any other person. Subject to the other terms of this agreement, you may download or copy Content only for your own personal use, provided that you maintain all copyright and other notices contained in it. You may not store electronically any significant portion of any Content.</p>
            <h5> Online Quotes</h5>
            <p>Online quotes may not be available for all provinces, products, services or coverage selections. All quotes generated by this site are estimates based upon the information you provided and are not a contract, binder, or agreement to extend insurance coverage. Any coverage descriptions provided on this site are general descriptions of available coverage’s and are not a statement of contract. To obtain coverage you must submit an application. All applications are subject to underwriting approval. Coverage’s and availability may vary by province; and additional minimum coverage limits may be available. Certain links on the Travelance.ca, website/s lead to resources located on servers maintained by third parties over which Travelance.ca has no control.  As such, Travelance.ca makes no representation as to the accuracy or any other aspect of the information contained on such servers.  These links are provided solely as a convenience to you and do not serve as an endorsement by Travelance.ca of the contents on such third party websites.</p>
            <p>If you decide to access linked third party websites, you do so for your own benefit and at your own risk. If you choose to use the services provided by those sites, Travelance.ca is not responsible for the privacy practices of those sites, even though Travelance.ca’s name or logo may appear on those sites. Our Privacy Statement applies solely to the Travelance.ca Website/s.</p>
            <h5> Applicable Law</h5>
            <p>These Terms of Use are governed by and construed in accordance with the laws of the Province of Ontario and the laws of Canada applicable therein, without regard to the conflicts of laws provisions thereof, and each party adorns to the jurisdiction of the courts therein.</p>
            <p>Last modified, July 29, 2013</p>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["terms-conditions"]['lang']} />
    </>
  );
}

export default TermsConditions;
