import React from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './Login.css'
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { useFormik } from "formik";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { trackPromise } from 'react-promise-tracker';

const Login = (props) => {
  const [error, setError] = useState("");
  const signIn = useSignIn();
  const navigate = useNavigate();
  const onSubmit = async (values) => {
    setError("");
    const dataToSubmit = {
      'username':values.email,
      'password':values.password
    };
      let url = `${process.env.REACT_APP_API_AUTH_TOKEN}`;
      trackPromise(
        axios.post(url,dataToSubmit).then((res) => {
          console.log(res);
          if(res.status === 200){
            console.log('inn');
            if(signIn({
              auth: {
                token: res.data.token,
                type: 'Bearer',
                tokenType: "Bearer",
                authState: { email: res.data.user_email },
              },
              userState: {
                name: res.data.user_nicename,
                uid: res.data.user_email
              }
            })){
              navigate("/");
            }else {
              setError("Try Again");
            }
          }else{
            setError("Try Again");
          }
        })
      );
    };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(),
      password: Yup.string().required().min(4),
    }),
    onSubmit,
  });
  return (
    <Container className="login">
      <Row>
        <Col>
          <form onSubmit={formik.handleSubmit}>

            <h1>Welcome Back!</h1>
            <div className="error">{error}</div>
            <Form.Group className="form-group">
              <Form.Control type="text" name="email" className="" placeholder="Username" required="required" value={formik.values.email} onChange={formik.handleChange} />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Control type="password" name="password" className="" placeholder="Password" required="required" value={formik.values.password} onChange={formik.handleChange} />
            </Form.Group>
            <Form.Group className="form-group">
              <Button kind="primary" name="btnSubmit" type="submit">
                Login
              </Button>
            </Form.Group>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
