import React, { useState,useRef } from 'react';
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import $ from 'jquery';
import ReCAPTCHA from "react-google-recaptcha";
import Cf7FormWrapper from "./../../../cf7-form-wrapper"

function Form({ handler, isLoading, isSent, hasError }) {
  const [formState, setFormState] = useState({})
  const captchaRef = useRef(null);

  const handleFieldChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
    })
  }

  const handleFormSubmit = async(e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    if(!token){
      alert('Please click on the reCAPTCHA box.');
    }else{
      captchaRef.current.reset();
      const dataToSubmit = $(e.target).serializeArray().reduce(function(prev, curr) {
        prev[curr.name] = curr.value;
        return prev;
      }, {});
      console.log(dataToSubmit);
      dataToSubmit.grecaptcharesponse = token;
      dataToSubmit._wpcf7_recaptcha_response = token;
      formState._wpcf7_recaptcha_response = token;
      formState['g-recaptcha-response'] = token;
      formState._wpcf7 = '22927';
      formState['cf7sr-recaptcha'] = token;
      formState._wpcf7_unit_tag = "wpcf7-f22927-p35-o1";
      handler(e, formState);
    }
  }

  return (
      <form id="contactPage" action="" method="POST" encType="multipart/form-data" name="contactPage" onSubmit={handleFormSubmit} >
        <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f22927-p35-o1" />
        <Row>
          <Col xl={6}>
            <div className="form-group">
              <label>Name: <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="contact-name" type="text" name="contact-name" placeholder="John Smith" onChange={(e) => handleFieldChange("contact-name", e)} required />
            </div>
          </Col>
          <Col xl={6}>
            <div className="form-group">
              <label>Email: <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="contact_email" type="email" name="contact_email" placeholder="example@website.com" onChange={(e) => handleFieldChange("contact_email", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Subject <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="contact_subject" type="text" name="contact_subject" placeholder="Query About" onChange={(e) => handleFieldChange("contact_subject", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Message <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <textarea className="select" id="message" type="text" rows="10" name="message" placeholder="Your Feedback/Suggestion/Query" onChange={(e) => handleFieldChange("message", e)} ></textarea>
            </div>
          </Col>
          <Col xl={12}>
            <ReCAPTCHA sitekey="6LeUkOAUAAAAAKjgWJg-4bZ7lFiXG4hj7bW5ikBo" ref={captchaRef} />
          </Col>
          <br />
          <Col xl={12}>
            <input className="btn btn-primary" type="submit" name="Submit" value="Submit" />
            {
              isSent ?
              (<div className="wpcf7-response-output" >Thank you for your message. It has been sent.</div>):""
            }
            {hasError ?
                (<div className="wpcf7-response-output-erron" >{hasError}</div>)
              :""}
          </Col>
        </Row>
      </form>
  )
}

const Contact = (props) => {
  return (
    <>
      <Header en="/contact-us" fr="/contactez-nous" lang="en" />
      <div className="container mainDiv contactus">
        <div className="row">
          <div className="col-xs-12 content">
            <h1>Contact Us</h1>
            <div className="header_contactus bannerInner"></div>
            <Container>
              <Row>
                <Col xl={4}>
                  <h2><i className="fa fa-phone-square fa-sm" aria-hidden="true"></i><a href="tel:1-855-566-8555"> 1-855-566-8555</a></h2>
                  <h2><i className="fa fa-envelope fa-sm" aria-hidden="true"></i><a href="mailto:info@travelance.ca"> info@travelance.ca</a></h2>
                </Col>
                <Col xl={8}>
                  <div className="inline_style">
                    <dl>
                      <dt><i className="fa-6x clock"></i></dt>
                      <dd>
                        <h2>Business Hours</h2>
                        <p>Open: Monday to Friday, 9:00 am to 7:00 pm (ET)<br/>
                        Closed: Saturday, Sunday, and Statutory Holidays</p>
                      </dd>
                    </dl>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={8}>
                  <iframe style={{border:'0px',marginBottom:'30px'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2905.640014740852!2d-79.86534088451499!3d43.25896587913695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce045513797d43%3A0xf5d116ca07e3d886!2sTravelance!5e0!3m2!1sen!2sin!4v1566243906073!5m2!1sen!2sin" width="100%" height="400" frameBorder="0" allowFullScreen="allowfullscreen" title="map"></iframe>
                  <p>Help is only a message away. Use the form below to contact us.</p>
                  <Cf7FormWrapper siteUrl="https://www.travelance.ca/backend" formId="22927">
                    <Form />
                  </Cf7FormWrapper>
                </Col>
                <Col xl={4}>
                  <div className="stickyDiv" >
                  <div className="holder_block">
<h2>All Products</h2>
<ul className="list">
<li><a href="/products/trips/travel/">Travel</a></li>
<li><a href="/products/trips/youth/">Youth</a></li>
<li><a href="/products/visitors-canada-insurance/">VTC Emergency Medical Insurance</a></li>
<li><a href="/products/student-accident-plan/">Student Accident Plan</a></li>
      <li><a href="/products/international-student/">International Student Plan</a></li>
</ul>
</div>
<div className="holder_block">
<h2>Quick Links</h2>
<ul className="list">
<li><a href="https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&amp;returnurl=step1.aspx">Get a Quote</a></li>
<li><a href="/#faqSection">FAQs</a></li>
<li><a href="/about-our-underwriter/">Our Underwriter</a></li>
<li><a href="/claims/">Claims</a></li>
</ul>
</div>

                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <Footer lang="en" />
    </>
  );
}

export default Contact;
