import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const AboutUs = (props) => {
  return (
    <>
    <Header en={GLobalLinks["about-us"]['en']} fr={GLobalLinks["about-us"]['fr']} lang={GLobalLinks["about-us"]['lang']} />
    <div className="container mainDiv ab aboutUs">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="about-us">About Us</h1>
          <div>
            <div className="header_about bannerInner"></div>
            <h2>Strategic Goals and Values</h2>
            <p>Travelance is an insurance distributor dedicated to providing superior service and products to our clients. Our clients include policyholders, brokers, travel agents, and agencies.</p>
            <p>The following is our commitment to our clients:</p>
            <div className="inline_style">
              <dl>
                <dt><i className="fa-6x exceptional_expn"></i></dt>
                <dd>
                  <h2>To Deliver an Exceptional Client Experience</h2>
                  <ul className="list">
                    <li>Provide knowledgeable and professional service to our clients</li>
                    <li>Utilize technology to provide timely and accurate insurance information</li>
                    <li>Listen to and consider our clients’ needs to provide a positive and constructive experience</li>
                  </ul>
                </dd>
              </dl>
            </div>
            <div className="inline_style">
              <dl>
                <dt><i className="fa-6x valuable_prod"></i></dt>
                <dd>
                  <h2>To Offer Valuable Products</h2>
                  <ul className="list">
                    <li>Provide the peace of mind that comes from knowing help is only a phone call away</li>
                    <li>Continue to develop affordable products with competitive benefits resulting from fantastic feedback from our clients</li>
                    <li>Deliver the strength, security, and stability of an established underwriter</li>
                    <li>Maintain a simple claims process</li>
                  </ul>
                </dd>
              </dl>
            </div>
            <div className="inline_style">
              <dl>
                <dt><i className="fa-6x comm_prof"></i></dt>
                <dd>
                  <h2>To Communicate Professionally and Ethically</h2>
                  <ul className="list">
                    <li>Operate with honesty and integrity</li>
                    <li>Provide well-researched information to our clients in our communications</li>
                    <li>Educate our clients and the public about the value of insurance</li>
                  </ul>
                </dd>
              </dl>
            </div>
            <div className="inline_style">
              <dl>
                <dt><i className="fa-6x industry_lead"></i></dt>
                <dd>
                  <h2>To be Industry Leaders</h2>
                  <ul className="list">
                    <li>Promote the need for insurance</li>
                    <li>Conduct continual research in our industry</li>
                    <li>Work with other industry leaders to help develop effective insurance practices</li>
                  </ul>
                </dd>
              </dl>
            </div>
            <div className="inline_style">
              <dl>
                <dt><i className="fa-6x env_impact"></i></dt>
                <dd>
                  <h2>To be Mindful of our Impact on the Environment</h2>
                  <ul className="list">
                    <li>Offer paperless solutions through technology</li>
                    <li>Reduce our waste by reusing what we can and recycling what we cannot</li>
                    <li>Offer our team and clients tools to conduct business in an environmentally responsible manner</li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["about-us"]['lang']} />
    </>
  );
}

export default AboutUs;
