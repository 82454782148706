import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const ProductsISP = (props) => {

  return (
    <>
    <Header en={GLobalLinks["products/international-student"]['en']} fr={GLobalLinks["products/international-student"]['fr']} lang={GLobalLinks["products/international-student"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="isp">International Student Travel Insurance (ISP)</h1>
          <div>
          <div className="bannerInner header_international-student-plan"></div>
          <p>Students may not be covered under Canada’s universal health care system, meaning students and their families would have to pay out of pocket for any unexpected medical emergencies. Our International Student Plans provide coverage for students coming to study at a Canadian school, their spouses and their dependents, important emergency medical coverage while here in Canada. Plans can be purchased for a minimum of 14 days up to a maximum of 365 days.</p>

          <h2>Choose from two plans:</h2>
          <ul>
            <li>The Smart Plan is a great option for those who are in good health, not taking any medication, with no pre-existing conditions.</li>
            <li>The Smart-Plus Plan provides some coverage for pre-existing conditions that have been stable and controlled for more than 90 days (conditions apply).</li>
          </ul>
          <div className="holder_block" style={{textAlign: 'center'}}>
            <a href="https://quote.travelance.ca/#/ISP" target="_blank" rel="noopener noreferrer">
                <button style={{fontSize: '15px',textAlign: 'right',display: 'inline-block',background: '#cc4037',border: '1px solid #cc4037',boxShadow: '0px 3px 0px #a72720',borderRadius: '3px',padding: '8px 12px',color: '#fff',cursor: 'pointer'}}>GET A FREE QUOTE</button>
            </a>
          </div>
          <strong>International Student Plan Benefits</strong>
          <table style={{border: '1px solid #787276', padding: '10px'}}>
              <tbody>
                  <tr>
                      <td style={{border: '1px solid #787276', backgroundColor: '#cc4037', color: '#fff', padding: '10px'}} colSpan="2"><b>Emergency Medical Insurance</b></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Emergency Medical Treatment</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>$2,000,000</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Drugs or Medications</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $10,000 to a 30-day supply</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Private Duty Nursing</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $15,000</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Professional Medical Services </span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $600 per practitioner</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Emergency Air Transportation</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>When pre-approved by Zurich Travel Assist</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Physical Examination</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $150 for one visit</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Non-Emergency Treatment</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $3,000</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Maternity Benefit</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $25,000 for pre-natal care</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Impacted Wisdom Teeth</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $150 per tooth</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Dental Injury</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $5,000 for an accident</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Dental Pain</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $600</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Repatriation</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $15,000</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Psychiatric and Psychological Care</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $10,000 for in-patient care</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Family Transportation</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $5,000</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Eye Examination</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>One visit per 12-month period</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Prescription Glasses, Contact Lenses, Hearing Aids</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $200</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Tutorial Services</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Up to $20/hour, up to $400</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Accidental Death &amp; Dismemberment</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>$10,000</span></td>
                  </tr>
                  <tr>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>Deductible</span></td>
                      <td style={{border: '1px solid #787276', padding: '10px'}}><span style={{fontWeight: '400'}}>$0</span></td>
                  </tr>
              </tbody>
          </table>
          <p><a href="https://travelance.box.com/v/isp-policy-eng" target="_blank" rel="noopener noreferrer">Click here to review the policy wording</a>. This is a brief description of coverage. Please see policy wording for complete benefits, details,
          exclusions, limitations, terms and conditions.</p>

          <em><span style={{fontSize: '0.8em'}}>Underwritten by Zurich Insurance Company Ltd (Canadian Branch).</span></em>



          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["products/international-student"]['lang']} />
    </>
  );
}

export default ProductsISP;
