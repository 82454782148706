import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const AboutOUW = (props) => {
  return (
    <>
    <Header en={GLobalLinks["about-our-underwriter"]['en']} fr={GLobalLinks["about-our-underwriter"]['fr']} lang={GLobalLinks["about-our-underwriter"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="about-our-underwriter">About Our Underwriters</h1>
          <div>
            <h2><strong>Old Republic Insurance Company of Canada</strong></h2>
            <p>Old Republic Insurance Company of Canada, located in Hamilton, Ontario represents the Canadian operations of Old Republic    International, one of the 50 largest publicly held insurance operations in North America.</p>
            <p>Old Republic Insurance Company of Canada has been providing insurance solutions for Canadians since 1887 and, in particular, travel insurance solutions since 1978. Their partners know them for their expertise, flexibility and innovations while their policyholders know them by how they handle and communicate their claims service. It’s their service and professionalism that set them apart from their competitors and solidifies their reputation within the industry.</p>
            <p><em>Old Republic Insurance Company of Canada underwriters the Visitors to Canada Emergency Medical Insurance Plans, Travel Right Insurance Plans, and the Student Accident Plan.</em></p>
            <h2><strong>Zurich Insurance Company Ltd (Canadian Branch)</strong></h2>
            <p>Zurich Insurance Company Ltd (Canadian Branch) is a part of Zurich Insurance Group, a leading multi-line insurer that services its customers in global and local markets. Zurich North America is one of the largest providers of insurance solutions and services to businesses and individuals. They employ over 9,000 people in North America with offices throughout Canada and the US.</p>
            <p><em>Zurich Insurance Company Ltd (Canadian Branch) underwrites the International Student Travel Insurance Policy.</em></p>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["about-our-underwriter"]['lang']} />
    </>
  );
}

export default AboutOUW;
