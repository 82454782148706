import React from 'react';
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import PartnerForm from "../../../commons/PartnerForm/PartnerForm";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const PartnerPage = (props) => {  

  return (
    <>
      <Header en='/partner' fr='/partenaires' lang='en' />
      <Container className="mainDiv partnerPage">
        <Row className="">
          <Col xl={12} className="content">
            <h1>Partner</h1>
            <Container>
              <Row className="">
                <Col xl={8} className="">
                  <PartnerForm heading="Complete this form to begin the sign-up process with Travelance." />

                  <div className="outline_box">
                    <h2><i className="fa-6x why"></i>Why Travelance?</h2>
                    <p>Not only does Travelance offer competitive products but all products are easy to access and issue through our online portal. With the support of a reputable underwriter and exceptional service, our partners at Travelance can expect great insurance policies along with technical support that is equally as great.</p>
                    <a href="/" className="btn-2">Learn More</a>
                  </div>
                  <div className="outline_box">
                    <h2><i className="fa-6x product"></i>Products</h2>
                    Travel insurance is not a one-size-fits-all product. Offering variety helps you to find the best plans to meet your clients’ needs.
                    <h2>Choose from Four Products!</h2>
                    <h3><a href="/products/trips/">Travel Right Insurance Plans (TRIPs)</a></h3>
                    <ul className="list">
                      <li><a href="/products/trips/youth/">TRIPs Youth</a></li>
                      <li><a href="/products/trips/travel/">TRIPs Travel</a></li>
                    </ul>
                    <h3><a href="/products/visitors-canada-insurance/">Visitors to Canada Emergency Medical Insurance (VTC)</a></h3>
                    <ul className="list">
                      <li>VTC Essential</li>
                      <li>VTC Premier</li>
                    </ul>
                    <h3><a href="/products/international-student/">International Student Travel Insurance Policy (ISP)</a></h3>
                      <ul className="list">
                        <li>Smart Plan</li>
                        <li>Smart-Plus Plan</li>
                      </ul>
                    <h3><a href="/products/student-accident-plan/">Student Accident Insurance Plan (SAP)</a></h3>
                    Learn more about our complete suite of insurance products! <a href="/products/">LEARN MORE</a>
                  </div>
                </Col>
                <Col xl={4} className="">
                  <div className="stickyDiv">
                    <div className="holder_block">
                      <h2>All Products</h2>
                      <ul className="list">
                        <li><a href="/products/trips/travel/">Travel</a></li>
                        <li><a href="/products/trips/youth/">Youth</a></li>
                        <li><a href="/products/visitors-canada-insurance/">VTC Emergency Medical Insurance</a></li>
                        <li><a href="/products/student-accident-plan/">Student Accident Plan</a></li>
                        <li><a href="/products/international-student/">International Student Plan</a></li>
                      </ul>
                    </div>
                    <div className="holder_block">
                      <h2>Quick Links</h2>
                      <ul className="list">
                        <li><a href="https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&returnurl=step1.aspx">Get a Quote</a></li>
                        <li><a href="/#faqSection">FAQ</a></li>
                        <li><a href="/about-our-underwriter/">Our Underwriter</a></li>
                      </ul>
                    </div>
                    <div className="holder_block">
                      <h2><a href="https://travelance.box.com/v/Travelance-Product-Portfolio">View Travelance's Product Portfolio</a></h2>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer lang="en" />
    </>
  );
}

export default PartnerPage;
