import React from 'react';
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import PartnerForm from "../../../commons/PartnerForm/PartnerForm";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Banner from "../../../../assets/images/travelance-banner-online-training.webp"

const PartnerEfgPage = (props) => {

  return (
    <>
      <Header en='/partner/experiorfinancialgroup' fr='/fr' lang='en' />
      <Container className="mainDiv partnerFormPage">
        <Row className="">
          <Col xl={12} className="content">
            <h1>Experior Financial Group Information Centre</h1>
            <Container>
              <Row className="">
                <Col xl={12}>
                     <img src={Banner} width="100%" alt="experior-training" target="_blank" />
                     <br />
                     <h2><strong>Welcome to the Travelance Information Centre for Experior Financial Group Brokers</strong></h2>
                     <p>On this page, you will find the tools to help you get started selling Travelance products.</p>
                </Col>
                <Col xl={8} className="">
                  <PartnerForm heading="Complete this form to begin the sign-up process with Travelance." mga="efg" />
                  <br />
                  <h2>Online Training</h2>
         				  <p><a href="/partner/online-training-sessions/" target="_blank" rel="noopener noreferrer">Live Online Training Session</a> - Register for our online training sessions to learn about our products and services. Each week, our team members lead interactive, lively, and information-packed sessions, with small class sizes, and a team member can answer all your questions.</p>

         				 <p><a href="https://academy.travelance.ca/remote-login/login.cfm"  rel="noopener noreferrer">Travelance Academy</a> - Our most flexible training option. Once you have completed the partnership process with Travelance, you will be able to access Travelance Academy through MyTL. Access on-demand, online learning, that you can complete on your own schedule.</p>

         				 <p>Looking for training for your team? Email <a href="mailto:training@travelance.ca">training@travelance.ca</a> to request a session.</p>

         				 <h2>Travelance Partner Resources</h2>
         				 <p>To help you get started selling Travelance products, we’ve created resources to help you along every step of the way. Access complete policy wordings, rate charts, steps to selling guides, links to useful resources, and more! <a href="/partner/resources/" target="_blank" rel="noopener noreferrer">Click here to access our Partner Resource Webpage</a>.</p>

         				 <h2>Contact Information</h2>
         				 <p>For policy related questions, changes, or portal assistance contact Support Services, Monday to Friday, 9:00 am to 7:00 pm (ET), <a href="mailto:info@travelance.ca">info@travelance.ca</a> or <a href="tel:8555668555">1-855-566-8555</a> extension 1.</p>

         				 <h2>For Contracting Inquiries</h2>
         				 <ul className="list">
         				 <li>West Contracting - <a href="mailto:westcontracting@experiorheadoffice.ca">westcontracting@experiorheadoffice.ca</a></li>
         				 <li>East Contracting - <a href="mailto:eastcontracting@experiorheadoffice.ca">eastcontracting@experiorheadoffice.ca</a></li>
         				 </ul>

                </Col>
                <Col xl={4} className="">
                  <div className="stickyDiv">
                  <div className="holder_block">
         					 <h2>Quick Links</h2>
         					 <ul className="list">
         					 <li><a href="/claims/" target="_blank" rel="noopener noreferrer">Claims</a></li>
         					 <li><a href="https://www.getreliable.com/getreliable/" target="_blank" rel="noopener noreferrer">Online Portal Login</a></li>
         					 <li><a href="/about-our-underwriter/" target="_blank" rel="noopener noreferrer">Our Underwriter</a></li>
         					 <li><a href="/#faqSection" target="_blank" rel="noopener noreferrer">Partner FAQs</a></li>
         					 <li><a href="/partner/online-training-sessions/" target="_blank" rel="noopener noreferrer">Register for Online Training</a></li>
         					 <li><a href="https://www.travel.gc.ca/travelling/advisories/" target="_blank" rel="noopener noreferrer">Travel Advisories</a></li>
         					 </ul>

         					 {/*<button className="btn btn-primary">View Travelance's<br />Product Portfolio</button>*/}
         				 </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer lang="en" />
    </>
  );
}

export default PartnerEfgPage;
