import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const ProductsVCIM = (props) => {
  return (
    <>
    <Header en={GLobalLinks["products/visitors-canada-insurance/monthly"]['en']} fr={GLobalLinks["products/visitors-canada-insurance/monthly"]['fr']} lang={GLobalLinks["products/visitors-canada-insurance/monthly"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="visitors-canada-insurance-monthly">Super Visa Insurance Monthly Payment Plan</h1>
          <div>
            <div className="bannerInner header_visitors-canada-insurance-monthly"></div>
            <div className="row">
              <div className="col-lg-12">
                <p>The <strong>Monthly Payment Option</strong> offers a convenient way to pay the premium for <a href="/products/visitors-canada-insurance/monthly/">Visitors to Canada Emergency Medical Insurance</a> .</p>
              </div>
              <div className="col-lg-12">
                <p><strong>Requirements:</strong>
                  <ul>
                   	<li>Available for plans with 90 travel days or more</li>
                   	<li>Two months premium collected on application date</li>
                   	<li>Equal monthly payments are applied to the credit card starting on the start date of the policy</li>
                   	<li>Prorated daily refunds available for cancellations (administration fees apply)</li>
                   	<li>$60 non-refundable billing fee</li>
                  </ul>
                </p>
              </div>
              <div className="col-lg-12">
                <p><a href="/contact-us/">Contact us</a> or your Travelance partnered broker to learn more!</p>
                <p>Emergency medical insurance purchased for the purposes of the parent and grandparent super visa must meet <a href="https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/temporary-residents/visitors/super-visa.html">IRCC requirements.</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["products/visitors-canada-insurance/monthly"]['lang']} />
    </>
  );
}

export default ProductsVCIM;
