import React from "react";
import { NavLink } from "react-router-dom";
import "./DropdownButton.css";
import $ from 'jquery';

const DropdownButton =(props)=>{

  function handleClick(e){
    var pp = $(e.target).parent('.custom-dropdown').parent();
    var pc = $(e.target).parent('.custom-dropdown');
    var c = $(e.target);
    var cc = pc.find('.custom-dropdown-menu');
    pp.find('.custom-dropdown').not(pc).removeClass('show');
    pp.find('.custom-dropdown-toggle').not(c).removeClass('show');
    pp.find('.custom-dropdown-menu').not(cc).removeClass('show');
    c.toggleClass('show');
    pc.toggleClass('show');
    cc.toggleClass('show');
  }

  const items = props.links;
  const id = props.did;

  if(props.lang === 'fr'){
    return (
      <div id={id} className={`${props.class} custom-dropdown`}>
        <button type="button" id={`${id}-dropdownbutton`} className="btn custom-dropdown-toggle"  data-id={id} onClick={handleClick}>{props.titlefr}</button>
        <div className="custom-dropdown-menu" >
          {
            items.map((item,i)=>(
              <NavLink key={i} to={item.link} rel={item.rel} target={item.target} className="custom-dropdown-item">{item.textfr}</NavLink>
            ))
          }
        </div>
      </div>
    );
  }else{
    return (
      <div id={id} className={`${props.class} custom-dropdown`}>
        <button type="button" id={`${id}-dropdownbutton`} className="btn custom-dropdown-toggle"  data-id={id} onClick={handleClick}>{props.title}</button>
        <div className="custom-dropdown-menu" >
          {
            items.map((item,i)=>(
              <NavLink key={i} to={item.link} rel={item.rel} target={item.target} className="custom-dropdown-item">{item.text}</NavLink>
            ))
          }
        </div>
      </div>
    );
  }
}

export default DropdownButton;
