import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";
import $ from 'jquery';

function medicalPlan(id) {
  $('#trips table.tablestyle td').find('.btn-2.btnPr').each(function(){
    $(this).css('display','none');
  });
  $('#'+id).css('display','inline');
  return id;
}
const ClaimForms = (props) => {
  $(document).ready(function(){
    $('.mainDiv .nav-link').on('click',function(e){
      e.preventDefault();
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      var id = $(this).attr('href');
      $('.tab-pane').removeClass('active');
      $(id).addClass('active');
    });
  });

  return (
    <>
      <Header en={GLobalLinks["claims/forms"]['en']} fr={GLobalLinks["claims/forms"]['fr']} lang={GLobalLinks["claims/forms"]['lang']} />
      <div className="container mainDiv ab">
        <div className="row">
          <div className="col-xs-12 content">
            <h1 className="forms">Claims Submission and Forms</h1>
            <div>
              <div className="row">
                <div className="col-lg-3 content vertical-tab">
                  <ul id="myTab" className="nav nav-tabs" role="tablist">
                    <li className="nav-item"><a id="trips-tab" className="nav-link active" role="tab" href="#trips" data-toggle="tab" aria-controls="messages" aria-selected="false">For Visitors to Canada, Travel Right Insurance, and Student Accident Plan Claims</a></li>
                    <li className="nav-item"><a id="internationalstudent-tab" className="nav-link" role="tab" href="#internationalstudent" data-toggle="tab" aria-controls="messages" aria-selected="false">For International Student Travel Insurance Policy Claims</a></li>
                  </ul>
                </div>
                <div className="col-lg-9 content vertical-top">
                  <div className="tab-content">
                    <div id="trips" className="tab-pane active" role="tabpanel" aria-labelledby="trips-tab">
                      <h4>For Visitors to Canada, Travel Right Insurance, and Student Accident Plan Claims</h4>
                      <h3>Option 1: <span className="highlight">Submit Your Claim Information Electronically (eClaims)</span></h3>
                      <p>Submit your claim electronically through the online eClaims system. Complete the forms, attach all required documentation online, and request direct deposit claim payment. <a href="https://www.oldrepubliccanada.com/eClaims/Account/Login?cc=tai&lang=en" rel="noopener noreferrer" target="_blank">Click here to submit your claim online</a>.</p>
                      <h3>Option 2: <span className="highlight">Download, Complete, and Return Claim Documentation</span></h3>
                      <p>Download the claim form specific to your claim, complete the form(s), attach all required documentation, and mail to the address shown on the form(s). If you have any questions, please contact our underwriters at the number listed below.</p>
                      <table width="100%" cellPadding="0" cellSpacing="0" className="tablestyle">
                        <tbody>
                          <tr>
                            <td>
                              <h2>Visitors to Canada*</h2>
                              <p>If you are insured under one of our Visitors to Canada plans and you suffered a covered sickness or injury during your period of coverage resulting in out-of-pocket expenses.</p>
                            </td>
                            <td>
                              <a href="https://travelance.box.com/v/vtc-claim-form" className="btn-2" target="_blank" rel="noopener noreferrer">Download Form</a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h2>Trip Cancellation / Trip Interruption – Medical*</h2>
                              <p>If your policy provides Trip Cancellation/Interruption coverage and you must cancel or interrupt your trip due to a covered injury or sickness.</p>
                            </td>
                            <td>
                              <a href="https://travelance.box.com/v/trip-cancellation-claim-form" className="btn-2" target="_blank" rel="noopener noreferrer">Download Form</a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h2>Trip Delay*</h2>
                              <p>If your policy provides Trip Delay coverage and your trip is delayed on or after your departure date due to a covered risk resulting in additional expenses.</p>
                            </td>
                            <td>
                              <a href="https://travelance.box.com/v/trip-delay-claim-form" className="btn-2" target="_blank" rel="noopener noreferrer">Download Form</a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h2>Student Accident*</h2>
                              <p>If you are insured under the Student Accident Plan and would like to submit a claim.</p>
                            </td>
                            <td>
                              <a href="https://travelance.box.com/v/sap-claim-form" className="btn-2" target="_blank" rel="noopener noreferrer">Download Form</a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h2>Emergency Medical*</h2>
                              <div>
                                <select id="test" name="form_select" defaultValue="0" onChange={(e) => medicalPlan(e.target.value)}>
                                  <option value="0">&lt; select province of residence &gt;</option>
                                  <option value="1">Alberta</option>
                                  <option value="2">British Columbia</option>
                                  <option value="3">Manitoba</option>
                                  <option value="4">New Brunswick</option>
                                  <option value="5">Newfoundland</option>
                                  <option value="6">Nova Scotia</option>
                                  <option value="7">Ontario</option>
                                  <option value="8">Prince Edward Island</option>
                                  <option value="9">Quebec</option>
                                  <option value="10">Saskatchewan</option>
                                  <option value="11">Yukon</option>
                                </select>
                              </div>
                              <p>If your policy provides Emergency Medical coverage and you suffer a covered sickness or injury during your covered trip that required emergency medical treatment resulting in out-of-pocket expenses.</p>
                            </td>
                            <td>
                              <a id="11" className="btn-2 btnPr" href="https://travelance.box.com/v/claims-em-YK"  style={{display:'none',float:'left',marginTop:'10px'}} target="_blank" rel="noopener noreferrer">Yukon Download Form</a>
                              <a id="10" className="btn-2 btnPr" href="https://travelance.box.com/v/claims-em-SK" style={{display:'none',float:'left',marginTop:'10px'}} target="_blank" rel="noopener noreferrer">Saskatchewan Download Form</a>
                              <a id="9" className="btn-2 btnPr" href="https://travelance.box.com/v/claims-em-QC" style={{display:'none',float:'left',marginTop:'10px'}} target="_blank" rel="noopener noreferrer">Quebec Download Form</a>
                              <a id="6" className="btn-2 btnPr" href="https://travelance.box.com/v/claims-em-NS" style={{display:'none',float:'left',marginTop:'10px'}} target="_blank" rel="noopener noreferrer">Nova Scotia Download Form</a>
                              <a id="8" className="btn-2 btnPr" href="https://travelance.box.com/v/claims-em-PE" style={{display:'none',float:'left',marginTop:'10px'}} target="_blank" rel="noopener noreferrer">Prince Edward Island Download Form</a>
                              <a id="5" className="btn-2 btnPr" href="https://travelance.box.com/v/claims-em-NL" style={{display:'none',float:'left',marginTop:'10px'}} target="_blank" rel="noopener noreferrer">Newfoundland Download Form</a>
                              <a id="4" className="btn-2 btnPr" href="https://travelance.box.com/v/claims-em-NB" style={{display:'none',float:'left',marginTop:'10px'}} target="_blank" rel="noopener noreferrer">New Brunswick Download Form</a>
                              <a id="3" className="btn-2 btnPr" href="https://travelance.box.com/v/claims-em-MB" style={{display:'none',float:'left',marginTop:'10px'}} target="_blank" rel="noopener noreferrer">Manitoba Download Form</a>
                              <a id="2" className="btn-2 btnPr" href="https://travelance.box.com/v/claims-em-BC" style={{display:'none',float:'left',marginTop:'10px'}} target="_blank" rel="noopener noreferrer">British Columbia Download Form</a>
                              <a id="1" className="btn-2 btnPr" href="https://travelance.box.com/v/claims-em-AB" style={{display:'none',float:'left',marginTop:'10px'}} target="_blank" rel="noopener noreferrer">Alberta Download Form</a>
                              <a id="7" className="btn-2 btnPr" href="https://travelance.box.com/v/claims-em-ON" style={{display:'none',float:'left',marginTop:'10px'}} target="_blank" rel="noopener noreferrer">Ontario Download Form</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <h3>Accidental Death and Dismemberment*</h3>
                      <p>If your policy provides Accidental Death and Dismemberment coverage and, in the event of your loss of life, limb, sight or the complete and irrecoverable loss of your speech or hearing resulting from an injury sustained while on your covered trip, please contact our underwriter’s Client Services Department at the number below for assistance.</p>
                      <p><strong>* Please read your policy for complete conditions, limitations, and exclusions that apply.</strong></p>
                      <h3>Option 3: <span className="highlight">Call Us to Report a Claim</span></h3>
                      <p>You can initiate a claim by contacting our underwriters’ Client Services Department. The Client Services representative will record the initial information concerning your claim and send you the documents required along with the information on completing and submitting your claim.</p>
                      <p><strong>Old Republic Insurance Company of Canada</strong><br/>Box 557, 100 King Street West<br/>Hamilton, Ontario<br/>L8N 3K9</p>
                      <p><strong>Phone</strong><br/>1-888-526-0111<br/>Monday to Friday<br/>8:30 am to 8:00 pm ET<br/>5:30 am to 5:00 pm PT</p>
                    </div>
                    <div id="internationalstudent" className="tab-pane" role="tabpanel" aria-labelledby="studentaccidental-tab">
                      <h4>International Student Travel Insurance Policy (ISP)</h4>
                      <p>After you have reported your claim to Zurich Travel Assist, you may submit your claims forms and supporting documentation as follows:</p>
                      <h3>Option 1: <span className="highlight">Personal delivery or registered mail to Zurich Insurance Company Ltd (Canadian Branch) head office in Ontario</span></h3>
                      <p><strong>Zurich Canada Travel Insurance</strong><br/>100 King Street West Suite 5350<br/>Toronto, Ontario, M5X 1C9, Canada</p>
                      <h3>Option 2: <span className="highlight">Personal delivery to our authorized agent in the province</span></h3>
                      <h3>Option 3: <span className="highlight">Electronic delivery to <a href="mailto:travelclaims@wtp.ca">travelclaims@wtp.ca</a>.</span></h3>
                      <p>Claims must be reported within thirty (30) days of occurrence of a claim arising under your policy. Proof of claim must be submitted within ninety days (90) of the date the claim has occurred or the service was provided.</p>
                      <a id="11" className="btn-2" style={{float:'left', marginTop:'10px'}} href="https://travelance.box.com/v/ISP-ClaimForm-English" target="_blank" rel="noopener noreferrer">Click Here to Download, Complete, and Return Claim Documentation</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer lang={GLobalLinks["claims/forms"]['lang']} />
    </>
  );
}

export default ClaimForms;
