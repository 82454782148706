import React from "react";
import './ContactSection.css';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import contact from "../../../assets/images/contact.webp";
import MulticolorTitleTag from "../MulticolorTitleTag/MulticolorTitleTag";

const ContactSection = (props) => {
  const title=[
    {
      'text':'Contact Us',
      'color': 'black'
    }
  ]
  const titleFr=[
    {
      'text':'Contactez-nous',
      'color': 'black'
    }
  ]
  const text = 'to find a licensed Travelance insurance broker in your area, today!';
  const textFr = 'dès aujourd\'hui afin de trouver un agent d\'assurance autorisé de Travelance dans votre région !';
  const buttontext = 'Contact Us';
  const buttontextFr = 'Contactez-nous';
  const buttonlink = '/contact-us';
  const buttonlinkFr = '/contactez-nous';

  return (
    <div className="contactSection">
      <Container>
        <Row className="align-items-center">
          <Col md={5} className="contactImageCol" >
            <div className="imgBackground">

            </div>
            <img loading="lazy" src={contact} alt="contact" className="contactImage" width="363" height="267"/>
          </Col>
          <Col className="" md={7} >
            <MulticolorTitleTag  items={(props.lang==='fr')?titleFr:title} tagName="3"/>
            <p>{(props.lang==='fr')?textFr:text}</p>
            <Button className="red contactButton" href={(props.lang==='fr')?buttonlinkFr:buttonlink}>{(props.lang==='fr')?buttontextFr:buttontext}</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactSection;
