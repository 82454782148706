import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const ProductsTripsY = (props) => {
  return (
    <>
    <Header en={GLobalLinks["products/trips/youth"]['en']} fr={GLobalLinks["products/trips/youth"]['fr']} lang={GLobalLinks["products/trips/youth"]['lang']} />
    <div className="container mainDiv ab trips">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="tripsYouth">Youth Travel Insurance</h1>
          <div>
            <div className="bannerInner header_youth"></div>
            <p>Travelance’s youth travel insurance has been designed exclusively for the young traveller who requires medical and non-medical travel insurance bundled into one convenient package.</p>
            <div className="row">
              <div className="col-lg-8">
                <p>Coverage is available to persons under 30 years of age at the time of purchase. It covers travel up to 365 days and bundles medical and non-medical coverages in one easy-to-purchase, affordable plan. Rates start as low as $38 for a 3 day trip.</p>
                <h2>Product Highlights</h2>
                <div className="product-heading-blue">
                  <h2>Youth Premier Plan</h2>
                </div>
                <ul className="list">
                  <li>Emergency medical up to $1,000,000</li>
                  <li>Emergency medical evacuation/return home up to $1,000,000</li>
                  <li>Trip cancellation and interruption benefits</li>
                  <li>Trip delay up to $1,500</li>
                  <li>Baggage and personal effects: passport, travel visa, and travel tickets loss up to $800</li>
                  <li>Airflight accident up to $25,000</li>
                  <li>24/7 travel assistance</li>
                </ul>
                <a href="https://travelance.box.com/v/youth-policy-trips" target="_blank" rel="noopener noreferrer">Click to review the policy.</a>
                <p>This is a brief description of coverage. Please see policy wording for benefits, definitions, exclusions, limitations, terms and conditions.</p>
                <i>Underwritten by Old Republic Insurance Company of Canada.</i>
              </div>
              <div className="col-lg-4">
                <div className="stickyDiv">
                  <div className="holder_block">
                    <a href="https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&amp;returnurl=step1.aspx" target="_blank" rel="noopener noreferrer">
                      <button style={{fontSize: '15px',textAlign: 'right',display: 'inline-block',marginLeft: '25%',background: '#cc4037',border: '1px solid #cc4037',boxShadow: '0px 3px 0px #a72720',borderRadius: '3px',padding: '8px 12px',color: '#fff',cursor: 'pointer'}}>GET A FREE QUOTE</button>
                    </a>
                  </div>
                  <div className="holder_block">
                    <h2>All Products</h2>
                    <ul className="list">
                      <li><a href="/products/trips/travel/">Travel</a></li>
                      <li><a href="/products/trips/youth/">Youth</a></li>
                      <li><a href="/products/visitors-canada-insurance/">Visitors to Canada Emergency Medical Insurance</a></li>
                      <li><a href="/products/student-accident-plan/">Student Accident Plan</a></li>
                      <li><a href="/products/international-student/">International Student Plan</a></li>
                    </ul>
                  </div>
                  <div className="holder_block">
                    <h2>Quick Links</h2>
                    <ul className="list">
                      <li><a href="https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&amp;returnurl=step1.aspx">Get a Quote</a></li>
                      <li><a href="/#faqSection">FAQs</a></li>
                      <li><a href="/about-our-underwriter/">Our Underwriter</a></li>
                      <li><a href="/claims/">Claims</a></li>
                    </ul>
                  </div>
                  <div className="holder_block">
                    <h3><a href="https://travelance.app.box.com/v/purchase-trips-plans" target="_blank" rel="noopener noreferrer">How to Quote or Purchase a TRIPs Plan from our Website</a></h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["products/trips/youth"]['lang']} />
    </>
  );
}

export default ProductsTripsY;
