import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";
import One from "../../../../assets/images/banner-visitors-canada-insurance.webp"
import Two from "../../../../assets/images/banner-trips.webp"
import Three from "../../../../assets/images/banner-student-accident-plan.webp"
import Four from "../../../../assets/images/ISP-Cover-1.webp"

const Products = (props) => {
  return (
    <>
    <Header en={GLobalLinks["products"]['en']} fr={GLobalLinks["products"]['fr']} lang={GLobalLinks["products"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="privacy-policy">Products</h1>
          <div>
            <p>Travelance insurance is not just about financial protection, it’s about the service and support you carry with you while you travel.</p>
            <h2>Travel and Student Accident Insurance Products</h2>
            <div className="holder_block oddP">
              <h3>Visitors to Canada Emergency Medical Insurance Plans (VTC)</h3>
              <dl>
                <dt>
                  <ul className="list">
                    <li>Designed for visitors to Canada and individuals not eligible for a government health insurance plan (GHIP)</li>
                    <li><a href="/products/insurance-for-visitors-to-canada-with-super-visa/">Super visa</a> eligible when purchased according to Canadian government requirements</li>
                    <li>Supported by 24/7 worldwide, multilingual, travel assistance</li>
                  </ul>
                  <a className="btn-2" href="/products/visitors-canada-insurance/">Find a plan for you</a>
                </dt>
                <dd>
                  <img className="alignnone size-medium wp-image-87" title="" src={One} alt="Travelance Visitors to Canada Emergency Medical Insurance Plans VTC" width="300" height="200" />
                </dd>
              </dl>
            </div>
            <div className="holder_block evenP">
              <h3>Travel Right Insurance Plans (TRIPs)</h3>
              <dl>
                <dd><img className="alignnone size-medium wp-image-96" src={Two} alt="Travelance Travel Right Insurance Plans TRIPs" width="300" height="189" /></dd>
                <dt className="space_before">
                  <ul className="list">
                    <li>For Canadians travelling anywhere in the world</li>
                    <li>A variety of plans to meet most traveller’s needs, including single trip or annual options</li>
                    <li>Individual plans or package plans available</li>
                    <li>Family rates available</li>
                    <li>Supported by 24/7 worldwide, multilingual, travel assistance</li>
                  </ul>
                  <a className="btn-2" href="/products/trips/">Find a plan for you</a>
                </dt>
              </dl>
            </div>
            <div className="holder_block oddP">
              <h3>Student Accident Plan (SAP)</h3>
              <dl>
                <dt>
                  <ul className="list">
                    <li>Offers financial protection for your family against the possible costs arising from your child’s accident</li>
                    <li>For eligible children and students over 6 months and under 26 years of age</li>
                    <li>Over 20 benefits available!</li>
                    <li>Single price coverage for the entire year</li>
                  </ul>
                  <a className="btn-2" href="/products/student-accident-plan/">See SAP Plan</a>
                </dt>
                <dd><img className="size-medium wp-image-95" title="Travelance-Student-Accident-Plan" src={Three} alt="Travelance-Student-Accident-Plan" width="300" height="200" /></dd>
              </dl>
            </div>
            <div className="holder_block evenP">
              <h3>International Student Travel Insurance Policy (ISP)</h3>
              <dl>
                <dd><img className="alignnone size-medium wp-image-96" src={Four} alt="International-Student-Travel-Insurance-Policy" width="300" height="200" /></dd>
                <dt className="space_before">
                  <ul className="list">
                    <li>For students who are coming to study at a Canadian school</li>
                    <li>Coverage available for spouses and dependent child(ren)</li>
                    <li>$2,000,000 emergency medical coverage</li>
                    <li>Some coverage available for pre-existing conditions (depending on plan purchased)</li>
                  </ul>
                  <a className="btn-2" href="/products/international-student/">FIND A PLAN FOR YOU</a>
                </dt>
              </dl>
            </div>
            <em>Visitors to Canada Emergency Medical Insurance Plans, Travel Right Insurance Plans, and the Student Accident Plan are underwritten by Old Republic Insurance Company of Canada. International Student Travel Insurance Policy is underwritten by Zurich Insurance Company Ltd (Canadian Branch).</em>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["products"]['lang']} />
    </>
  );
}

export default Products;
