import React, { useState, useEffect, useRef } from 'react';

const LazyBackgroundImage = ({ src, placeholder, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
      }
    }, { threshold: 0.1 });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) { // eslint-disable-next-line
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div className="box-inner"
      ref={ref}
      style={{
        backgroundColor: '#f0f0f0', // Placeholder color (optional)
        backgroundImage: `url(${isVisible ? src : placeholder})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: 'background-image 0.5s ease-in-out', // Smooth transition
      }}
    >
      {children}
    </div>
  );
};

export default LazyBackgroundImage;
