const Meta ={
  "/": {
    "en": "https://www.travelance.ca/",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/",
    "title": "Affordable Travel, Student, and Visitor Insurance Plans | Compare Coverage and Plans",
    "description": "Find reliable travel insurance, visitor super visa medical plans, student accident policies, and more. Choose flexible coverage options tailored to your needs with Travelance.",
    "canonical": "https://www.travelance.ca"
  },
  "/fr": {
    "en": "https://www.travelance.ca/",
    "fr": "https://www.travelance.ca/fr",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/fr",
    "title": "Assurance Voyage | Travelance",
    "description": "Travelance est un fournisseur d&#039;assurance voyage qui offre à la fois une assurance voyage aux Canadiens et aux visiteurs au Canada. Visitez notre site pour obtenir plus de détails sur nos services.",
    "canonical": "https://www.travelance.ca/fr"
  },
  "/about-us": {
    "en": "https://www.travelance.ca/about-us",
    "fr": "https://www.travelance.ca/a-propos-de-nous",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/about-us/",
	  "title":"Travelance Super Visa Insurance Brokers in Canada",
    "description": "Travelance offers insurance for visitors to Canada (including super visa), travelling Canadians, international students, and a Student Accident Plan. Reliable coverage for your needs. ",
    "canonical": "https://www.travelance.ca/about-us/"
  },
  "/a-propos-de-nous": {
    "en": "https://www.travelance.ca/about-us",
    "fr": "https://www.travelance.ca/a-propos-de-nous",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/a-propos-de-nous/",
    "title": "À propos de nous",
    "description": "Travelance est un distributeur dâ€™assurances dÃ©diÃ© Ã  lâ€™approvisionnement de produits et services supÃ©rieurs Ã  nos clients.",
    "canonical": "https://www.travelance.ca/a-propos-de-nous/"
  },
  "/about-us/mission-and-vision": {
    "en": "https://www.travelance.ca/about-us/mission-and-vision",
    "fr": "https://www.travelance.ca/a-propos-de-nous/mission-et-vision",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/about-us/mission-and-vision/",
    "title": "Travelance Mission & Vision | Trusted Insurance Distributor",
    "description": "Know our mission and vision as a trusted insurance distributor, dedicated to providing comprehensive coverage for visitors to Canada, travelling Canadians, and students.",
    "canonical": "https://www.travelance.ca/about-us/mission-and-vision/"
  },
  "/a-propos-de-nous/mission-et-vision": {
    "en": "https://www.travelance.ca/about-us/mission-and-vision",
    "fr": "https://www.travelance.ca/a-propos-de-nous/mission-et-vision",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/a-propos-de-nous/mission-et-vision/",
    "title": "Mission et vision",
    "description": "Travelance - Mission et vision et Notre vision",
    "canonical": "https://www.travelance.ca/a-propos-de-nous/mission-et-vision/"
  },
  "/contact-us/reviews": {
    "en": "https://www.travelance.ca/contact-us/reviews",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/contact-us/reviews/",
    "title": "Customer Reviews | Travelance",
    "description": "Read what our satisfied customers have to say about their experiences with Travelance. Honest reviews on our services.",
    "canonical": "https://www.travelance.ca/contact-us/reviews/"
  },
  "/products": {
    "en": "https://www.travelance.ca/products",
    "fr": "https://www.travelance.ca/produits",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/products/",
    "title": "Travelance Insurance Plans: Visitors to Canada (super visa), Youth, Travel and Student Coverage",
    "description": "Choose Travelance for reliable travel insurance for visitors to Canada, youth, outbound Canadians, international students, and Canadians students.",
    "canonical": "https://www.travelance.ca/products/"
  },
  "/produits": {
    "en": "https://www.travelance.ca/products",
    "fr": "https://www.travelance.ca/produits",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/produits/",
    "title": "Produits",
    "description": "Travelance RÃ©gimes Visiteurs au Canada Assurance mÃ©dicale dâ€™urgence (VauC), RÃ©gimes dâ€™assurance voyage et RÃ©gime dâ€™assurance accident pour Ã©tudiants.",
    "canonical": "https://www.travelance.ca/produits/"
  },
  "/products/insurance-for-visitors-to-canada-with-super-visa": {
    "en": "https://www.travelance.ca/products/insurance-for-visitors-to-canada-with-super-visa",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/products/insurance-for-visitors-to-canada-with-super-visa/",
    "title": "Affordable Super Visa Insurance Canada | Travelance",
    "description": "Get comprehensive Travelance super visa insurance for parents and grandparents visiting Canada. Get a quote for super visa eligible coverage, today.",
    "canonical": "https://www.travelance.ca/products/insurance-for-visitors-to-canada-with-super-visa/"
  },
  "/products/visitors-canada-insurance": {
    "en": "https://www.travelance.ca/products/visitors-canada-insurance",
    "fr": "https://www.travelance.ca/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/products/visitors-canada-insurance/",
    "title": "Travelance Visitor Insurance & Super Visa Monthly Plans",
    "description": "Secure medical insurance coverage for visitors to Canada. Get reliable visitor medical insurance, including super visa insurance with monthly payment options.",
    "canonical": "https://www.travelance.ca/products/visitors-canada-insurance/"
  },
  "/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence": {
    "en": "https://www.travelance.ca/products/visitors-canada-insurance",
    "fr": "https://www.travelance.ca/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence/",
    "title": "Visiteurs au Canada Assurance médicale d’urgence",
    "description": "Travelance Assurance Accident Ã©tudiant pour enfants et mineurs RÃ©gime Essentiel RÃ©gime Premier.",
    "canonical": "https://www.travelance.ca/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence/"
  },
  "/products/trips": {
    "en": "https://www.travelance.ca/products/trips",
    "fr": "https://www.travelance.ca/produits/l-assurance-voyage",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/products/trips/",
    "title": "Travel Right Insurance Plans for Stress-Free Travel",
    "description": "Protect your journey with Travel Right Insurance Plans. Offering comprehensive coverage, these plans provide medical and non-medical benefits.",
    "canonical": "https://www.travelance.ca/products/trips/"
  },
  "/produits/l-assurance-voyage": {
    "en": "https://www.travelance.ca/products/trips",
    "fr": "https://www.travelance.ca/produits/l-assurance-voyage",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/produits/l-assurance-voyage/",
    "title": "L’assurance voyage",
    "description": "Travelance Travel",
    "canonical": "https://www.travelance.ca/produits/l-assurance-voyage/"
  },
  "/products/trips/travel": {
    "en": "https://www.travelance.ca/products/trips/travel",
    "fr": "https://www.travelance.ca/produits/l-assurance-voyage/voyage",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/products/trips/travel/",
    "title": "Comprehensive Travel Insurance Plans for Every Traveller",
    "description": "Protect your travels with tailored travel insurance plans. Coverage for emergencies, cancellations, and more to ensure peace of mind on every journey.",
    "canonical": "https://www.travelance.ca/products/trips/travel/"
  },
  "/produits/l-assurance-voyage/voyage": {
    "en": "https://www.travelance.ca/products/trips/travel",
    "fr": "https://www.travelance.ca/produits/l-assurance-voyage/voyage",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/produits/l-assurance-voyage/voyage/",
    "title": "Voyage",
    "description": "Travelance Plan de voyage unique, Plan annuel multi-voyages, Le rÃ©gime mondial Tout compris, Les rÃ©gimes annuels Frais mÃ©dicaux dâ€™urgence, Le rÃ©gime Accident vol aÃ©rien.",
    "canonical": "https://www.travelance.ca/produits/l-assurance-voyage/voyage/"
  },
  "/products/trips/youth": {
    "en": "https://www.travelance.ca/products/trips/youth",
    "fr": "https://www.travelance.ca/produits/l-assurance-voyage/jeunes",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/products/trips/youth/",
    "title": "Youth Travel Insurance - Travel Protected",
    "description": "Protect your journey with reliable travel insurance. Comprehensive coverage for international trips, ensuring safety and peace of mind.",
    "canonical": "https://www.travelance.ca/products/trips/youth/"
  },
  "/produits/l-assurance-voyage/jeunes": {
    "en": "https://www.travelance.ca/products/trips/youth",
    "fr": "https://www.travelance.ca/produits/l-assurance-voyage/jeunes",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/produits/l-assurance-voyage/jeunes/",
    "title": "Jeunesse",
    "description": "Travelance Lâ€™assurance voyage Jeunesse de Travelance a Ã©tÃ© conÃ§ue exclusivement pour le jeune voyageur nÃ©cessitant une assurance voyage Ã  prix modique.",
    "canonical": "https://www.travelance.ca/produits/l-assurance-voyage/jeunes/"
  },
  "/products/student-accident-plan": {
    "en": "https://www.travelance.ca/products/student-accident-plan",
    "fr": "https://www.travelance.ca/produits/regime-dassurance-accidents-pour-etudiants",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/products/student-accident-plan/",
    "title": "Student Accident Plan - Affordable Accident Coverage for Students",
    "description": "Accidents Happen. Comprehensive, affordable coverage for accidents, providing peace of mind for families and schools.",
    "canonical": "https://www.travelance.ca/products/student-accident-plan/"
  },
  "/produits/regime-dassurance-accidents-pour-etudiants": {
    "en": "https://www.travelance.ca/products/student-accident-plan",
    "fr": "https://www.travelance.ca/produits/regime-dassurance-accidents-pour-etudiants",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/produits/regime-dassurance-accidents-pour-etudiants/",
    "title": "Assurance Accident étudiant",
    "description": "Travelance Assurance Accident Ã©tudiant pour enfants et mineurs.",
    "canonical": "https://www.travelance.ca/produits/regime-dassurance-accidents-pour-etudiants/"
  },
  "/products/international-student": {
    "en": "https://www.travelance.ca/products/international-student",
    "fr": "https://www.travelance.ca/produits/police-dassurance-voyage-pour-les-etudiants-etrangers",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/products/international-student/",
    "title": "Reliable International Student Travel Insurance Coverage",
    "description": "Study in Canada with peace of mind. Emergency medical coverage for students studying in Canada.",
    "canonical": "https://www.travelance.ca/products/international-student/"
  },
  "/produits/police-dassurance-voyage-pour-les-etudiants-etrangers": {
    "en": "https://www.travelance.ca/products/international-student",
    "fr": "https://www.travelance.ca/produits/police-dassurance-voyage-pour-les-etudiants-etrangers",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/produits/police-dassurance-voyage-pour-les-etudiants-etrangers/",
    "title": "Police d’assurance voyage pour les étudiants étrangers",
    "description": "Les étudiants ne sont peut-être pas couverts par le système de santé universel du Canada, ce qui signifie que les étudiants et leur famille devraient payer de leur poche pour toute urgence médicale imprévue.",
    "canonical": "https://www.travelance.ca/produits/police-dassurance-voyage-pour-les-etudiants-etrangers/"
  },
  "/products/faqs": {
    "en": "https://www.travelance.ca/products/faqs",
    "fr": "https://www.travelance.ca/produits/produits-faq",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/products/faqs/",
    "title": "Travelance FAQs - Your Questions Answered",
    "description": "Browse the Travelance FAQ page for detailed answers to your travel insurance questions. Get the information you need to plan your next trip with confidence.",
    "canonical": "https://www.travelance.ca/products/faqs/"
  },
  "/produits/produits-faq": {
    "en": "https://www.travelance.ca/products/faqs",
    "fr": "https://www.travelance.ca/produits/produits-faq",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/produits/produits-faq/",
    "title": "Produits FAQ",
    "description": "Travelance",
    "canonical": "https://www.travelance.ca/produits/produits-faq/"
  },
  "/claims": {
    "en": "https://www.travelance.ca/claims",
    "fr": "https://www.travelance.ca/reclamations",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/claims/",
    "title": "Travel Insurance Claims | Easy & Fast Process Online",
    "description": "File your travel insurance claims quickly with Travelance. Access a smooth, hassle-free claims process designed for your convenience. Visit us to start now!",
    "canonical": "https://www.travelance.ca/claims/"
  },
  "/reclamations": {
    "en": "https://www.travelance.ca/claims",
    "fr": "https://www.travelance.ca/reclamations",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/reclamations/",
    "title": "Réclamations",
    "description": "Travelance",
    "canonical": "https://www.travelance.ca/reclamations/"
  },
  "/claims/forms": {
    "en": "https://www.travelance.ca/claims/forms",
    "fr": "https://www.travelance.ca/reclamations/formulaires",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/claims/forms/",
    "title": "Travelance Claims Forms | Easy Online Submission",
    "description": "Access Travelance’s claims forms online with ease. Submit your insurance claims quickly and efficiently through our straightforward and secure platform.",
    "canonical": "https://www.travelance.ca/claims/forms/"
  },
  "/reclamations/formulaires": {
    "en": "https://www.travelance.ca/claims/forms",
    "fr": "https://www.travelance.ca/reclamations/formulaires",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/reclamations/formulaires/",
    "title": "Formulaires",
    "description": "Travelance",
    "canonical": "https://www.travelance.ca/reclamations/formulaires/"
  },
  "/about-our-underwriter": {
    "en": "https://www.travelance.ca/about-our-underwriter",
    "fr": "https://www.travelance.ca/au-sujet-de-notre-assureur",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/about-our-underwriter/",
    "title": "About Our Underwriters",
    "description": "Learn about our underwriters Old Republic Insurance Company of Canada and Zurich Insurance Company Ltd (Canadian Branch).",
    "canonical": "https://www.travelance.ca/about-our-underwriter/"
  },
  "/au-sujet-de-notre-assureur": {
    "en": "https://www.travelance.ca/about-our-underwriter",
    "fr": "https://www.travelance.ca/au-sujet-de-notre-assureur",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/au-sujet-de-notre-assureur/",
    "title": "Au sujet de notre assureur",
    "description": "Travelance",
    "canonical": "https://www.travelance.ca/au-sujet-de-notre-assureur/"
  },
  "/products/visitors-canada-insurance/monthly": {
    "en": "https://www.travelance.ca/products/visitors-canada-insurance/monthly",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/products/visitors-canada-insurance/monthly/",
    "title": "Affordable Super Visa Insurance Monthly Payment Plan | Travelance",
    "description": "Purchase monthly super visa insurance payment plans for visitors to Canada. Flexible, affordable, and convenient coverage options with affordable monthly payments.",
    "canonical": "https://www.travelance.ca/products/visitors-canada-insurance/monthly/"
  },
  "/products/isp": {
    "en": "https://www.travelance.ca/products/isp",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/products/isp/",
    "title": "Travel Insurance for International Students in Canada",
    "description": "Secure emergency medical insurance for international students in Canada. Student travel insurance Canada ensures comprehensive coverage for your health.",
    "canonical": "https://www.travelance.ca/products/isp/"
  },
  "/partner": {
    "en": "https://www.travelance.ca/partner",
    "fr": "https://www.travelance.ca/partenaires",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/partner/",
    "title": "Partner with Travelance | Insurance Solutions for Brokers",
    "description": "Partner with Travelance for comprehensive insurance solutions. Boost your offerings with travel, health, and visitors' insurance tailored for brokers.",
    "canonical": "https://www.travelance.ca/partner/"
  },
  "/partenaires": {
    "en": "https://www.travelance.ca/partner",
    "fr": "https://www.travelance.ca/partenaires",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/partenaires/",
    "title": "Partenaires",
    "description": "Travelance",
    "canonical": "https://www.travelance.ca/partenaires/"
  },
  "/partner/form": {
    "en": "https://www.travelance.ca/partner/form",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/partner/form/",
    "title": "Partner with Travelance | Partner Form",
    "description": "Travelance",
    "canonical": "https://www.travelance.ca/partner/form/"
  },
  "/partner/form/thank-you": {
    "en": "https://www.travelance.ca/partner/form/thank-you",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "Links": "/partner/form/thank-you/",
    "link": "https://www.travelance.ca/partner/form/thank-you/",
    "title": "Thank You | Partnership Request",
    "description": "Thank you for submitting your request to partner with Travelance.",
    "canonical": "https://www.travelance.ca/partner/form/thank-you/"
  },
  "/partner/agency-form": {
    "en": "https://www.travelance.ca/partner/agency-form",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/partner/agency-form/",
    "title": "Partner with Travelance for Agency | Partnership Form",
    "description": "Join Travelance as a partner agency to access exclusive resources, support, and grow your business. Complete the form to start the partnership process!",
    "canonical": "https://www.travelance.ca/partner/agency-form/"
  },
  "/partner/wfg-information": {
    "en": "https://www.travelance.ca/partner/wfg-information",
    "fr": "https://www.travelance.ca/partner/wfg-information",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/partner/wfg-information/",
    "title": "World Financial Group | Travelance",
    "description": "Access essential tools and resources for WFG brokers to start selling Travelance products effectively. Partner with us for seamless solutions.",
    "canonical": "https://www.travelance.ca/partner/wfg-information/"
  },
  "/partner/greatwayfinancial": {
    "en": "https://www.travelance.ca/partner/greatwayfinancial",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/partner/greatwayfinancial/",
    "title": "Greatway Financial Partnership | Travelance",
    "description": "Access essential tools and resources for Greatway Financial brokers to start selling Travelance products effectively. Partner with us for seamless solutions.",
    "canonical": "https://www.travelance.ca/partner/greatwayfinancial/"
  },
  "/partner/experiorfinancialgroup": {
    "en": "https://www.travelance.ca/partner/experiorfinancialgroup",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/partner/experiorfinancialgroup/",
    "title": "Experior Financial Group Partnership | Travelance",
    "description": "Access essential resources for Experior Financial Group brokers. Learn, grow, and succeed with our tools.",
    "canonical": "https://www.travelance.ca/partner/experiorfinancialgroup/"
  },
  "/partner/wfg-information/thank-you": {
    "en": "https://www.travelance.ca/partner/wfg-information/thank-you",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/partner/wfg-information/thank-you/",
    "title": "Thank You | WFG Partner Request",
    "description": "Thank you for submitting your request to partner with Travelance.",
    "canonical": "https://www.travelance.ca/partner/wfg-information/thank-you/"
  },
  "/partner/reasons-to-partner-with-travelance": {
    "en": "https://www.travelance.ca/partner/reasons-to-partner-with-travelance",
    "fr": "https://www.travelance.ca/partenaires/sassocier-avec-travelance",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/partner/reasons-to-partner-with-travelance/",
    "title": "9 Reasons to Partner with Travelance for Exceptional Insurance Services",
    "description": "Partner with Travelance for outstanding insurance solutions. We offer top-tier products and dedicated service.",
    "canonical": "https://www.travelance.ca/partner/reasons-to-partner-with-travelance/"
  },
  "/partenaires/sassocier-avec-travelance": {
    "en": "https://www.travelance.ca/partner/reasons-to-partner-with-travelance",
    "fr": "https://www.travelance.ca/partenaires/sassocier-avec-travelance",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/partenaires/sassocier-avec-travelance/",
    "title": "9 raisons de s'associer à Travelance",
    "description": "Travelance",
    "canonical": "https://www.travelance.ca/partenaires/sassocier-avec-travelance/"
  },
  "/partner/resources": {
    "en": "https://www.travelance.ca/partner/resources",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/partner/resources/",
    "title": "Partner Resources for Travel Insurance Support",
    "description": "Access valuable resources for all Travelance plans. Partner with Travelance to offer top-notch insurance solutions for every need.",
    "canonical": "https://www.travelance.ca/partner/resources/"
  },
  "/partner/faq-agents": {
    "en": "https://www.travelance.ca/partner/faq-agents",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/partner/faq-agents/",
    "title": "Travelance Partner FAQs for Brokers - All You Need to Know",
    "description": "Check out the Travelance FAQ section for brokers. Understand features, benefits, and resources designed to support your success as a partner.",
    "canonical": "https://www.travelance.ca/partner/faq-agents/"
  },
  "/complaint-resolution-process": {
    "en": "https://www.travelance.ca/complaint-resolution-process",
    "fr": "https://www.travelance.ca/processus-de-resolution-des-plaintes",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/complaint-resolution-process/",
    "title": "Complaint Resolution Process | Travelance Canada",
    "description": "Learn about Travelance's transparent complaint resolution process. Our step-by-step guide ensures your concerns are handled quickly and efficiently.",
    "canonical": "https://www.travelance.ca/complaint-resolution-process/"
  },
  "/processus-de-resolution-des-plaintes": {
    "en": "https://www.travelance.ca/complaint-resolution-process",
    "fr": "https://www.travelance.ca/processus-de-resolution-des-plaintes",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/processus-de-resolution-des-plaintes/",
    "title": "Processus de résolution des plaintes",
    "description": "Travelance",
    "canonical": "https://www.travelance.ca/processus-de-resolution-des-plaintes/"
  },
  "/partner/online-training-sessions": {
    "en": "https://www.travelance.ca/partner/online-training-sessions",
    "fr": "https://www.travelance.ca/partenaires/sessions-de-formation-en-ligne",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/partner/online-training-sessions/",
    "title": "Enhance Skills with Online Training Sessions | Travelance",
    "description": "Register for our Online Training Sessions to learn about our products and services.Each week, our team members lead interactive, lively, and information-packed sessions.",
    "canonical": "https://www.travelance.ca/partner/online-training-sessions/"
  },
  "/partenaires/sessions-de-formation-en-ligne": {
    "en": "https://www.travelance.ca/partner/online-training-sessions",
    "fr": "https://www.travelance.ca/partenaires/sessions-de-formation-en-ligne",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/partenaires/sessions-de-formation-en-ligne/",
    "title": "Sessions de formation en ligne",
    "description": "Travelance",
    "canonical": "https://www.travelance.ca/partenaires/sessions-de-formation-en-ligne/"
  },
  "/partner/client-referral-program": {
    "en": "https://www.travelance.ca/partner/client-referral-program",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/partner/client-referral-program/",
    "title": "Client Referral Program for Travelance Partners",
    "description": "Earn rewards by referring clients to Travelance's trusted services. Join our referral program and enhance your partnership with exclusive benefits.",
    "canonical": "https://www.travelance.ca/partner/client-referral-program/"
  },
  "/sitemap": {
    "en": "https://www.travelance.ca/sitemap",
    "fr": "https://www.travelance.ca/plan-du-site",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/sitemap/",
    "title": "Sitemap",
    "description": "Travelance",
    "canonical": "https://www.travelance.ca/sitemap/"
  },
  "/plan-du-site": {
    "en": "https://www.travelance.ca/sitemap",
    "fr": "https://www.travelance.ca/plan-du-site",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/plan-du-site/",
    "title": "Plan du site",
    "description": "Travelance Plan du site.",
    "canonical": "https://www.travelance.ca/plan-du-site/"
  },
  "/terms-conditions": {
    "en": "https://www.travelance.ca/terms-conditions",
    "fr": "https://www.travelance.ca/modalites-et-conditions",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/terms-conditions/",
    "title": "Travelance Terms & Conditions - Read Our Policies",
    "description": "Review Travelance's terms and conditions for detailed information on our policies, user obligations, and important agreements for using our services.",
    "canonical": "https://www.travelance.ca/terms-conditions/"
  },
  "/modalites-et-conditions": {
    "en": "https://www.travelance.ca/terms-conditions",
    "fr": "https://www.travelance.ca/modalites-et-conditions",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/modalites-et-conditions/",
    "title": "Modalités et conditions",
    "description": "Travelance ModalitÃ©s et conditions.",
    "canonical": "https://www.travelance.ca/modalites-et-conditions/"
  },
  "/privacy-policy": {
    "en": "https://www.travelance.ca/privacy-policy",
    "fr": "https://www.travelance.ca/politique-de-confidentialite",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/privacy-policy/",
    "title": "Travelance Privacy Policy - Commitment to Your Security",
    "description": "Learn about Travelance's privacy practices and how we protect your data. We prioritize security for our clients, including policyholders, and brokers.",
    "canonical": "https://www.travelance.ca/privacy-policy/"
  },
  "/politique-de-confidentialite": {
    "en": "https://www.travelance.ca/privacy-policy",
    "fr": "https://www.travelance.ca/politique-de-confidentialite",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/politique-de-confidentialite/",
    "title": "Politique de confidentialité",
    "description": "Politique de confidentialitÃ© en ligne de Travelance V1.1.",
    "canonical": "https://www.travelance.ca/politique-de-confidentialite/"
  },
  "/contact-us": {
    "en": "https://www.travelance.ca/contact-us",
    "fr": "https://www.travelance.ca/contactez-nous",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/contact-us/",
    "title": "Contact Us | Travel & Emergency Medical Insurance",
    "description": "Reach out to Travelance for travel insurance for visitors to Canada, travelling Canadians, international students, and student accident coverage.",
    "canonical": "https://www.travelance.ca/contact-us/"
  },
  "/contactez-nous": {
    "en": "https://www.travelance.ca/contact-us",
    "fr": "https://www.travelance.ca/contactez-nous",
    "lang": "fr_CA",
    "langalt": "en_US",
    "link": "https://www.travelance.ca/contactez-nous/",
    "title": "Contactez-nous",
    "description": "Travelance Contactez-nous TÃ©lÃ©phone Courriel Email Heures de travail.",
    "canonical": "https://www.travelance.ca/contactez-nous/"
  },
  "/blog": {
    "en": "https://www.travelance.ca/blogs",
    "fr": "https://www.travelance.ca/blogs",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/blog/",
    "title": "Travelance Blog | Travel Insurance & Tips for Travellers",
    "description": "Stay informed with expert blogs on emergency medical plans, travel insurance, student accident coverage, and international student protection.",
    "canonical": "https://www.travelance.ca/blog/"
  },
  "/partner/referral-form": {
    "en": "https://www.travelance.ca/partner/referral-form",
    "fr": "https://www.travelance.ca/fr",
    "lang": "en_US",
    "langalt": "fr_CA",
    "link": "https://www.travelance.ca/partner/referral-form",
    "title": "Partner Reffral | Travelance",
    "description": "Partner with Travelance and choose from the medical and non-medical travel insurance products offered by Travelance for visitors, travellers and students.",
    "canonical": "https://www.travelance.ca/partner/referral-form"
  },
}

export default Meta;
