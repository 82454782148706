import React from "react";
import './GetQuoteSection.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img1 from "../../../assets/images/quotation-svg.webp";
import img2 from "../../../assets/images/support-svg.webp";
import img3 from "../../../assets/images/task-svg.webp";


const GetQuoteSection = (props) => {
  const cards = [
    {
      'image':img3,
      'title':'Get A Quote',
      'text': 'Our competitive products are backed by established underwriters.',
      'button':'CLICK HERE',
      'href':'https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&returnurl=step1.aspx',
      'rel':'',
      'target':'_blank',
      'width':91,
      'height':106
    },
    {
      'image':img1,
      'title':'Find the Right Plan',
      'text': 'We offer a variety of plans to meet most travellers’ needs.',
      'button':'CLICK HERE',
      'href':'/products/',
      'rel':'',
      'target':'',
      'width':88,
      'height':106
    },
    {
      'image':img2,
      'title':'Support',
      'text': 'We are here to help you! Ready assistance is only a step away.',
      'button':'CLICK HERE',
      'href':'/contact-us/',
      'rel':'',
      'target':'',
      'width':95,
      'height':106
    }
  ];
  const cardsFr = [
    {
      'image':img3,
      'title':'Obtenir un devis',
      'text': 'Nos produits compétitifs sont appuyés par un assureur établis et mettent à votre disposition un service mondial et multilingue d\'assistance en voyage 24h/24 7j/7.',
      'button':'Cliquant Ici',
      'href':'https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&returnurl=step1.aspx',
      'rel':'',
      'target':'_blank',
      'width':91,
      'height':106
    },
    {
      'image':img1,
      'title':'Trouver le régime qui vous va',
      'text': 'Nos divers régimes répondent aux besoins de la majorité des voyageurs.',
      'button':'Cliquant Ici',
      'href':'/produits/',
      'rel':'',
      'target':'',
      'width':88,
      'height':106
    },
    {
      'image':img2,
      'title':'Assistance',
      'text': 'Nous sommes là pour vous aider !Pour parler en direct avec un représentant, composez le 1-855-566-8555, ou envoyez un courriel à info@travelance.ca.',
      'button':'Cliquant Ici',
      'href':'/contactez-nous/',
      'rel':'',
      'target':'',
      'width':95,
      'height':106
    }
  ];

  return (
    <div className="getQuoteSection">
      <Container>
        <Row className="align-items-center">
        {
          (props.lang === 'fr')?
          cardsFr.map((item,i)=>{
            return(
              <Col className="getQuoteCard align-items-center" key={i}>
                <div className="cardimage"><img loading="lazy" src={item.image} alt={`${i}-img`} /></div>
                <div className="cardDetails">
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                  <a href={item.href} target={item.target} rel={item.rel} className="getQuoteCardButton">{item.button}</a>
                </div>
              </Col>
            )
          })
          :
          cards.map((item,i)=>{
            return(
              <Col className="getQuoteCard align-items-center" key={i}>
                <div className="cardimage"><img loading="lazy" src={item.image} alt={`${i}-img`} width={item.width} height={item.height} /></div>
                <div className="cardDetails">
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                  <a href={item.href} target={item.target} rel={item.rel} className="getQuoteCardButton">{item.button}</a>
                </div>
              </Col>
            )
          })
        }
        </Row>
      </Container>
    </div>
  );
}

export default GetQuoteSection;
