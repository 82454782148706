import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const ProductsTrips = (props) => {
  return (
    <>
    <Header en={GLobalLinks["products/trips"]['en']} fr={GLobalLinks["products/trips"]['fr']} lang={GLobalLinks["products/trips"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="trips">Travel Right Insurance Plans (TRIPS)</h1>
          <div>
            <div className="header_trips bannerInner"></div>
            <h3>Travel Right Insurance Plans (TRIPs) are an excellent choice for eligible travellers who plan to travel outside their resident province/territory or outside of Canada and have a valid Government Health Insurance Plan (GHIP) or Canadian University Health Insurance Plan (UHIP).</h3>
            <div className="row">
              <div className="col-lg-4">
                <div className="holder_block center">
                  <h2><i className="fa-6x travel"></i>Travel</h2>
                  <p>Heading to the USA for the weekend, to Mexico for a week, or overseas for an extended vacation? Choose from our single trip and multi-trip options. Features include emergency medical, trip cancellation/interruption, package plans, family rates and more!</p>
                  <a className="btn-2" href="/products/trips/travel/">Read More</a>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="holder_block center">
                  <h2><i className="fa-6x youth"></i>Youth</h2>
                  <p>Are you under the age of 30 and looking for a travel insurance plan bundling emergency medical and non-medical coverages? You’ve come to the right place! Travel with financial protection for as little as $38 for a 3 day trip.</p>
                  <a className="btn-2" href="/products/trips/youth/">Read More</a>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="holder_block center">
                  <h2><i className="fa-6x all-inclusive-canada"></i>All-Inclusive Canada</h2>
                  <p>Travelling within Canada but outside of your home province or territory?<br />
                  This plan has all of the benefits of our All-Inclusive plan with special rates for those staying in the country for their travels. Includes trip cancellation and interruption benefits.</p>
                  <a className="btn-2" href="/products/trips/travel/">Read More</a>
                </div>
              </div>
            </div>
            <p>This is a brief description of coverage. Please see policy wording for benefits, definitions, exclusions, limitations, terms and conditions.</p>
            <em>Underwritten by Old Republic Insurance Company of Canada.</em>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["products/trips"]['lang']} />
    </>
  );
}

export default ProductsTrips;
