import React, { useState, useEffect } from 'react';
import { BiSolidArrowToTop } from "react-icons/bi";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <button
          onClick={scrollToTop}
           style={{position: 'fixed',bottom: '50px',right: '50px', cursor: 'pointer',zIndex: '1000',fontSize: '36px',padding: '0px 5px',borderRadius: '100px',height: '60px',width: '60px',background: 'transparent',border: '3px solid #cc4037',color: '#cc4037',textAlign: 'center',verticalAlign: 'middle'}}
        >
        <BiSolidArrowToTop />

        </button>
      )}
    </div>
  );
};

export default BackToTop;
