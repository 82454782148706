import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const ProductsSVIC = (props) => {

  return (
    <>
    <Header en={GLobalLinks["products/insurance-for-visitors-to-canada-with-super-visa"]['en']} fr={GLobalLinks["products/insurance-for-visitors-to-canada-with-super-visa"]['fr']} lang={GLobalLinks["products/insurance-for-visitors-to-canada-with-super-visa"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="productsSVIC">Super Visa Insurance Canada - Medical Insurance for Super Visa</h1>
          <div>
            <div className="header_super-visa bannerInner"></div>
              <div className="row">
                <div className="col-lg-8">
                  <h2>The Super Visa: A Convenient Option for Parents and Grandparents</h2>
                  <p><strong>What is a super visa?</strong></p>
                  <p>Unlike a standard visitor visa, which allows travelers to stay in Canada for only 6 months at a time, the Canadian super visa is a multi-entry visitor visa. It allows parents and grandparents of Canadian citizens and permanent residents to visit their loved ones in Canada more frequently and for longer periods of time. The super visa is valid for up to 10 years and permits visa holders to stay in Canada for up to 5 years without having to renew their status.</p>
                  <p>The super visa is a flexible option that allows parents and grandparents to visit their children and grandchildren in Canada without lengthy processing times. The average super visa application only takes a few months to process.</p>
                  <p>Monthly Payment Option available for convenient installments.</p>
                  <h2>What are the Super Visa Requirements?</h2>
                  <p>To be eligible for the super visa, as per the Canadian government, applicants must satisfy several requirements including the following:
                    <ul>
                      <li>Proof that the applicant is a parent or grandparent of a Canadian citizen or permanent resident</li>
                      <li>Undergo a medical examination carried out by a Canadian approved doctor</li>
                      <li>Proof of valid medical insurance from a Canadian insurance provider with at least $100,000 coverage for a minimum of one year, covering healthcare, hospitalization, and repatriation</li>
                      <li>The policy must be paid for in full or installments with a deposit (quotes are not accepted)</li>
                      <li>The child or grandchild must meet minimum income thresholds and provide a written statement pledging financial support for the duration of the applicant’s stay</li>
                    </ul>
                  </p>
                  <h2>Travelance's Visitors to Canada Plans Meet Super Visa Insurance Requirements!</h2>
                  <p>Both of our Visitors to Canada Emergency Medical Insurance plans meet the Canadian super visa medical insurance requirements when purchase for a minimum sum insured of $100,000 for at least one year.</p>
                  <p>We have two plans which can provide medical insurance coverage for the super visa – the Essential Plan and Premier Plan.</p>
                  <p>The <strong>Essential Plan*</strong> is a cost-effective option for those who are looking for basic emergency medical coverage and do not require extended benefits such as paramedical services or coverage for pre-existing conditions.</p>
                  <p>The <strong>Premier Plan*</strong> is a cost-effective option for those who are looking for additional and upgraded benefits when compared to the Essential Plan. Benefits are available for some pre-existing conditions.</p>
                  <p><i>*Please see policy wording for benefits, definitions, exclusions, limitations, terms and conditions.</i></p>
                  <p>For parents and grandparents coming to Canada on a super visa, we recommend reviewing our products to learn more about Canadian super visa insurance. <a href="/products/visitors-canada-insurance/">Visit our products page to compare our Visitors to Canada Emergency Medical ‘Super Visa’ Insurance plans</a>.</p>
                  <p>For more information on insurance for visitors to Canada or to speak to a licensed Travelance broker, contact us through our website or call <a href="tel:18555668555">1-855-566-8555</a>.</p>
                  <p><em>Disclaimer: Travelance is not an immigration authority. Please contact an immigration professional or IRCC for your questions related to the visa process.</em></p>
              </div>
              <div className="col-lg-4">
                <div className="stickyDiv">
                  <div className="holder_block">
                    <h2>All Products</h2>
                    <ul className="list">
                      <li><a href="/products/trips/travel/">Travel</a></li>
                      <li><a href="/products/trips/youth/">Youth</a></li>
                      <li><a href="/products/visitors-canada-insurance/">Visitors to Canada Emergency Medical Insurance</a></li>
                      <li><a href="/products/student-accident-plan/">Student Accident Plan</a></li>
                      <li><a href="/products/international-student/">International Student Plan</a></li>
                    </ul>
                  </div>
                  <div className="holder_block">
                    <h2>Quick Links</h2>
                    <ul className="list">
                      <li><a href="https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&amp;returnurl=step1.aspx">Get a Quote</a></li>
                      <li><a href="/#faqSection">FAQs</a></li>
                      <li><a href="/about-our-underwriter/">Our Underwriter</a></li>
                      <li><a href="/claims/">Claims</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["products/insurance-for-visitors-to-canada-with-super-visa"]['lang']} />
    </>
  );
}

export default ProductsSVIC;
