import React from "react";
import { Link } from "react-router-dom";
import DropdownButton from "../../DropdownButton/DropdownButton"
import "bootstrap/js/dist/dropdown.js";
import './Middleheader.css';
import logo from "../../../../assets/images/logo.svg";

const Middleheader = (props) => {
  const items = [
    {
      'text':'GetReliable (VTC, TRIPs, and SAP Sales)',
      'textfr':'GetReliable (VauC, RATR et AAE)',
      'link':'https://www.getreliable.com/getreliable/',
      'target':'_blank',
      'rel': 'noopener noreferrer'
    },
    {
      'text':'MyTL (ISP Sales and Compliance)',
      'textfr':'MyTL (ISP Ventes et conformité)',
      'link':'https://mytl.travelance.ca/#',
      'target':'_blank',
      'rel': 'noopener noreferrer'
    }
  ];
  const items1 = [
    {
      'text':'Visitors to Canada Emergency Medical Insurance (VTC)',
      'textfr':'Visiteurws au Canada Assurance médicale d’urgence (VauC)',
      'link':'https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&returnurl=step1.aspx',
      'target':'_blank',
      'rel': 'noopener noreferrer'
    },
    {
      'text':'International Student Travel Insurance Policy (ISP)',
      'textfr':"Police d'assurance voyage pour étudiants internationaux (ISP)",
      'link':'https://quote.travelance.ca/#/ISP?language=en',
      'target':'',
      'rel': ''
    },
    {
      'text':'Travel Right Insurance Plan (TRIPs)',
      'textfr':'Régimes d’assurance Travel Right (RATR)',
      'link':'https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&returnurl=step1.aspx',
      'target':'_blank',
      'rel': 'noopener noreferrer'
    }
  ];

  var link = '/';
  var btnTxt = "VTC RATE CALCULATOR";
  var btnUrl = "https://quote.travelance.ca/#/VTC?language=en";
  if(props.lang === 'fr'){
    link = '/fr';
    btnTxt = "Calculatrice du taux VauC";
    btnUrl = "https://quote.travelance.ca/#/VTC?language=fr";
  }else{
    link = '/';
    btnTxt = "VTC RATE CALCULATOR";
    btnUrl = "https://quote.travelance.ca/#/VTC?language=en";
  }

  return (
    <section id="middlebar">
      <div className="container clearfix">
        <div className="row">
          <div className="col-md-3">
            <Link className="float-left logo" to={link}>
              <img loading="lazy" src={logo} alt="Travel" width="228" height="47"/>
            </Link>
          </div>
          <div className="col-md-9">
            <div className="float-end">
              <Link className="" to={btnUrl} target="_blank">
                <button type="button" className="vtcBtn btn btn-primary">{btnTxt}</button>
              </Link>
              <div className="dropdownParent">
                <DropdownButton title="PARTNER LOGIN" titlefr="Ouvrir une session" links={items} lang={props.lang} class="blue middlebarDrop" did="partner-login" />
                <DropdownButton title="GET QUOTE" titlefr="Obtenir un devis" links={items1} lang={props.lang} class="red middlebarDrop" did="get-quote" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Middleheader;
