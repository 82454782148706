import React, { useEffect, useState } from 'react';
import { useSearchParams  } from 'react-router-dom';
import Header from "../../commons/Header/Header";
import Footer from "../../commons/Footer/Footer";
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';
import { Link } from "react-router-dom";
import Pagination from 'react-bootstrap/Pagination';

const Search = (props) => {

  const [searchParams] = useSearchParams();
  const s = searchParams.get('s');
  let page = searchParams.get('page');

  if(page === null){
    page = 1;
  }
  console.log(page);
  let pageId = parseInt(page);
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(pageId);
  const [totalPages, setTotalPages] = useState(1);

  const isThereNextPage     = currentPage < totalPages;
  const isTherePreviousPage = currentPage > 1;
  const paginationArray     = createPaginationArray( currentPage, totalPages );

  const getPageLink = ( pageNo ) => {
    return `/search?s=${s}&page=${pageNo}`;
  };

  useEffect(() => {

    let url = `${process.env.REACT_APP_API_ROOT}search?s=${s}&page=${page}`;
    trackPromise(
      axios.get(url).then((res) => {
        const { data } = res;
        setBlogs(data.posts_data);
        setTotalPages(data.page_count);
    }));
  }, [s,page]);
  console.log(blogs);

  return (
    <>
      <Header en="/Search" fr="/fr" lang="en" />
      <div className="container mainDiv">
        <div className="row">
          <div className="col-xs-12 content">
            <h1>Search Results for:{s}</h1>
          </div>
        </div>
        <div className="row g-5">
            {
              blogs.map((blog,i)=>{
                return(
                  <div className="col-md-12 srch-single-content" key={blog.id}>
                    <Link to={blog.link}>
                      <h4 dangerouslySetInnerHTML={{__html:blog.title}}></h4>
                    </Link>
                    <p dangerouslySetInnerHTML={{__html:blog.excerpt}}></p>
                  </div>
                )
              })
            }
        </div>
        <div className="row g-5 footer-pagination">
          <Pagination>
            { isTherePreviousPage && <Pagination.Item className="prev" ><Link to={ getPageLink( currentPage - 1 ) } onClick={ () => setCurrentPage( currentPage - 1 ) } className="prev page_no" >Previous</Link></Pagination.Item> }
            { paginationArray && paginationArray.map( ( item, index ) => {
              if ( '...' !== item && currentPage !== item  ) {
                return (
                  <Pagination.Item key={ `${ item }-${ index }` } >
                    <Link to={ getPageLink( item ) } onClick={ () => setCurrentPage( item ) } className="page_no">{ item }</Link>
                  </Pagination.Item>
                );
              } else {
                return (
                  <Pagination.Item key={ `${ item }-${ index }` } className={ currentPage === item ? 'page-no active' : '' }>
                    <Link className="page_no">{ item }</Link>
                  </Pagination.Item>
                );
              }
            } ) }
            { isThereNextPage && <Pagination.Item className="next"><Link to={ getPageLink( currentPage + 1 ) } onClick={ () => setCurrentPage( currentPage + 1 ) } className="next page_no" >Next</Link></Pagination.Item> }
          </Pagination>

        </div>
      </div>

      <Footer lang="en" />
    </>
  );
}

export default Search;

export const createPaginationArray = ( currentPage, totalPages ) => {

	let loopableArray   = [];
	let countOfDotItems = 0;

	// If there is only one page, return an empty array.
	if ( 1 === totalPages ) {
		return loopableArray;
	}

	/**
	 * Push the two index items before the current page.
	 */
	if ( 0 < currentPage - 2 ) {
		loopableArray.push( currentPage - 2 );
	}

	if ( 0 < currentPage - 1 ) {
		loopableArray.push( currentPage - 1 );
	}

	// Push the current page index item.
	loopableArray.push( currentPage );

	/**
	 * Push the two index items after the current page.
	 */
	if ( totalPages >= currentPage + 1 ) {
		loopableArray.push( currentPage + 1 );
	}

	if ( totalPages >= currentPage + 2 ) {
		loopableArray.push( currentPage + 2 );
	}

	/**
	 * Push the '...' at the beginning of the array
	 * only if the difference of between the 1st and 2nd index item is greater than 1.
	 */
	if ( 1 < loopableArray[ 0 ] - 1 ) {
		loopableArray.unshift( '...' );
		countOfDotItems += 1;
	}

	/**
	 * Push the '...' at the end of the array.
	 * only if the difference of between the last and 2nd last item is greater than 1.
	 * We remove the count of dot items from the array to get the actual indexes, while checking the condition.
	 */
	if (
		1 <
		totalPages - loopableArray[ loopableArray.length - ( 2 - countOfDotItems ) ]
	) {
		loopableArray.push( '...' );
	}

	// Push first index item in the array if it does not already exists.
	if ( -1 === loopableArray.indexOf( 1 ) ) {
		loopableArray.unshift( 1 );
	}

	// Push last index item in the array if it does not already exists.
	if ( -1 === loopableArray.indexOf( totalPages ) ) {
		loopableArray.push( totalPages );
	}

	return loopableArray;
};
