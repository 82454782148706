import React from "react";
import './MulticolorTitleTag.css'

const Tagh1 = (props) => {
  const items = Object.values(props.items);
  var html = '';
  items.map((item, i) => {
    html += '<span class="'+item.color+'">'+item.text+'</span>';
    return html;
  });
  return (
    <h1 dangerouslySetInnerHTML={{__html:html}}></h1>
  );
}
const Tagh2 = (props) => {
  const items = Object.values(props.items);
  var html = '';
  items.map((item, i) => {
    html += '<span class="'+item.color+'">'+item.text+'</span>';
    return html;
  });
  return (
    <h2 dangerouslySetInnerHTML={{__html:html}}></h2>
  );
}
const Tagh3 = (props) => {
  const items = Object.values(props.items);
  var html = '';
  items.map((item, i) => {
    html += '<span class="'+item.color+'">'+item.text+'</span>';
    return html;
  });
  return (
    <h3 dangerouslySetInnerHTML={{__html:html}}></h3>
  );
}
const Tagh4 = (props) => {
  const items = Object.values(props.items);
  var html = '';
  items.map((item, i) => {
    html += '<span class="'+item.color+'">'+item.text+'</span>';
    return html;
  });
  return (
    <h4 dangerouslySetInnerHTML={{__html:html}}></h4>
  );
}
const Tagh5 = (props) => {
  const items = Object.values(props.items);
  var html = '';
  items.map((item, i) => {
    html += '<span class="'+item.color+'">'+item.text+'</span>';
    return html;
  });
  return (
    <h5 dangerouslySetInnerHTML={{__html:html}}></h5>
  );
}
const Tagh6 = (props) => {
  const items = Object.values(props.items);
  var html = '';
  items.map((item, i) => {
    html += '<span class="'+item.color+'">'+item.text+'</span>';
    return html;
  });
  return (
    <h6 dangerouslySetInnerHTML={{__html:html}}></h6>
  );
}

const MulticolorTitleTag = (props) => {
  const tagName = props.tagName;
  if(tagName === '1') {
    return ( <Tagh1 items={props.items} /> );
  }else if(tagName === '2'){
    return ( <Tagh2 items={props.items} /> );
  }else if(tagName === '3'){
    return ( <Tagh3 items={props.items} /> );
  }else if(tagName === '4'){
    return ( <Tagh4 items={props.items} /> );
  }else if(tagName === '5'){
    return ( <Tagh5 items={props.items} /> );
  }else if(tagName === '6'){
    return ( <Tagh6 items={props.items} /> );
  }
}

export default MulticolorTitleTag;
