import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";
import $ from 'jquery';

const FaqAgents = (props) => {
  $(document).ready(function(){
    $('.mainDiv .nav-link').on('click',function(e){
      e.preventDefault();
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      var id = $(this).attr('href');
      $('.tab-pane').removeClass('active');
      $(id).addClass('active');
    });
    $('.accordion-header button').on('click',function(e){
      e.preventDefault();
      $('.accordion-header button').not(this).addClass('collapsed');
      $(this).toggleClass('collapsed');
      var id = $(this).attr('data-target');
      console.log(id);
      console.log($('.collapse').not(id));
      $('.collapse').not(id).removeClass('show');
      $(id).toggleClass('show');
    });
  });

  return (
    <>
      <Header en={GLobalLinks["partner/faq-agents"]['en']} fr={GLobalLinks["partner/faq-agents"]['fr']} lang={GLobalLinks["partner/faq-agents"]['lang']} />
      <div className="container mainDiv faqsagents">
        <div className="row">
          <div className="col-xs-12 content">
            <h1 className="forms">FAQ Agents</h1>
            <div>
            <div className="row">
                <div className="col-lg-8">
                    <h2><strong>Travel Right Insurance Plans (TRIPS)</strong></h2>
                    <div id="accordionExample" className="accordion">
                        <div className="card">
                            <div id="headingOne" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        My client already has Emergency Medical coverage through their employer. Are they able to purchase Trip Cancellation, Trip Interruption, and Trip Delay coverage for their trip?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>Yes. Your client can purchase a <a href="/products/trips/travel/">‘Trip Cancellation/Interruption Plan’</a> which provides benefits for trip cancellation, trip interruption, and trip delay.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingThree" className="card-header">
                                <h2 className="mb-0 accordion-header" style={{display: 'block', width: '100%'}}>
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        My client’s provincial health care card is currently not valid. However, they must travel for personal reasons. Are they able to purchase emergency medical insurance coverage for their trip?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>Yes, they can. However, emergency medical coverage will be limited. As per the Travel Right Insurance Plan (TRIPs) policy wordings:</li>
                                    </ul>
                                    <p>
                                        “You must be insured under a valid Canadian federal, provincial or territorial government health insurance plan (GHIP) or Canadian university health insurance plan (UHIP). Otherwise, the limit of
                                        <u>coverage is $25,000.”</u>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingFour" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        My client is taking a trip outside of their home province but will remain within Canada. Do they need to purchase emergency medical insurance?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>
                                            Yes, purchasing emergency medical insurance anytime your client travels outside of their home province/territory can provide your client with benefits against sudden and unforeseen medical emergencies which
                                            may not be covered by their provincial/territorial health insurance plan. Common emergency medical exclusions of government health insurance plans include prescription medication, ambulance charges, and
                                            paramedical services.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingFive" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Can a client purchase travel insurance coverage once they’ve left their home province for their vacation?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>Yes, however, they must purchase the insurance before leaving Canada.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingSix" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">Why should my client purchase an Annual Plan?</button>
                                </h2>
                            </div>
                            <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>
                                            An annual plan provides your client with the flexibility to travel multiple times throughout the covered period, without having to purchase a new policy each time they depart, provided they remain eligible
                                            for coverage under the given policy.
                                        </li>
                                        <li>Annual plans are a great way to save money if your client plans to travel more than once during a 365 day period. Your client can choose between a 15 or 30-day plan.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingSeven" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                        If my client purchases an Annual Plan and wants to travel longer than 15 or 30 days, can they buy additional coverage for their trip?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>The 15-day or 30-day annual plans may be topped up by purchasing a single trip emergency medical plan for the additional days required.</li>
                                        <li>
                                            For example, your client purchases a 15-day annual plan (departing June 1). However, one of their trips will be 20 days (ending June 20). They may purchase a single-trip plan to top-up their annual plan for
                                            the additional 5 days of their trip (June 16 – 20).
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingEight" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">What is an 'All-Inclusive' Plan?</button>
                                </h2>
                            </div>
                            <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>
                                            An all-inclusive plan provides bundled benefits of emergency medical and non-medical insurance coverages all-in-one! Benefits include emergency medical, trip cancellation, trip interruption, trip delay,
                                            baggage and personal effects, worldwide accident, and airflight accident.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingNine" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                        What benefits are available under the Emergency Medical Plan?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                <div className="card-body">
                                    <p>Emergency Medical</p>
                                    <ul className="list">
                                        <li>Hospital and Medical – Unlimited</li>
                                        <li>Accidental Dental up to $1,500</li>
                                        <li>Emergency Medical Evacuation/Return Home – Unlimited</li>
                                        <li>Accommodation &amp; Meals up to $1,750</li>
                                        <li>Hospital Stay and Allowance up to $500</li>
                                        <li>Repatriation of Remains – Unlimited</li>
                                        <li>Cremation/Burial at Destination up to $3,000</li>
                                    </ul>
                                    <p>The coverages listed above are for the Travelance TRIPs Emergency Medical Insurance Plan only.</p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingTen" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                        There are no medical questionnaires required for the coverage my client is purchasing. Does this mean they are automatically covered for any medical emergency?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>
                                            No. While the plan your client is purchasing does not require a medical questionnaire there are eligibility requirements and stability periods for pre-existing conditions the client must meet to be eligible
                                            for coverage under a given policy.
                                        </li>
                                        <li>Note: not all policies have the same eligibility requirements or offer benefits for pre-existing conditions.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingEleven" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">When does Trip Cancellation/Interruption apply?</button>
                                </h2>
                            </div>
                            <div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>
                                            <strong>Trip Cancellation</strong> applies when the insured must cancel their <strong>covered trip</strong> before the departure date due to a covered event, that is sudden and unforeseen beyond their
                                            control, as listed in the policy wording.
                                        </li>
                                        <li>
                                            <strong>Trip Interruption</strong> applies when the insured must interrupt their <strong>covered trip</strong> while travelling (on or after their departure date) due to a sudden and unforeseen covered event,
                                            that is beyond their control, as listed in the policy wording.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingTwelve" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                        My client needs emergency medical treatment - what do they do?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwelve" className="collapse" aria-labelledby="headingTwelve" data-parent="#accordionExample">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>
                                            Contact the Emergency Assistance Provider before being admitted to the hospital or within 24 hours after a life or organ-threatening emergency. The phone numbers are listed on the Confirmation of Coverage
                                            (COC) provided to the client at the time of purchase, as well as in the policy under the ‘What To Do When You Need Assistance’ and ‘Claims Information’ sections.
                                        </li>
                                        <li>When receiving medical treatment, remind your client to be sure to keep all of their documentation and original receipts as these may be required to review their claim.</li>
                                        <li>To report a claim, call: <a href="tel:1885260111">1-888-526-0111</a></li>
                                        <li>Claim forms can be found at: <a href="/claims/">https://www.travelance.ca/claims/</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingThirteen" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                        Are refunds provided for Travel Right Insurance Plans (TRIPs)?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThirteen" className="collapse" aria-labelledby="headingThirteen" data-parent="#accordionExample">
                                <div className="card-body">
                                    Your client may cancel their policy within 10-days of purchase if they have not departed on their covered trip and there is no claim in progress. Outside of the ‘10 Day Right to Examine Period,’ the Emergency Medical
                                    Insurance Plans may be refunded prior to travel. Or, if the insured returns to the departure point before the expiry date, then they may request a refund of the premium paid for the unused days provided that:
                                    <ul className="list">
                                        <li>Proof is submitted for the date of return</li>
                                        <li>A claim has not been incurred under the policy</li>
                                        <li>A request for a premium refund is submitted to Travelance</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2><strong>Visitors to Canada Emergency Medical Insurance (VTC)</strong></h2>
                    <div id="accordionExample2" className="accordion">
                        <div className="card">
                            <div id="headingFourteen" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                        Does Travelance offer insurance for the super visa?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFourteen" className="collapse" aria-labelledby="headingFourteen" data-parent="#accordionExample2">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>Yes, both of our Visitors to Canada Emergency Medical plans meet the super visa insurance requirements when purchased for a period of at least one year with a minimum sum insured of $100,000.</li>
                                        <li>
                                            <a href="http://www.cic.gc.ca/english/visit/supervisa.asp"><u>For information about the super visa, please visit the Immigration, Refugees and Citizenship Canada website.</u></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingFifteen" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                        What is the difference between the Visitors To Canada Emergency Medical Insurance Premier and Essential plans?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFifteen" className="collapse" aria-labelledby="headingFifteen" data-parent="#accordionExample2">
                                <div className="card-body">
                                    <p>The Essential plan offers coverage for:</p>
                                    <ul className="list">
                                        <li>Those who are in good health and require a cost effective plan</li>
                                        <li>Emergency medical up to the plan limit selected, arising from sudden and unforeseen circumstances</li>
                                        <li>No benefits available for any pre-existing conditions</li>
                                    </ul>
                                    <p>The Premier plan builds on the Essential plan, offering upgraded and additional benefits including:</p>
                                    <ul className="list">
                                        <li>Some coverage for pre-existing conditions (be sure to read the policy wording for details)</li>
                                        <li>Accidental Death and Dismemberment (up to the plan limit selected to a maximum of $100,000)</li>
                                        <li>Repatriation – $16,000</li>
                                    </ul>
                                    <p>For a detailed comparison of the plan highlights, please see the plan comparison on our website:</p>
                                    <a href="/products/visitors-canada-insurance/">https://www.travelance.ca/products/visitors-canada-insurance/</a>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingSixteen" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">When is a deductible applied?</button>
                                </h2>
                            </div>
                            <div id="collapseSixteen" className="collapse" aria-labelledby="headingSixteen" data-parent="#accordionExample2">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>The deductible is applied per occurrence.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingSeventeen" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                        If my client is applying for a super visa, do they have to purchase coverage for one year if they are only staying in Canada for three months?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseSeventeen" className="collapse" aria-labelledby="headingSeventeen" data-parent="#accordionExample2">
                                <div className="card-body">
                                    <p>As per the Government of Canada requirements, the super visa program requires proof of emergency medical insurance from a Canadian insurance provider for:</p>
                                    <ul className="list">
                                        <li>a minimum coverage period of one year, and</li>
                                        <li>a minimum sum insured of $100,000</li>
                                    </ul>
                                    <p>
                                        Provided the insured has not incurred a claim under their policy, a refund for the unused days will be allowed less an administration fee. To complete the refund, we require proof of the insured’s early return to
                                        their home country.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingEighteen" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                        If my client’s super visa application is denied, will they get a full refund?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseEighteen" className="collapse" aria-labelledby="headingEighteen" data-parent="#accordionExample2">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>Yes. If your client’s super visa application is denied, we will provide them with a full premium refund of their policy. To complete the refund, we require a copy of their visa denial letter.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingNineteen" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                                        Are there any medical questions or is there a medical questionnaire my client needs to complete before they purchase the policy?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseNineteen" className="collapse" aria-labelledby="headingNineteen" data-parent="#accordionExample2">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>There is an online medical questionnaire to complete for this policy. If your client does not meet the Eligibility Requirements, they will not be eligible to purchase the policy.</li>
                                        <li>
                                            If your client has a pre-existing condition and would like to purchase the Premier Plan, be sure to advise your client to read the policy carefully including the Policy Exclusions section and how the
                                            exclusions relate to pre-existing conditions.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingTwenty" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                                        Can my client travel outside of Canada during their period of coverage?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwenty" className="collapse" aria-labelledby="headingTwenty" data-parent="#accordionExample2">
                                <div className="card-body">
                                    <p>Under the VTC Premier and Essential plans, coverage for side trips is available provided:</p>
                                    <ul className="list">
                                        <li>
                                            <p>At the time of a claim outside of Canada, the number of covered days must be more than 50% of the accumulated covered days under this policy</p>
                                            <ul>
                                                <li>
                                                    For example, if the insured is taking a side trip outside of Canada for 30 days, then the total number of accumulated days under the policy, prior to the start of the side trip, must be at least 31
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Each side trip must begin in Canada</li>
                                        <li>Each side trip can be up to a maximum of 45 days</li>
                                        <li>Trips cannot be to the insured’s home country</li>
                                    </ul>
                                    <p>For complete details, please refer to policy wording.</p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingTwentyone" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwentyone" aria-expanded="false" aria-controls="collapseTwentyone">
                                        Can my client pay for policy coverage in monthly installments?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwentyone" className="collapse" aria-labelledby="headingTwentyone" data-parent="#accordionExample2">
                                <div className="card-body">
                                    <p>
                                        No, there is no monthly payment option. Your client must pay in full for the total number of days they require at the time of purchase. For more information on our Visitors to Canada Emergency Medical Insurance
                                        plans, please visit:
                                        <a href="/products/visitors-canada-insurance/">https://www.travelance.ca/products/visitors-canada-insurance/</a>
                                        Or call <a href="tel:1-855-566-8555">1-855-566-8555</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingTwentytwo" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwentytwo" aria-expanded="false" aria-controls="collapseTwentytwo">
                                        I’ve sold my client a policy, what are my next steps?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwentytwo" className="collapse" aria-labelledby="headingTwentytwo" data-parent="#accordionExample2">
                                <div className="card-body">
                                    <ul className="list">
                                        <li>
                                            <strong>a. Advise your client to read the policy wording carefully.</strong> They will be sent a copy to their personal email address as you entered in the online agent portal during the sale. Be sure your
                                            client understands the policy wording, including the eligibility, benefits, exclusions, terms and conditions.
                                        </li>
                                        <li><strong>b.</strong> If you have any questions or are unsure how to advise your client, call Travelance for clarification 1-855-566-8555</li>
                                        <li><strong>c.</strong> If your client wishes to cancel their policy, they have 10 days from the application date to receive a full refund, provided the policy has not started.</li>
                                        <li><strong>d.</strong> Make sure your client is familiar with the Emergency Assistance call in procedure.</li>
                                        <li>
                                            <strong>e. Advise your client to carry page three of their Confirmation of Coverage (COC) with them at all times, while travelling.</strong>
                                            <ul>
                                                <li>This page has a handy wallet card that contains the Emergency Assistance numbers you will need to call in case of an emergency.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2><strong>Student Accident Plans (SAP)</strong></h2>
                    <div id="accordionExample3" className="accordion">
                        <div className="card">
                            <div id="headingTwentyThree" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                                        My client has coverage through their employer, what is the benefit of purchasing a Student Accident Plan?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwentyThree" className="collapse" aria-labelledby="headingTwentyThree" data-parent="#accordionExample3">
                                <div className="card-body">
                                    <p>
                                        This policy provides coverage for your client’s family against the possible costs that could be incurred due to their child/student’s accident. Benefits under this plan are not normally covered by extended or
                                        government health insurance plans.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingTwentyFour" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                                        Who is eligible to purchase the Student Accident Plan?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwentyFour" className="collapse" aria-labelledby="headingTwentyFour" data-parent="#accordionExample3">
                                <div className="card-body">
                                    <p>
                                        Any child or student who resides in Canada (excluding Quebec) and is over 6 months and under 26 years of age. If the child/student is 14 years of age or older, they must be enrolled in a minimum of 3 concurrent
                                        classes.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingTwentyFive" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">What types of classes are eligible?</button>
                                </h2>
                            </div>
                            <div id="collapseTwentyFive" className="collapse" aria-labelledby="headingTwentyFive" data-parent="#accordionExample3">
                                <div className="card-body">The student must be enrolled in a minimum of 3 concurrent classes, which include online courses and co-ops if they are part of the curriculum.</div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingTwentySix" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwentySix" aria-expanded="false" aria-controls="collapseTwentySix">
                                        Where and when is coverage available under the Student Accident Plan?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwentySix" className="collapse" aria-labelledby="headingTwentySix" data-parent="#accordionExample3">
                                <div className="card-body">Coverage is available 24 hours a day, every day, everywhere for one year from the date of purchase.</div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingTwentySeven" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwentySeven" aria-expanded="false" aria-controls="collapseTwentySeven">
                                        Does the policy provide coverage for students/children while they are on school field trips, summer holidays, or participating in recreational activities?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwentySeven" className="collapse" aria-labelledby="headingTwentySeven" data-parent="#accordionExample3">
                                <div className="card-body">Yes, the plan is available 24 hours a day, every day, during the entire policy term.</div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingTwentyEight" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwentyEight" aria-expanded="false" aria-controls="collapseTwentyEight">
                                        Does this policy provide coverage outside of the client’s home province or Canada?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwentyEight" className="collapse" aria-labelledby="headingTwentyEight" data-parent="#accordionExample3">
                                <div className="card-body">
                                    <p>
                                        The Student Accident Plan provides accident coverage outside the insured’s province or territory of residence for trips up to 30 days in duration. The coverage for emergency medical is limited to $100,000 and
                                        includes emergency medical expense coverage for sickness.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingTwentyNine" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwentyNine" aria-expanded="false" aria-controls="collapseTwentyNine">
                                        Is this policy available to students enrolled in a private school?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwentyNine" className="collapse" aria-labelledby="headingTwentyNine" data-parent="#accordionExample3">
                                <div className="card-body"><p>Yes, as long as they meet the eligibility requirements.</p></div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingThirty" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThirty" aria-expanded="false" aria-controls="collapseThirty">
                                        Is this policy available to homeschooled children?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThirty" className="collapse" aria-labelledby="headingThirty" data-parent="#accordionExample3">
                                <div className="card-body">
                                    <p>
                                        Yes, if your children are between 6 months and 14 years of age. If your children are students 14 years of age or older and under 26 years of age, they must be full-time students (minimum of 3 concurrent courses).
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingThirtyOne" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThirtyOne" aria-expanded="false" aria-controls="collapseThirtyOne">
                                        Can university and college students purchase the Student Accident Plan?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThirtyOne" className="collapse" aria-labelledby="headingThirtyOne" data-parent="#accordionExample3">
                                <div className="card-body">Yes, as long as they are enrolled in a minimum of three concurrent courses and are under 26 years of age.</div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingThirtyTwo" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThirtyTwo" aria-expanded="false" aria-controls="collapseThirtyTwo">Is there a family rate available?</button>
                                </h2>
                            </div>
                            <div id="collapseThirtyTwo" className="collapse" aria-labelledby="headingThirtyTwo" data-parent="#accordionExample3">
                                <div className="card-body"><p>No. The annual premium is $49.99 per insured.</p></div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingThirtyThree" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThirtyThree" aria-expanded="false" aria-controls="collapseThirtyThree">When does the policy come into effect?</button>
                                </h2>
                            </div>
                            <div id="collapseThirtyThree" className="collapse" aria-labelledby="headingThirtyThree" data-parent="#accordionExample3">
                                <div className="card-body"><p>Coverage under this policy begins on the date and time Old Republic Insurance Company of Canada or its authorized representative receives the application and the required premium.</p></div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingThirtyFour" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThirtyFour" aria-expanded="false" aria-controls="collapseThirtyFour">Does the policy automatically renew?</button>
                                </h2>
                            </div>
                            <div id="collapseThirtyFour" className="collapse" aria-labelledby="headingThirtyFour" data-parent="#accordionExample3">
                                <div className="card-body"><p>No, the policy does not automatically renew.</p></div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingThirtyFive" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThirtyFive" aria-expanded="false" aria-controls="collapseThirtyFive">When does the policy terminate?</button>
                                </h2>
                            </div>
                            <div id="collapseThirtyFive" className="collapse" aria-labelledby="headingThirtyFive" data-parent="#accordionExample3">
                                <div className="card-body">
                                    <p>On the expiration date shown on the Confirmation of Coverage or the date the insured ceases to be a full-time student (enrollment in a minimum of 3 concurrent courses), whichever happens first.</p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingThirtySix" className="card-header">
                                <h2 className="mb-0 accordion-header">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThirtySix" aria-expanded="false" aria-controls="collapseThirtySix">Are refunds available?</button>
                                </h2>
                            </div>
                            <div id="collapseThirtySix" className="collapse" aria-labelledby="headingThirtySix" data-parent="#accordionExample3">
                                <div className="card-body"><p>You may cancel this policy within 10 days of the date on the Confirmation of Coverage for a full refund, if there is no claim in process.</p></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="stickyDiv">
                        <div className="holder_block">
                            <h2>Quick Links</h2>
                            <ul className="list">
                                <li><a href="/products/trips/">TRIPs</a></li>
                                <li><a href="/products/visitors-canada-insurance/">VTC Plans</a></li>
                                <li><a href="/products/student-accident-plan/">SAP</a></li>
                                <li><a href="/products/international-student/">ISP</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            </div>
          </div>
        </div>
      </div>
      <Footer lang={GLobalLinks["partner/faq-agents"]['lang']} />
    </>
  );
}

export default FaqAgents;
