import React from "react";
import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit';
import Pages from "./components/pages/Pages";
import './App.css';
import CookieConsent from "react-cookie-consent";

function App() {
  const store = createStore({
    authName:'_auth',
    authType:'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
  });

  return (
    <>
      <AuthProvider store={store}>
        <Pages />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName2"
          style={{ fontSize:'12px',background: "#cd4037",textAlign:'center',diplay:'flex',justifyContent:'center' }}
          buttonStyle={{ color: "#ffffff",background:'#495677',borderRadius:'3px', fontSize: "12px",fontWeight:'bold',padding:'2px 12px',lineHeight:'20px',margin:'0px 10px',cursor:'pointer' }}
          expires={30}
        >
        By accessing our website and clicking on 'Accept', you agree to our <a style={{color:'#fff',textDecoration:'underline'}} href="/privacy-policy">Privacy Policy</a> and use of cookies. {" "}
        </CookieConsent>
      </AuthProvider>
    </>
  );
}

export default App;
