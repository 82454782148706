import React from "react";
import Header from "../../commons/Header/Header";
import Footer from "../../commons/Footer/Footer";

const NotFound = () => {
    return (
      <>
      <Header en="/" fr="/fr" lang="en" />
      <div className="container mainDiv ab nf404">
        <div className="row">
          <div className="col-xs-12 content">
            <h1 className="about-us">Have not found what you searched for? We are here for help!</h1>
            <div>
              <div className="banner404 bannerInner"></div>
              <div style={{textAlign: 'center'}} className="content">
                <a className="btn-2 blue" href="/contact-us/">Contact Us</a>
                <a className="btn-2" href="/">Go to Home</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer lang="en" />
      </>
    );
};

export default NotFound;
