const GLobalLinks ={
  "about-us": {
    "en": "/about-us",
    "fr": "/a-propos-de-nous",
    "lang": "en"
  },
  "a-propos-de-nous": {
    "en": "/about-us",
    "fr": "/a-propos-de-nous",
    "lang": "fr"
  },
  "about-us/mission-and-vision": {
    "en": "/about-us/mission-and-vision",
    "fr": "/a-propos-de-nous/mission-et-vision",
    "lang": "en"
  },
  "a-propos-de-nous/mission-et-vision": {
    "en": "/about-us/mission-and-vision",
    "fr": "/a-propos-de-nous/mission-et-vision",
    "lang": "fr"
  },
  "contact-us/reviews": {
    "en": "/contact-us/reviews",
    "fr": "/fr",
    "lang": "en"
  },
  "products": {
    "en": "/products",
    "fr": "/produits",
    "lang": "en"
  },
  "produits": {
    "en": "/products",
    "fr": "/produits",
    "lang": "fr"
  },
  "products/insurance-for-visitors-to-canada-with-super-visa": {
    "en": "/products/insurance-for-visitors-to-canada-with-super-visa",
    "fr": "/fr",
    "lang": "en"
  },
  "products/visitors-canada-insurance": {
    "en": "/products/visitors-canada-insurance",
    "fr": "/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence",
    "lang": "en"
  },
  "produits/assurance-visiteurs-au-canada-frair-medicaux-durgence": {
    "en": "/products/visitors-canada-insurance",
    "fr": "/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence",
    "lang": "fr"
  },
  "products/trips": {
    "en": "/products/trips",
    "fr": "/produits/l-assurance-voyage",
    "lang": "en"
  },
  "produits/l-assurance-voyage": {
    "en": "/products/trips",
    "fr": "/produits/l-assurance-voyage",
    "lang": "fr"
  },
  "products/trips/travel": {
    "en": "/products/trips/travel",
    "fr": "/produits/l-assurance-voyage/voyage",
    "lang": "en"
  },
  "produits/l-assurance-voyage/voyage": {
    "en": "/products/trips/travel",
    "fr": "/produits/l-assurance-voyage/voyage",
    "lang": "fr"
  },
  "products/trips/youth": {
    "en": "/products/trips/youth",
    "fr": "/produits/l-assurance-voyage/jeunes",
    "lang": "en"
  },
  "produits/l-assurance-voyage/jeunes": {
    "en": "/products/trips/youth",
    "fr": "/produits/l-assurance-voyage/jeunes",
    "lang": "fr"
  },
  "products/student-accident-plan": {
    "en": "/products/student-accident-plan",
    "fr": "/produits/regime-dassurance-accidents-pour-etudiants",
    "lang": "en"
  },
  "produits/regime-dassurance-accidents-pour-etudiants": {
    "en": "/products/student-accident-plan",
    "fr": "/produits/regime-dassurance-accidents-pour-etudiants",
    "lang": "fr"
  },
  "products/international-student": {
    "en": "/products/international-student",
    "fr": "/produits/police-dassurance-voyage-pour-les-etudiants-etrangers",
    "lang": "en"
  },
  "produits/police-dassurance-voyage-pour-les-etudiants-etrangers": {
    "en": "/products/international-student",
    "fr": "/produits/police-dassurance-voyage-pour-les-etudiants-etrangers",
    "lang": "fr"
  },
  "products/faqs": {
    "en": "/products/faqs",
    "fr": "/produits/produits-faq",
    "lang": "en"
  },
  "produits/produits-faq": {
    "en": "/products/faqs",
    "fr": "/produits/produits-faq",
    "lang": "fr"
  },
  "claims": {
    "en": "/claims",
    "fr": "/reclamations",
    "lang": "en"
  },
  "reclamations": {
    "en": "/claims",
    "fr": "/reclamations",
    "lang": "fr"
  },
  "claims/forms": {
    "en": "/claims/forms",
    "fr": "/reclamations/formulaires",
    "lang": "en"
  },
  "reclamations/formulaires": {
    "en": "/claims/forms",
    "fr": "/reclamations/formulaires",
    "lang": "fr"
  },
  "about-our-underwriter": {
    "en": "/about-our-underwriter",
    "fr": "/au-sujet-de-notre-assureur",
    "lang": "en"
  },
  "au-sujet-de-notre-assureur": {
    "en": "/about-our-underwriter",
    "fr": "/au-sujet-de-notre-assureur",
    "lang": "fr"
  },
  "products/visitors-canada-insurance/monthly": {
    "en": "/products/visitors-canada-insurance/monthly",
    "fr": "/fr",
    "lang": "en"
  },
  "products/isp": {
    "en": "/products/isp",
    "fr": "/fr",
    "lang": "en"
  },
  "partner": {
    "en": "/partner",
    "fr": "/partenaires",
    "lang": "en"
  },
  "partenaires": {
    "en": "/partner",
    "fr": "/partenaires",
    "lang": "fr"
  },
  "partner/form": {
    "en": "/partner/form",
    "fr": "/fr",
    "lang": "en"
  },
  "partner/form/thank-you": {
    "en": "/partner/form/thank-you",
    "fr": "/fr",
    "lang": "en"
  },
  "partner/agency-form": {
    "en": "/partner/agency-form",
    "fr": "/fr",
    "lang": "en"
  },
  "partner/wfg-information": {
    "en": "/partner/wfg-information",
    "fr": "/partner/wfg-information",
    "lang": "en"
  },
  "partner/greatwayfinancial": {
    "en": "/partner/greatwayfinancial",
    "fr": "/fr",
    "lang": "en"
  },
  "partner/experiorfinancialgroup": {
    "en": "/partner/experiorfinancialgroup",
    "fr": "/fr",
    "lang": "en"
  },
  "partner/wfg-information/thank-you": {
    "en": "/partner/wfg-information/thank-you",
    "fr": "/fr",
    "lang": "en"
  },
  "partner/reasons-to-partner-with-travelance": {
    "en": "/partner/reasons-to-partner-with-travelance",
    "fr": "/partenaires/sassocier-avec-travelance",
    "lang": "en"
  },
  "partenaires/sassocier-avec-travelance": {
    "en": "/partner/reasons-to-partner-with-travelance",
    "fr": "/partenaires/sassocier-avec-travelance",
    "lang": "fr"
  },
  "partner/resources": {
    "en": "/partner/resources",
    "fr": "/fr",
    "lang": "en"
  },
  "partner/faq-agents": {
    "en": "/partner/faq-agents",
    "fr": "/fr",
    "lang": "en"
  },
  "complaint-resolution-process": {
    "en": "/complaint-resolution-process",
    "fr": "/processus-de-resolution-des-plaintes",
    "lang": "en"
  },
  "processus-de-resolution-des-plaintes": {
    "en": "/complaint-resolution-process",
    "fr": "/processus-de-resolution-des-plaintes",
    "lang": "fr"
  },
  "partner/online-training-sessions": {
    "en": "/partner/online-training-sessions",
    "fr": "/partenaires/sessions-de-formation-en-ligne",
    "lang": "en"
  },
  "partenaires/sessions-de-formation-en-ligne": {
    "en": "/partner/online-training-sessions",
    "fr": "/partenaires/sessions-de-formation-en-ligne",
    "lang": "fr"
  },
  "partner/client-referral-program": {
    "en": "/partner/client-referral-program",
    "fr": "/fr",
    "lang": "en"
  },
  "sitemap": {
    "en": "/sitemap",
    "fr": "/plan-du-site",
    "lang": "en"
  },
  "plan-du-site": {
    "en": "/sitemap",
    "fr": "/plan-du-site",
    "lang": "fr"
  },
  "terms-conditions": {
    "en": "/terms-conditions",
    "fr": "/modalites-et-conditions",
    "lang": "en"
  },
  "modalites-et-conditions": {
    "en": "/terms-conditions",
    "fr": "/modalites-et-conditions",
    "lang": "fr"
  },
  "privacy-policy": {
    "en": "/privacy-policy",
    "fr": "/politique-de-confidentialite",
    "lang": "en"
  },
  "politique-de-confidentialite": {
    "en": "/privacy-policy",
    "fr": "/politique-de-confidentialite",
    "lang": "fr"
  },
  "contact-us": {
    "en": "/contact-us",
    "fr": "/contactez-nous",
    "lang": "en"
  },
  "contactez-nous": {
    "en": "/contact-us",
    "fr": "/contactez-nous",
    "lang": "fr"
  },
  "blogs": {
    "en": "/blogs",
    "fr": "/blogs",
    "lang": "en"
  },

  "partner/referral-form": {
    "en": "/partner/referral-form",
    "fr": "/partner/referral-form",
    "lang": "en"
  },
}

export default GLobalLinks;
