import React from 'react';
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import PartnerForm from "../../../commons/PartnerForm/PartnerForm";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Banner from "../../../../assets/images/travelance-banner-WFG-partner.webp"

const PartnerWfgPage = (props) => {

  return (
    <>
      <Header en='/partner/wfg-information' fr='/fr' lang='en' />
      <Container className="mainDiv partnerFormPage">
        <Row className="">
          <Col xl={12} className="content">
            <h1>WFG Partner Information Centre</h1>
            <Container>
              <Row className="">
                <Col xl={12}>
                     <img src={Banner} width="100%" alt="WFG-partner" target="_blank" />
                     <br />
                     <h2><strong>Welcome to the Travelance Information Centre for World Financial Group Brokers</strong></h2>
                     <p>On this page, you will find the tools to help you get started selling Travelance products.</p>
                </Col>
                <Col xl={8} className="">
                  <PartnerForm heading="Partner with Travelance" mga="wfg" />
                  <br />
                  <h2>Online Training</h2>
                  <p><a href="/partner/online-training-sessions/" target="_blank" rel="noopener noreferrer">Live Online Training Session</a> - Register for our online training sessions to learn about our products and services. Each week, our team members lead interactive, lively, and information-packed sessions, with small class sizes, and a team member can answer all your questions.</p>
                  <h3><span className="highlight">Emergency Medical Insurance for Visitors to Canada</span></h3>
              Protection for visitors to Canada against unforeseen medical emergencies is a must as they are not covered under Canada’s health care system. After attending this session, you will have an in-depth knowledge about Visitors
              to Canada Emergency Medical Insurance plans. Please register for one of the following ‘Emergency Medical Insurance for Visitors to Canada’ sessions:
              <p style={{fontSize:'14px',color:'#495677'}}>Please register for <span style={{textDecoration: 'underline'}}>one</span> of the following ‘Emergency Medical Insurance for Visitors to Canada’ sessions:</p>

              <table style={{height: '150px'}} border="1" width="100%">
              <tbody>
              <tr className="bg-color txt-center">
              <td style={{textAlign: 'center', backgroundColor: '#e8e8e8'}} width="37%"><strong>Date &amp; Time (ET)</strong></td>
              <td style={{textAlign: 'center', backgroundColor: '#e8e8e8'}} width="29%"><strong>Presenter</strong></td>
              <td style={{textAlign: 'center', backgroundColor: '#e8e8e8'}} width="34%"><strong>Registration</strong></td>
              </tr>
              <tr className="alt">
              <td style={{textAlign: 'center'}}>May 14, 2025 5:00 pm (ET)</td>
              <td style={{textAlign: 'center'}}>Megha Vashishtha</td>
              <td style={{textAlign: 'center'}}><span style={{textDecoration: 'underline'}}><span style={{color: '#ff0000', textDecoration: 'underline'}}><span style={{color: '#cc4037', textDecoration: 'underline'}}><a href="https://travelanceinc.clickmeeting.com/wfg-emergency-medical-insurance-for-visitors-to-canada-may-14-/register" target="_blank" rel="noopener noreferrer">Click to Register</a></span></span></span></td>
              </tr>
              <tr className="alt">
              <td style={{textAlign: 'center'}}>August 20, 2025 5:00 pm (ET)</td>
              <td style={{textAlign: 'center'}}>Megha Vashishtha</td>
              <td style={{textAlign: 'center'}}><span style={{textDecoration: 'underline'}}><span style={{color: '#ff0000', textDecoration: 'underline'}}><span style={{color: '#cc4037', textDecoration: 'underline'}}><a href="https://travelanceinc.clickmeeting.com/wfg-emergency-medical-insurance-for-visitors-to-canada-aug-20-/register" target="_blank" rel="noopener noreferrer">Click to Register</a></span></span></span></td>
              </tr>
              </tbody>
              </table>
<br/>
<h3><span className="highlight">VTC Medical Eligibility Questionnaire (MEQ) Made Easier</span></h3>
Review the VTC Medical Eligibility Questionnaire (MEQ) with your clients with confidence! This presentation provides you with in-depth knowledge of each eligibility question for our Visitors to Canada Emergency Medical
Insurance plans.
<p style={{fontSize:'14px',color:'#495677'}}>Please register for <span style={{textDecoration: 'underline'}}>one</span> of the following ‘VTC Medical Eligibility Questionnaire (MEQ) Made Easier’ sessions:</p>

<table style={{height: '150px'}} border="1" width="100%">
<tbody>
<tr className="bg-color txt-center">
<td style={{textAlign: 'center', backgroundColor: '#e8e8e8'}} width="37%"><strong>Date &amp; Time (ET)</strong></td>
<td style={{textAlign: 'center', backgroundColor: '#e8e8e8'}} width="29%"><strong>Presenter</strong></td>
<td style={{textAlign: 'center', backgroundColor: '#e8e8e8'}} width="34%"><strong>Registration</strong></td>
</tr>
<tr className="alt">
<td style={{textAlign: 'center'}}>April 30, 2025 5:00 pm (ET)</td>
<td style={{textAlign: 'center'}}>Megha Vashishtha</td>
<td style={{textAlign: 'center'}}><span style={{textDecoration: 'underline'}}><span style={{color: '#ff0000', textDecoration: 'underline'}}><span style={{color: '#cc4037', textDecoration: 'underline'}}><a href="https://travelanceinc.clickmeeting.com/wfg-vtc-medical-eligibility-questionnaire-made-easier-apr-30-/register" target="_blank" rel="noopener noreferrer">Click to Register</a></span></span></span></td>
</tr>
<tr className="alt">
<td style={{textAlign: 'center'}}>September 24, 2025 5:00 pm (ET)</td>
<td style={{textAlign: 'center'}}>Phoebe Haroun</td>
<td style={{textAlign: 'center'}}><span style={{textDecoration: 'underline'}}><span style={{color: '#ff0000', textDecoration: 'underline'}}><span style={{color: '#cc4037', textDecoration: 'underline'}}><a href="https://travelanceinc.clickmeeting.com/wfg-vtc-medical-eligibility-questionnaire-made-easier-sep-24-/register" target="_blank" rel="noopener noreferrer">Click to Register</a></span></span></span></td>
</tr>
</tbody>
</table>
<br/>
<h3><span className="highlight">Travel Insurance – Value and Protection for Your Clients</span></h3>
Discover the growing need for travel insurance for travelling Canadians and what Travelance can offer to protect your clients. By offering choices, Travelance offers a plan for almost everyone’s needs.
<p style={{fontSize:'14px',color:'#495677'}}>Please register for <span style={{textDecoration: 'underline'}}>one</span> of the following ‘Travel Insurance – Value and Protection for Your Clients’ sessions:</p>

<table style={{height: '150px'}} border="1" width="100%">
<tbody>
<tr className="bg-color txt-center">
<td style={{textAlign: 'center', backgroundColor: '#e8e8e8'}} width="37%"><strong>Date &amp; Time (ET)</strong></td>
<td style={{textAlign: 'center', backgroundColor: '#e8e8e8'}} width="29%"><strong>Presenter</strong></td>
<td style={{textAlign: 'center', backgroundColor: '#e8e8e8'}} width="34%"><strong>Registration</strong></td>
</tr>
<tr className="alt">
<td style={{textAlign: 'center'}}>June 18, 2025 5:00 pm (ET)</td>
<td style={{textAlign: 'center'}}>Megha Vashishtha</td>
<td style={{textAlign: 'center'}}><span style={{textDecoration: 'underline'}}><span style={{color: '#ff0000', textDecoration: 'underline'}}><span style={{color: '#cc4037', textDecoration: 'underline'}}><a href="https://travelanceinc.clickmeeting.com/wfg-travel-insurance-value-and-protection-for-your-clients-jun-18-/register" target="_blank" rel="noopener noreferrer">Click to Register</a></span></span></span></td>
</tr>
<tr className="alt">
<td style={{textAlign: 'center'}}>October 22, 2025 5:00 pm (ET)</td>
<td style={{textAlign: 'center'}}>Megha Vashishtha</td>
<td style={{textAlign: 'center'}}><span style={{textDecoration: 'underline'}}><span style={{color: '#ff0000', textDecoration: 'underline'}}><span style={{color: '#cc4037', textDecoration: 'underline'}}><a href="https://travelanceinc.clickmeeting.com/wfg-travel-insurance-value-and-protection-for-your-clients-oct-22-/register" target="_blank" rel="noopener noreferrer">Click to Register</a></span></span></span></td>
</tr>
</tbody>
</table>
<br/>
<h3><span className="highlight">Student Accident Plan</span></h3>
Did you know that expenses from most accidents exceed the benefits provided by your client’s government and group insurance plans? Learn how the Student Accident Plan offers families protection against financial
responsibilities in the event of a child’s or student’s accident.
<p style={{fontSize:'14px',color:'#495677'}}>Please register for <span style={{textDecoration: 'underline'}}>one</span> of the following ‘Student Accident Plan’ sessions:</p>

<table style={{height: '150px'}} border="1" width="100%">
<tbody>
<tr className="bg-color txt-center">
<td style={{textAlign: 'center', backgroundColor: '#e8e8e8'}} width="37%"><strong>Date &amp; Time (ET)</strong></td>
<td style={{textAlign: 'center', backgroundColor: '#e8e8e8'}} width="29%"><strong>Presenter</strong></td>
<td style={{textAlign: 'center', backgroundColor: '#e8e8e8'}} width="34%"><strong>Registration</strong></td>
</tr>
<tr className="alt">
<td style={{textAlign: 'center'}}>July 30, 2025 5:00 pm (ET)</td>
<td style={{textAlign: 'center'}}>Phoebe Haroun</td>
<td style={{textAlign: 'center'}}><span style={{textDecoration: 'underline'}}><span style={{color: '#ff0000', textDecoration: 'underline'}}><span style={{color: '#cc4037', textDecoration: 'underline'}}><a href="https://travelanceinc.clickmeeting.com/wfg-student-accident-plan-jul-30-/register" target="_blank" rel="noopener noreferrer">Click to Register</a></span></span></span></td>
</tr>
<tr className="alt">
<td style={{textAlign: 'center'}}>December 03, 2025 5:00 pm (ET)</td>
<td style={{textAlign: 'center'}}>Megha Vashishtha</td>
<td style={{textAlign: 'center'}}><span style={{textDecoration: 'underline'}}><span style={{color: '#ff0000', textDecoration: 'underline'}}><span style={{color: '#cc4037', textDecoration: 'underline'}}><a href="https://travelanceinc.clickmeeting.com/wfg-student-accident-plan-dec-3-/register" target="_blank" rel="noopener noreferrer">Click to Register</a></span></span></span></td>
</tr>
</tbody>
</table>
<br/>



                  <p><a href="https://academy.travelance.ca/remote-login/login.cfm" target="_blank" rel="noopener noreferrer">Travelance Academy</a> - Our most flexible training option. Once you have completed the partnership process with Travelance, you will be able to access Travelance Academy through MyTL. Access on-demand, online learning, that you can complete on your own schedule.</p>
                  <p>Looking for training for your team? Email <a href="mailto:training@travelance.ca">training@travelance.ca</a> to request a session. Training sessions are provided in English only.</p>
                  <h2>Travelance Partner Resources</h2>
                  <p>To help you get started selling Travelance products, we’ve created resources to help you along every step of the way. Access complete policy wordings, rate charts, steps to selling guides, links to useful resources, and more! <a href="/partner/resources/" target="_blank" rel="noopener noreferrer">Click here to access our Partner Resource Webpage</a>.</p>
                  <h2>Contact Information</h2>
                  <p>For policy related questions, changes, or portal assistance contact Support Services, Monday to Friday, 9:00 am to 7:00 pm (ET), <a href="mailto:info@travelance.ca">info@travelance.ca</a> or <a href="tel:8555668555">1-855-566-8555</a> extension 1.</p>

                </Col>
                <Col xl={4} className="">
                  <div className="stickyDiv">
                      <div className="holder_block">
                        <h2>WFG Partners</h2>
                        <ul className="list">
                          <li><a href="/partner/resources/">Travelance Resources</a></li>
                          <li><a href="/contact-us/">Contact Information</a></li>
                        </ul>
                      </div>
                      <div className="holder_block">
                        <h2>Quick Links</h2>
                        <ul className="list">
                          <li><a href="/claims/">Claims</a></li>
                          <li><a href="https://www.getreliable.com/getreliable/">Online Portal Login</a></li>
                          <li><a href="/about-our-underwriter/">Our Underwriter</a></li>
                          <li><a href="/#faqSection">Partner FAQs</a></li>
                          <li><a href="/partner/online-training-sessions/">Register for Online Training</a></li>
                          <li><a href="https://www.travel.gc.ca/travelling/advisories/">Travel Advisories</a></li>
                        </ul>
                      </div>
                    </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer lang="en" />
    </>
  );
}

export default PartnerWfgPage;
