import React from "react";
import './AccordianSection.css';
import MulticolorTitleTag from "../MulticolorTitleTag/MulticolorTitleTag";
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Banner1 from "../../../assets/images/photo.webp"
import Banner2 from "../../../assets/images/photo2.webp"
import Banner3 from "../../../assets/images/photo3.webp"
import Banner4 from "../../../assets/images/photo4.webp"
import Banner5 from "../../../assets/images/photo5.webp"
import Banner6 from "../../../assets/images/photo6.webp"

const AccordianSection = (props) => {

  const commonProps = {
    0:{'text':'Travelance insurance ','color':'red'},
    1:{"text":"isn't just about financial protection, ","color":"black"},
    2:{"text":"it’s about the ","color":"black"},
    3:{"text":"service and support ","color":"red"},
    4:{"text":"you carry with you.","color":"black"},
  };

  const commonProps1 = {
    0:{'text':'assurance Travelance ','color':'red'},
    1:{"text":"pourvoit non seulement la protection financière, ","color":"black"},
    2:{"text":"mais aussi le ","color":"black"},
    3:{"text":"service et soutien ","color":"red"},
    4:{"text":"pendant votre voyage.","color":"black"},
  };

  const data = [
    {
      'title':'Why You Need Travel Insurance?',
      'body' :'<p><img loading="lazy" width="1003" height="318" src="'+Banner1+'" alt="Travelance-Why-You-Need-Travel-Insurance" title="Travelance-Why-You-Need-Travel-Insurance"></p><p>Injury and sickness can happen anytime, anywhere. Travel insurance can provide you and your family with financial protection while you are abroad. The Government of Canada highly recommends that both visitors to Canada and travelling Canadians purchase adequate insurance coverage for their trip (this applies to day trips to the US as well).</p><p>Government Health Insurance Plans (GHIP) provide very limited emergency medical coverage for Canadians travelling outside of their province or territory of residence, and visitors to Canada are not covered under Canada’s universal health care system. For this reason, it’s ideal to purchase travel insurance.</p>'
    },
    {
      'title':'Visitors to Canada Emergency Medical Insurance (VTC)',
      'body' :'<p><img loading="lazy" width="1003" height="318" src="'+Banner2+'" alt="Travelance-Visitors-to-Canada-Emergency-Medical-Insurance-(VTC)" title="Travelance-Visitors-to-Canada-Emergency-Medical-Insurance-(VTC)"></p><p>Some visitors visas, such as the super visa, have mandatory health insurance requirements for visa approval and entry into Canada. Travelance offers two plans that are eligible as “super visa insurance” when purchased according to the Government of Canada regulations. Explore our Visitors to Canada Premier and Essential plans today by <a href="/products/visitors-canada-insurance/">clicking here</a>.</p>'
    },
    {
      'title':'Travel Right Insurance Plans (TRIPs)',
      'body' :'<p><img loading="lazy" width="1003" height="318" src="'+Banner3+'" alt="Travelance-Travel-Right-Insurance-Plans-(TRIPs)" title="Travelance-Travel-Right-Insurance-Plans-(TRIPs)"></p><p>Travel insurance is important for Canadians on any trip outside of their home province or territory. Travelance offers options to meet the needs of different travellers, whether new or experienced, going on a single trip or multiple trips a year. Our Travel Right Insurance Plans (TRIPs) offer a variety of benefits, from trip cancellation/interruption to emergency medical. To learn more about the variety of options available, <a href="/products/trips/">click here</a>.</p>'
    },
    {
      'title':'Student Accident Plan (SAP)',
      'body' :'<p><img loading="lazy" width="1003" height="318" src="'+Banner4+'" alt="Travelance-Student-Accident-Plan-(SAP)" title="Travelance-Student-Accident-Plan-(SAP)"></p><p>Let’s face it – accidents happen! For only $49.99 the benefits are numerous and the peace of mind is invaluable as your family is financially protected from the costs associated with a child/students’ physical accident. <a href="/products/student-accident-plan/">Click here</a> to learn more about this affordable and convenient plan.</p>'
    },
    {
      'title':'International Student Plan (ISP)',
      'body' :'<p><img loading="lazy" width="1003" height="318" src="'+Banner6+'" alt="International Student Plan (ISP))" title="International Student Plan (ISP)"></p><p>Students may not be covered under Canada’s universal health care system, meaning students and their families would have to pay out of pocket for any unexpected medical emergencies.</p><p>Our International Student Plans provide coverage for students coming to study at a Canadian school, their spouses and their dependents, important emergency medical coverage while here in Canada. Plans can be purchased for a minimum of 14 days up to a maximum of 365 days.</p>'
    },
    {
      'title':'Why Choose Travelance?',
      'body' :'<p><img loading="lazy" width="1003" height="318" src="'+Banner5+'" alt="Travelance-Why-Choose-Travelance" title="Travelance-Why-Choose-Travelance"></p><p><strong>Excellent Service</strong> – Friendly and reliable support before, during, and after each policy purchase.<br> <strong>Variety of Plans</strong> – Our comprehensive suite of insurance products includes a variety of travel insurance options for travelling Canadians and visitors to Canada, as well as a Student Accident Plan.<br> <strong>Free Look Period</strong> – 10 days to review and cancel your policy for a full refund, as long as it’s before the start date and no claim has been made.<br> <strong>Worldwide Assistance</strong> – All of our plans are supported 24/7 by our worldwide, multilingual travel assistance company.</p> <p><a href="/contact-us">Contact us</a> to find a licensed Travelance insurance broker in your area today!</p>'
    },
  ];

  const data1 = [
    {
      'title':"Pourquoi vous avez besoin d'une assurance voyage?",
      'body' :'<p><img loading="lazy" width="1003" height="318" src="'+Banner1+'" alt="Travelance-Pourquoi-vous-avez-besoin-d\'une-assurance-voyage" title="Travelance-Pourquoi-vous-avez-besoin-d\'une-assurance-voyage"></p><p>Les blessures et les maladies peuvent survenir n\'importe quand, n\'importe où. L\'assurance voyage peut vous offrir, ainsi qu\'à votre famille, une protection financière lorsque vous êtes à l\'étranger. Le gouvernement du Canada recommande fortement aux visiteurs du Canada et aux voyageurs canadiens de souscrire une assurance adéquate pour leur voyage (cela s\'applique également aux voyages d\'une journée aux États-Unis).</p><p>Les régimes d\'assurance maladie du gouvernement offrent une couverture médicale d\'urgence très limitée aux Canadiens qui voyagent à l\'extérieur de leur province ou territoire de résidence, et les visiteurs au Canada ne sont pas couverts par le système de santé universel du Canada. Pour cette raison, il est idéal de souscrire une assurance voyage.</p>'
    },
    {
      'title':'Forfaits d\'assurance Travel Right (TRIPs)',
      'body' :'<p><img loading="lazy" width="1003" height="318" src="'+Banner3+'" alt="Travelance-Forfaits-d\'assurance-Travel-Right-(TRIPs)" title="Travelance-Forfaits-d\'assurance-Travel-Right-(TRIPs)"></p><p>L\'assurance voyage est importante pour les Canadiens lors de tout voyage à l\'extérieur de leur province ou territoire d\'origine. Travelance offre des options pour répondre aux besoins de différents voyageurs, qu\'ils soient novices ou expérimentés, effectuant un seul voyage ou plusieurs voyages par année. Nos forfaits d\'assurance Travel Right (TRIPs) offrent une variété d\'avantages, de l\'annulation / interruption de voyage jusqu’aux soins médicaux d\'urgence. Pour en savoir plus sur la variété de forfaits disponibles, <a href="/fr/produits/l-assurance-voyage/">cliquez ici</a>.</p>'
    },
    {
      'title':'Forfait d\'assurance accident pour étudiant',
      'body' :'<p><img loading="lazy" width="1003" height="318" src="'+Banner4+'" alt="Travelance-Forfait-d\'assurance-accident-pour-étudiant" title="Travelance-Forfait-d\'assurance-accident-pour-étudiant"></p><p>Avouons-le - les accidents surviennent ! Pour seulement 49,99 $ par année, aidez à protéger financièrement votre famille contre les coûts possibles associés aux blessures accidentelles. Les avantages sont nombreux et la tranquillité d\'esprit est inestimable car votre famille est financièrement protégée contre les coûts des blessures accidentelles liées à leurs accidents. <a href="/fr/produits/l-assurance-voyage/jeunes/">Cliquez ici </a>pour en savoir plus sur ce forfait abordable et pratique.</p>'
    },
    {
      'title':'Pourquoi choisir Travelance?',
      'body' :'<p><img loading="lazy" width="1003" height="318" src="'+Banner5+'" alt="Travelance-Pourquoi-choisir-Travelance" title="Travelance-Pourquoi-choisir-Travelance"></p><p><strong>Un excellent service</strong> – Un soutien amical et fiable avant, pendant et après l’achat de votre police d’assurance.<br><strong>Une variété de forfaits</strong> – Notre gamme complète de produits d\'assurance comprend une variété d\'options d\'assurance voyage pour les voyageurs canadiens et les visiteurs au Canada, ainsi qu\'un forfait d\'accident pour étudiants.<br><strong>Période d’essai gratuit</strong> – Vous avez 10 jours pour examiner et annuler votre police pour un remboursement complet, à condition que ce soit fait avant la date d’entrée en vigueur et qu\'aucune réclamation n\'a été déposée.<br><strong>Assistance mondiale</strong> – Tous nos plans sont appuyés 24h/24 et 7j/7 par notre société d\'assistance voyage multilingue dans le monde entier.</p><p><a href="/fr/contactez-nous/">Contactez-nous dès aujourd\'hui</a> pour trouver un courtier d\'assurance Travelance agréé dans votre région !</p>'
    },
  ];

  var title = {};
  var accord = {};
  if(props.lang === 'fr'){
    title = commonProps1;
    accord = data1;
  }else{
    title = commonProps;
    accord = data;
  }

  return (
    <div className="accordianSection">
      <Container>
        <Row>
          <Col md={1} ></Col>
          <Col className="center text-center" md={10} >
            <MulticolorTitleTag items={title} tagName="3" />
          </Col>
          <Col md={1} ></Col>
        </Row>
        <Row>
          <Col md={1} ></Col>
          <Col className="" md={10}>
            <Accordion defaultActiveKey={0}>
            {
              accord.map((item,i)=>{
                return(
                  <Accordion.Item key={i} id={i} className={i} eventKey={i}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body dangerouslySetInnerHTML={{__html:item.body}} >
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })
            }
            </Accordion>
          </Col>
          <Col md={1} ></Col>
        </Row>
      </Container>
    </div>
  );
}

export default AccordianSection;
