import React from 'react';
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
import Topheader from "./Topheader/Topheader";
import Middleheader from "./Middleheader/Middleheader";
import Bottomheader from "./Bottomheader/Bottomheader";
import Meta from "../Meta/Meta";


const Header = (props) => {

    const location = useLocation();
    let key = (location.pathname.length > 1 ? location.pathname.replace(/\/$/, ''):location.pathname);
    console.log(key);

    if(Meta[key]){
    }else{
      key = '/';
    }
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url":Meta[key]['link'],
      "name": Meta[key]['title'],
      "description": Meta[key]['description']
    };

    return (
      <div id="header-sticky-wrapper" className="sticky-wrapper" >
        <Helmet>
          <title>{Meta[key]['title']}</title>
          <meta name="description" content={Meta[key]['description']} />
          <link rel="canonical" href={Meta[key]['canonical']} />
          <meta property="og:locale" content={Meta[key]['lang']} />
	        <meta property="og:locale:alternate" content={Meta[key]['langalt']} />
          <meta property="og:title" content={Meta[key]['title']} />
	        <meta property="og:description" content={Meta[key]['description']} />
	        <meta property="og:url" content={Meta[key]['link']} />
	        <meta property="og:site_name" content="Travelance" />
          <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
          <link rel="alternate" href={Meta[key]['en']} hreflang="en" />
          <link rel="alternate" href={Meta[key]['fr']} hreflang="fr" />
        </Helmet>
        <header id="header" >
          <Topheader  en={props.en} fr={props.fr} />
          <Middleheader lang={props.lang} />
          <Bottomheader lang={props.lang} />
        </header>
      </div>
    );
}

export default Header;
