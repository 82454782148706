import React from "react";
import './Bottomheader.css';
import NavbarMain from './NavbarMain';

const Bottomheader = (props) => {
        return (
            <section id="bottomheader">
              <div className="container clearfix">
                <div className="row">
                  <div className="col-md-12">
                    <NavbarMain lang={props.lang} />
                  </div>
                </div>
              </div>
            </section>
        );
}

export default Bottomheader;
