import React from "react";
import './Counter.css'

const Counter = (props) => {
    var over= '';
    var brokers='';
    var claims='';
    var clients='';
    var partners='';
    var languages='';
    if(props.lang === 'fr'){
      over = 'Plus de';
      brokers = 'courtiers';
      claims = 'réclamations';
      clients = 'servis';
      partners = 'Partenaires disponibles en';
      languages = 'langues';
    }else{
      over = 'Over';
      brokers = 'Brokers';
      claims = 'Claims Paid';
      clients = 'Clients Served';
      partners = 'Partners available in';
      languages = 'Languages';
    }
    return (
      <div className="counter">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 center border-right">
                <div className="content-bl">
                <h2><span className="small blue">{over}</span><br />
                <span className="red">10,000</span><br />
                {brokers}</h2>
                </div>
            </div>
            <div className="col-lg-3 center border-right">
                <div className="content-bl red-ln">
                <h2><span className="small blue">{over}</span><br />
                <span className="red middle">90%</span><br />
                {claims}</h2>
                </div>
            </div>
            <div className="col-lg-3 center border-right">
                <div className="content-bl">
                <h2><span className="small blue">{over}</span><br />
                <span className="red middle">100,000</span><br />
                {clients}</h2>
                </div>
            </div>
            <div className="col-lg-3 center">
                <div className="content-bl red-ln">
                <h2><span className="small blue">{partners}</span><br />
                <span className="red middle">23+</span><br />
                {languages}</h2>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Counter;
