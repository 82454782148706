import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
const routes = require('../../../../routes'); // Use CommonJS require

const Sitemap = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {

    let url = `https://www.travelance.ca/backend/wp-json/api/v1/tr/posts?per_page=All`;
    trackPromise(
      axios.get(url).then((res) => {
        const { data } = res;
        const blogs = data.posts_data;

        // Group blogs by category
        const grouped = blogs.reduce((acc, blog) => {
          const categoryIndex = acc.findIndex((item) => item.category === blog.categories.slug);

          if (categoryIndex === -1) {
            acc.push({
              category: blog.categories.slug,
              cate_name: blog.categories.name,
              blogs: [blog]
            });
          } else {
            acc[categoryIndex].blogs.push(blog);
          }

          return acc;
        }, []);

        console.log(grouped);
        const sortedGrouped = grouped.sort((a, b) =>
          a.category.localeCompare(b.category)
        );

        setBlogs(sortedGrouped);
    }));
  }, []);

  function decodeHTMLEntities(encodedString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(encodedString, 'text/html');
    return doc.documentElement.textContent;
  }

  return (
    <>
    <Header en="/sitemap" fr="/plan-du-site" lang="en" />
    <div className="container mainDiv ab aboutUs">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="about-us">Sitemap</h1>
          <div>
            <h2>Pages</h2>
            <ul>
            {
              routes.staticEn.map((route, index) => {
                if(route.childs){
                  return(
                    <li key={index}>
                      <p><a href={route.path}>{route.title}</a></p>
                      <ul>
                      {
                        route.childs.map((child, i) => {
                          var indexi = index.toString()+i.toString()
                          if(child.childs){
                            return(
                              <li key={indexi}>
                                <p><a href={child.path}>{child.title}</a></p>
                                <ul>
                                {
                                  child.childs.map((children, j) => {
                                    var indexij = indexi.toString()+j.toString()
                                    return(
                                      <li key={indexij}>
                                        <p><a href={children.path}>{decodeHTMLEntities(children.title)}</a></p>
                                      </li>
                                    )
                                  })
                                }
                                </ul>
                              </li>
                            )
                          }else{
                            return(
                              <li key={indexi}>
                                <p><a href={child.path}>{decodeHTMLEntities(child.title)}</a></p>
                              </li>
                            )
                          }
                        })
                      }
                      </ul>
                    </li>
                  )
                }else{
                  return(
                    <li key={index}>
                      <p><a href={route.path}>{decodeHTMLEntities(route.title)}</a></p>
                    </li>
                  )
                }

              })
            }
            </ul>
            <h2>Blogs</h2>
            <ul>
              {blogs.map((route, index) => {
                return(
                  <li key={`dynamic-${index}`}>
                    <h3>{decodeHTMLEntities(route.cate_name)}</h3>
                    <ul>
                    {
                      route.blogs.map((blog,j) =>{
                        return(
                          <li key={`blog-${index}-${j}`}>
                            <p><a href={`blog/${blog.name}`}>{decodeHTMLEntities(blog.title)}</a></p>
                          </li>
                        )
                      })
                    }
                    </ul>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer lang="en" />
  </>
  );
};

export default Sitemap;
