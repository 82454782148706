import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";
import CrossIcon from "../../../../assets/images/icon20.webp"

const ProductsVCI = (props) => {
  return (
    <>
    <Header en={GLobalLinks["products/visitors-canada-insurance"]['en']} fr={GLobalLinks["products/visitors-canada-insurance"]['fr']} lang={GLobalLinks["products/visitors-canada-insurance"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="visitors-canada-insurance">Visitors to Canada Emergency Medical Insurance (VTC)</h1>
          <div>
            <div className="bannerInner header_visitors-canada-insurance"></div>
            <h3 style={{color: '#314b75', fontSize: '20px', textShadow: '0 0 1px #314b75'}}>Our visitors to Canada plans are eligible as super visa insurance when purchased according to Canadian government requirements!</h3>
            <h2>Choose from two plans:</h2>
            <ul>
              <li>The <strong>Essential Plan</strong> is an option for visitors who are in good health, are not taking any medication, and require a low cost plan.</li>
              <li>The <strong>Premier Plan</strong> is our recommended plan, for visitors who want additional and upgraded benefits, and those with one or more pre-existing condition(s) (some conditions apply).</li>
            </ul>
            <div className="row">
              <div className="col-lg-8">
                <h2>Compare Our Two Visitors to Canada Emergency Medical Insurance Plans</h2>
                <div className="container">
                  <div className="row" style={{border: '1px solid', borderBottom: '0px', background: '#cc4037'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid',background: '#cc4037', padding: '10px'}}>
                      <p style={{color: '#fff'}}><strong>Benefit</strong></p>
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid',background: '#cc4037', padding: '10px'}}>
                      <p style={{color: '#fff'}}><strong>Essential Plan</strong></p>
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',background: '#cc4037', padding: '10px'}}>
                      <p style={{color: '#fff'}}><strong>Premier Plan</strong></p>
                    </div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                      Coverage for Pre-existing Conditions
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}><img style={{width:'20px'}} src={CrossIcon} alt="cross" width="24px" /></div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>Available for some conditions, for visitors up to age 79</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Maximum Duration
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>558 Days</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>558 Days</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Emergency Medical*
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>$25,000, $50,000, $100,000, or $150,000 (Depends on plan limit chosen)</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>$25,000, $50,000, $100,000, or $150,000 (Depends on plan limit chosen)</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Hospitalization
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>Included (Semi-Private)</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>Included (Semi-Private)</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Services of Physician, Surgeon, In-Hospital Nurse
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>Included</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>Included</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Ambulance
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>Included</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>Included</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Diagnostic, Laboratory &amp; X-Rays
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>Included</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>Included</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Therapeutic Equipment Rental &amp; Purchase
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>Included</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>Included</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Prescription Medications
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>30 day supply, up to $1,000 per occurrence</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>30 day supply, up to $10,000 per occurrence</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Emergency Return Home
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>Included</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>Included</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Emergency Dental*
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>Injury $2,000 Non-Injury Pain $300</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>Injury $4,000 Non-Injury Pain $500</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Repatriation of Remains
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>$10,000</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>$16,000</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Cremation/Burial At Destination
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>$4,000</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>$6,000</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Identification of Remains
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>Round-Trip Airfare + $450 Expenses</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>Round-Trip Airfare + $450 Expenses</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Follow-Up Visits
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>One visit, up to $1,000</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>Three visits, up to $3,000</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Emergency Paramedical Services
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}><img style={{width:'20px'}} src={CrossIcon} alt="cross" width="24px" /></div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>$500 per category of Practitioner</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Visit to Bedside
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}><img style={{width:'20px'}} src={CrossIcon} alt="cross" width="24px" /></div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>Airfare $3,000 + $1,000 Expenses</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Accommodations &amp; Meals
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}><img style={{width:'20px'}} src={CrossIcon} alt="cross" width="24px" /></div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>$150/Day, Maximum $1,500</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Return/Escort of Dependent Children
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}><img style={{width:'20px'}} src={CrossIcon} alt="cross" width="24px" /></div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>$3,000</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Incidental Hospital Expenses (e.g. Television)
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}><img style={{width:'20px'}} src={CrossIcon} alt="cross" width="24px" /></div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>$250</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Return of Baggage &amp; Personal Effects
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}><img style={{width:'20px'}} src={CrossIcon} alt="cross" width="24px" /></div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>$500</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Accidental Death &amp; Dismemberment
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}><img style={{width:'20px'}} src={CrossIcon} alt="cross" width="24px" /></div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>Up to the plan limit chosen to a maximum of $100,000</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Coverage for Side Trips
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>Included</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>Included</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Deductible
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>$0</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>$0</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Deductible Options
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>$250 (-10%) $500 (-15%) $1,000 (-20%) $5,000 (-30%) $10,000 (-45%)</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>$250 (-10%) $500 (-15%) $1,000 (-20%) $5,000 (-30%) $10,000 (-45%)</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Waived Deductible
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>First $250 of deductible waived when hospitalized for 72 consecutive hours</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>First $1,000 of deductible waived when hospitalized for 72 consecutive hours</div>
                  </div>
                  <div className="row" style={{border: '1px solid'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>
                        Family Rate
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', padding: '10px'}}>2x Eldest’s Rate</div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px'}}>2x Eldest’s Rate</div>
                  </div>
                  <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', borderBottom: '1px solid', padding: '10px'}}>
                        Policy Wording
                    </div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',borderRight: '1px solid', borderBottom: '1px solid', padding: '10px'}}><a href="https://travelance.box.com/v/VTC-EssentialPlan" target="_blank" rel="noopener noreferrer">Click to review.</a></div>
                    <div className="col mw" style={{width:'33%',wordBreak: 'break-word',padding: '10px', borderBottom: '1px solid'}}><a href="https://travelance.box.com/v/VTC-PremierPlan" target="_blank" rel="noopener noreferrer">Click to review.</a></div>
                  </div>
                </div>
                <p>*Coverage under this plan is only for emergencies and does not include routine check-ups. For the definition of ‘emergency’, please review the ‘definitions’ section of the policy wording. Emergency medical insurance purchased for the purposes of the parent and grandparent super visa must meet <a href="https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/temporary-residents/visitors/super-visa.html">IRCC requirements</a>. This is a brief description of coverage. Please see policy wording for complete benefits, definitions, exclusions, limitation, terms and conditions.</p>
                <em>Underwritten by Old Republic Insurance Company of Canada.</em>
              </div>
              <div className="col-lg-4">
                <div className="stickyDiv">
                  <div className="holder_block">
                    <a href="https://quote.travelance.ca/#/VTC" target="_blank" rel="noopener noreferrer">
                      <button style={{fontSize: '15px', display: 'inline-block', marginLeft: '25%', background: '#cc4037', border: '1px solid #cc4037', boxShadow: '0px 3px 0px #a72720', borderRadius: '3px', padding: '8px 12px', color: '#fff', cursor: 'pointer'}} >GET A FREE QUOTE</button>
                    </a>
                  </div>
                  <div className="holder_block">
                    <h3><a href="/products/visitors-canada-insurance/monthly/" target="_blank" rel="noopener noreferrer">Monthly Payment Option, Now Available! Learn more.</a></h3>
                  </div>
                  <div className="holder_block">
                    <h2>Who can buy?</h2>
                    <ul className="list">
                      <li>For Ages: 15 days to 85 years</li>
                      <li>Those eligible as per the policy wording</li>
                    </ul>
                  </div>
                  <div className="holder_block">
                    <h2>Special Features</h2>
                    <ul className="list">
                      <li>Duration: 7 days to 18 months</li>
                      <li>Plans are supported by 24/7 worldwide multilingual travel assistance</li>
                      <li>Coverage begins once the insured leaves the borders of their home country when purchased prior to departure</li>
                      <li>Refund of premium for early return</li>
                      <li>No charge to cancel a policy due to visa denial</li>
                      <li>Family Rates available</li>
                      <li>10 Day Right to Examine</li>
                    </ul>
                  </div>
                  <div className="holder_block">
                    <h2>All Products</h2>
                    <ul className="list">
                      <li><a href="/products/trips/travel/">Travel</a></li>
                      <li><a href="/products/trips/youth/">Youth</a></li>
                      <li><a href="/products/visitors-canada-insurance/">Visitors to Canada Emergency Medical Insurance</a></li>
                      <li><a href="/products/student-accident-plan/">Student Accident Plan</a></li>
                      <li><a href="/products/international-student/">International Student Plan</a></li>
                    </ul>
                  </div>
                  <div className="holder_block">
                    <h2>Quick Links</h2>
                    <ul className="list">
                      <li><a href="https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&amp;returnurl=step1.aspx">Get a Quote</a></li>
                      <li><a href="/#faqSection">FAQs</a></li>
                      <li><a href="/about-our-underwriter/">Our Underwriter</a></li>
                      <li><a href="/claims/">Claims</a></li>
                    </ul>
                  </div>
                  <div className="holder_block">
                    <h3><a href="https://travelance.app.box.com/v/purchase-vtc-plans" target="_blank" rel="noopener noreferrer">How to Quote or Purchase a VTC Plan from Our Website</a></h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["products/visitors-canada-insurance"]['lang']} />
    </>
  );
}

export default ProductsVCI;
