import React, { useState,useRef } from 'react';
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import $ from 'jquery';
import ReCAPTCHA from "react-google-recaptcha";
import Cf7FormWrapper from "./../../../cf7-form-wrapper";
import './Reffral.css';

function Form({ handler, isLoading, isSent, hasError }) {
  const [formState, setFormState] = useState({})
  const captchaRef = useRef(null);

  const handleFieldChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
    })
  }

  const handleFormSubmit = async(e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    if(!token){
      alert('Please click on the reCAPTCHA box.');
    }else{
      captchaRef.current.reset();
      const dataToSubmit = $(e.target).serializeArray().reduce(function(prev, curr) {
        prev[curr.name] = curr.value;
        return prev;
      }, {});
      dataToSubmit.grecaptcharesponse = token;
      dataToSubmit._wpcf7_recaptcha_response = token;
      formState._wpcf7_recaptcha_response = token;
      formState['g-recaptcha-response'] = token;
      formState._wpcf7 = '23861';
      formState['cf7sr-recaptcha'] = token;
      formState._wpcf7_unit_tag = "wpcf7-f23860-p49-o1";
      handler(e, formState);
    }
  }

  return (
      <form id="Reffreal" action="" method="POST" encType="multipart/form-data" name="Reffreal" onSubmit={handleFormSubmit} >
        <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f23861-p23671-o1" />
        <Row>
          <Col xl={12}>
            <div className="form-group">
              <label>Your Name: <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="your_name" type="text" name="your_name" placeholder="" onChange={(e) => handleFieldChange("your_name", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Your Email Address <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="your_email_address" type="email" name="your_email_address" placeholder="" onChange={(e) => handleFieldChange("your_email_address", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Your Agency Code <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="agency_code" type="text" name="agency_code" placeholder="" onChange={(e) => handleFieldChange("agency_code", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Referred's Name <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="referred_name" type="text" name="referred_name" placeholder="" onChange={(e) => handleFieldChange("referred_name", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Referred's Email Address <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="referred_email_address" type="email" name="referred_email_address" placeholder="" onChange={(e) => handleFieldChange("referred_email_address", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Referred's Phone Number <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="referred_phone_no" type="text" name="referred_phone_no" placeholder="" onChange={(e) => handleFieldChange("referred_phone_no", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Referred's Company Name <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="referred_company_name" type="text" name="referred_company_name" placeholder="" onChange={(e) => handleFieldChange("referred_company_name", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Are they from the same MGA? <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <span className="radioBox"><input className="radio" id="mga" type="radio" name="mga" value="Yes" placeholder="" onChange={(e) => handleFieldChange("mga", e)} required /><span> Yes</span></span>
              <span className="radioBox"><input className="radio" id="mga" type="radio" name="mga" value="No" placeholder="" onChange={(e) => handleFieldChange("mga", e)} required /><span> No</span></span>
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Is the referral an Agency/MGA Owner? <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <span className="radioBox"><input className="radio" id="agency" type="radio" name="agency" value="Yes" placeholder="" onChange={(e) => handleFieldChange("agency", e)} required /><span> Yes</span></span>
              <span className="radioBox"><input className="radio" id="agency" type="radio" name="agency" value="No" placeholder="" onChange={(e) => handleFieldChange("agency", e)} required /><span> No</span></span>
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Years of experience <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <input className="select" id="experience" type="text" name="experience" placeholder="" onChange={(e) => handleFieldChange("experience", e)} required />
            </div>
          </Col>
          <Col xl={12}>
            <div className="form-group">
              <label>Main product line <span className="required" style={{color: '#ff0000'}}>*</span></label>
              <select className="select" id="main_product_line" name="main_product_line" onChange={(e) => handleFieldChange("main_product_line", e)} >
                <option value="Life/Critical/Disability">Life/Critical/Disability</option>
                <option value="Travel/Visitors to Canada">Travel/Visitors to Canada</option>
                <option value="Investments">Investments</option>
                <option value="Auto/Home">Auto/Home</option>
              </select>
            </div>
          </Col>
          <Col xl={12}>
            <ReCAPTCHA sitekey="6LeUkOAUAAAAAKjgWJg-4bZ7lFiXG4hj7bW5ikBo" ref={captchaRef} />
          </Col>
          <br />
          <Col xl={12}>
            <input className="btn btn-primary" type="submit" name="Submit" value="Submit" />
            {
              isSent ?
              (<div className="wpcf7-response-output" >Thank you for your message. It has been sent.</div>):""
            }
            {hasError ?
                (<div className="wpcf7-response-output-erron" >{hasError}</div>)
              :""}
          </Col>
        </Row>
      </form>
  )
}

const Reffreal = (props) => {
  return (
    <>
      <Header en="/partner/client-referral-program" fr="/fr" lang="en" />
      <div className="container mainDiv ab">
        <div className="row">
          <div className="col-xs-12 content">
            <h1>Referral Form</h1>
            <Container>
              <Row>
                <Col>
                  <div className="bannerInner header-partner-referral-program"></div>
                </Col>
              </Row>

              <Row>
                <Col xl={7}>
                  Do you have insurance licensed friends or acquaintances in the insurance industry, who are not Travelance partners yet? Help them enjoy the same great benefits you receive by participating in our Partner Referral Program! You could earn extra cash for every successful partner you refer.
                  <table className="tablestyle" width="100%" cellspacing="0" cellpadding="0">
                    <tbody>
                      <tr>
                        <td style={{background: "#cc4037", color: "#fff"}}><strong>Brokers Referred</strong></td>
                        <td style={{background: "#cc4037", color: "#fff"}}><strong>Cash Reward (for successful referrals)</strong></td>
                      </tr>
                      <tr>
                        <td>1-2</td>
                        <td>$50 per successful referral</td>
                      </tr>
                      <tr>
                        <td>3-4</td>
                        <td>$75 per successful referral</td>
                      </tr>
                      <tr>
                        <td>5-15</td>
                        <td>$100 per successful referral</td>
                      </tr>
                    </tbody>
                  </table>
                  <strong> Terms & Conditions</strong>
                  <ul>
                    <li>Successful Referral: Referrals must sign up as Travelance partners and complete at least one sale within two months of your referral.</li>
                    <li>Referrals must come from outside your own agency/MGA.</li>
                    <li>The contest runs round the year from January 1st to December 31st.</li>
                    <li>If you generate referrals earning a total of $500 or more, a T4 slip will be issued to you.</li>
                  </ul>
                  Payments for successful rewards earned will be made via direct deposit on the 28th of the following months – March, June, September, and December with two grand prize draws in June and December.

                  Each Grand Prize is one (1) $250 cash reward.

                  Help your industry colleagues bring more variety to their portfolios, and earn rewards for sharing your knowledge. Take part in Travelance’s Partner Referral Program and spread the success!
                </Col>
                <Col xl={1}>
                </Col>
                <Col xl={4}>
                  <Cf7FormWrapper siteUrl="https://www.travelance.ca/backend" formId="23860">
                    <Form />
                  </Cf7FormWrapper>

                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <Footer lang="en" />
    </>
  );
}

export default Reffreal;
