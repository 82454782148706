import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';
import './BlogsList.css';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import Moment from "react-moment";
import moment from 'moment';
import { IoCalendarSharp } from "react-icons/io5";

const BlogsList = (props) => {

    let { id } = useParams();

    if(id === undefined){
      id = 1;
    }
    let pageId = parseInt(id);
    const [blogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(pageId);
    const [totalPages, setTotalPages] = useState(1);

    const isThereNextPage     = currentPage < totalPages;
    const isTherePreviousPage = currentPage > 1;
    const paginationArray     = createPaginationArray( currentPage, totalPages );

    const getPageLink = ( pageNo ) => {
      return `/blogs/page/${pageNo}`;
    };

    useEffect(() => {

      let url = `${process.env.REACT_APP_API_ROOT}posts?page_no=${currentPage}`;
      trackPromise(
        axios.get(url).then((res) => {
          const { data } = res;
          setBlogs(data.posts_data);
          setTotalPages(data.page_count);
      }));
    }, [currentPage]);
    return (
      <>
      <div className="container mainDiv">
        <div className="row">
          <div className="col-xs-12 content">
            <h1>Our Blogs</h1>
          </div>
        </div>
        <div className="row g-5">
            {
              blogs.map((blog,i)=>{
                const parsedDate = moment(blog.date,'YYYY-MM-DD');
                let img = '';
                if(!blog.attachment_image.img_src){
                  img = <img src={require('../../../assets/images/logo3.webp')} alt="Travel" className={`featuredmedia blog-${blog.id}`} />
                }else{
                  img = <img src={blog.attachment_image.img_src[0]} alt={blog.title} className={`featuredmedia blog-${blog.id}`} />
                }
                return(
                  <div className="col-md-4 single-blog" key={blog.id}>
                    <div className="blog-inner">
                      <div className="blog-Header">
                        <Link to={`/blog/${blog.name}`}>
                          {img}
                        </Link>
                      </div>
                      <div className="blog-body">
                        <Link to={`/blog/${blog.name}`}>
                          <h4 className="blog-title" dangerouslySetInnerHTML={{__html:blog.title}}></h4>
                        </Link>
                        <div className="blog-excerpt" dangerouslySetInnerHTML={{__html:blog.excerpt}}></div>
                      </div>
                      <div className="blog-footer">
                        <Link to={`/blog/${blog.name}`} className="btn btn-primary">
                        Read More
                        </Link>
                        <div className="dateBlog"><IoCalendarSharp />  <Moment format="YYYY-MM-DD" local>{parsedDate}</Moment></div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
        </div>
        <div className="row g-5 footer-pagination">
          <Pagination>
            { isTherePreviousPage && <Pagination.Item className="prev page_no" to={ getPageLink( currentPage - 1 ) } onClick={ () => setCurrentPage( currentPage - 1 ) } >Previous</Pagination.Item> }
            { paginationArray && paginationArray.map( ( item, index ) => {
              if ( '...' !== item && currentPage !== item  ) {
                return (
                  <Pagination.Item key={ `${ item }-${ index }` }  to={ getPageLink( item ) } onClick={ () => setCurrentPage( item ) } className="page_no" >
                    { item }
                  </Pagination.Item>
                );
              } else {
                return (
                  <Pagination.Item key={ `${ item }-${ index }` } className={ currentPage === item ? 'page-no active' : '' }>
                    { item }
                  </Pagination.Item>
                );
              }
            } ) }
            { isThereNextPage && <Pagination.Item className="next page_no"  to={ getPageLink( currentPage + 1 ) } onClick={ () => setCurrentPage( currentPage + 1 ) } >Next</Pagination.Item> }
          </Pagination>

        </div>
      </div>
      </>
    );
}

export default BlogsList;

export const createPaginationArray = ( currentPage, totalPages ) => {

	let loopableArray   = [];
	let countOfDotItems = 0;

	// If there is only one page, return an empty array.
	if ( 1 === totalPages ) {
		return loopableArray;
	}

	/**
	 * Push the two index items before the current page.
	 */
	if ( 0 < currentPage - 2 ) {
		loopableArray.push( currentPage - 2 );
	}

	if ( 0 < currentPage - 1 ) {
		loopableArray.push( currentPage - 1 );
	}

	// Push the current page index item.
	loopableArray.push( currentPage );

	/**
	 * Push the two index items after the current page.
	 */
	if ( totalPages >= currentPage + 1 ) {
		loopableArray.push( currentPage + 1 );
	}

	if ( totalPages >= currentPage + 2 ) {
		loopableArray.push( currentPage + 2 );
	}

	/**
	 * Push the '...' at the beginning of the array
	 * only if the difference of between the 1st and 2nd index item is greater than 1.
	 */
	if ( 1 < loopableArray[ 0 ] - 1 ) {
		loopableArray.unshift( '...' );
		countOfDotItems += 1;
	}

	/**
	 * Push the '...' at the end of the array.
	 * only if the difference of between the last and 2nd last item is greater than 1.
	 * We remove the count of dot items from the array to get the actual indexes, while checking the condition.
	 */
	if (
		1 <
		totalPages - loopableArray[ loopableArray.length - ( 2 - countOfDotItems ) ]
	) {
		loopableArray.push( '...' );
	}

	// Push first index item in the array if it does not already exists.
	if ( -1 === loopableArray.indexOf( 1 ) ) {
		loopableArray.unshift( 1 );
	}

	// Push last index item in the array if it does not already exists.
	if ( -1 === loopableArray.indexOf( totalPages ) ) {
		loopableArray.push( totalPages );
	}

	return loopableArray;
};
