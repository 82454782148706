import React, {useState, useRef, useEffect } from 'react';
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import './ReviewPage.css';
import $ from 'jquery';
import ReCAPTCHA from "react-google-recaptcha"


const ReviewPage = (props) => {
  const [rating, setRating] = useState(5);
  const [reviews, setReviews] = useState([]);
  const [hover, setHover] = useState(0);
  const [inputs, setInputs] = useState({});
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const captchaRef = useRef(null);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const resetForm = () => {
    setInputs({ first_name: "", last_name: "", email: "",comments: "",rating: "5", type: "",promote: "" });
  };

  const toggleChange = (event) => {
    setInputs(values => ({...values, 'promote': event.target.checked ? 'Yes': 'No' }))
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    if(!token){
      alert('Please click on the reCAPTCHA box.');
    }else{
      captchaRef.current.reset();
      const dataToSubmit = {
        ...inputs // Any additional form data object here
      };
      dataToSubmit.grecaptcharesponse = token;
      const headers = {
        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3d3dy50cmF2ZWxhbmNlLmNhIiwiaWF0IjoxNzEyODA0MTYxLCJuYmYiOjE3MTI4MDQxNjEsImV4cCI6MTcxMzQwODk2MSwiZGF0YSI6eyJ1c2VyIjp7ImlkIjoiMyJ9fX0.Ah6c7zDQBOuVF-it8qSEn5aUgJ3JCN8IaMdvHOPrf8c'
      };
      let url = `${process.env.REACT_APP_API_ROOT}addReview`;
      trackPromise(
        axios.post(url,dataToSubmit,headers).then((res) => {
          const { data } = res;
          setMessage(data.message);
          setMessageType(data.status);
          resetForm();
        })
      );
    }
  }

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_ROOT}wp_review`;
    trackPromise(
      axios.get(url).then((res) => {
        const { data } = res;
        setReviews(data);
      })
    );
  }, []);

  return (
    <>
      <Header en='/contact-us/reviews' fr='/fr' lang='en' />
      <Container className="mainDiv reviewPage">
        <Row className="">
          <Col xl={12} className="content">
            <Container>
              <Row>
                <Col xl={8}>
                  <p>Travelance appreciates reviews. Let us know how we did using the form below. Or, review Travelance on our Google Reviews page.</p>
                  <h2>Leave a Review</h2>
                  <Form method="post" id="reviewForm" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Row>
                      <Col xl={6}>
                        <Form.Group className="form-group">
                          <Form.Label>First Name <sup>*</sup></Form.Label>
                          <Form.Control type="text" name="first_name" className="" placeholder="John" required="required" value={inputs.first_name || ""} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                      <Col xl={6}>
                        <Form.Group className="form-group">
                          <Form.Label>Last Name <sup>*</sup></Form.Label>
                          <Form.Control type="text" name="last_name" className="" placeholder="Smith" required="required" value={inputs.last_name || ""} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                      <Col xl={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Email <sup>*</sup></Form.Label>
                          <Form.Control type="email" name="email" className="" placeholder="John@example.com" required="required" value={inputs.email || ""} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                      <Col xl={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Comments <sup>*</sup></Form.Label>
                          <Form.Control as="textarea" className="" name="comments" rows="5" placeholder="Comments / Feedback / Query" required="required" value={inputs.comments || ""} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                      <Col xl={12}>
                        <Form.Group className="form-group rating">
                          <Form.Label>Rating <sup>*</sup></Form.Label>
                          <div className="star-rating">
                            {[...Array(5)].map((star, index) => {
                              index += 1;
                              return (
                                <button
                                  type="button"
                                  key={index}
                                  className={index === 1 ? "on" : index <= (hover || rating) ? "on" : "off"}
                                  onClick={() => {setRating(index);$('#rating').val(index);setInputs(values => ({...values, 'rating': index}))}}
                                  onMouseEnter={() => setHover(index)}
                                  onMouseLeave={() => setHover(rating)}
                                >
                                  <span className="star">&#9733;</span>
                                </button>
                              );
                            })}
                          </div>
                          <Form.Control type="hidden" name="rating" id="rating" value={rating} />
                        </Form.Group>
                      </Col>
                      <Col xl={12}>
                        <Form.Group className="form-group">
                          <ul>
                            <li><Form.Check type="radio" name="type" id="broker" htmlFor="broker" value="I am a Broker" label="I am a Broker" onChange={handleChange}/></li>
                            <li><Form.Check type="radio" name="type" id="client" htmlFor="client"  value="I am a Client" label="I am a Client" onChange={handleChange}/></li>
                            <li><Form.Check type="radio" name="type" id="other" htmlFor="other"  value="Other" label="Other" onChange={handleChange}/></li>
                          </ul>
                        </Form.Group>
                      </Col>
                      <Col xl={12}>
                        <Form.Group className="form-group">
                          <ul>
                            <li><Form.Check  type="checkbox" name="promote" id="promote" htmlFor="promote" label="My review may be used to promote Travelance."  value="yes" onChange={toggleChange} /></li>
                          </ul>
                        </Form.Group>
                      </Col>
                      <Col xl={12}>
                      <ReCAPTCHA sitekey="6Leqq-kUAAAAAH5ekaYckwmGLXuDcDp9U2X0ipip" ref={captchaRef} />
                      </Col>
                      <Col xl={12}>
                        <Button variant="primary" name="btnSubmit" type="submit">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  {message && (
                    <div className={messageType === "success" ? "success-message" : "error-message"}>
                      {message}
                    </div>
                  )}
                </Col>
                <Col xl={4}>
                  <aside className="right-panel">
                    <h2>Recent Reviews</h2>
                    {
                      reviews && reviews.map((review,i)=>{
                        return(
                          <div className="single-comment" key={i}>
                            <p>{review.comments}</p>
                            <strong>– {review.first_name} {review.last_name} </strong>
                          </div>
                        )
                      })
                    }
                  </aside>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer lang="en" />
    </>
  );
}

export default ReviewPage;
