import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const ProductsTripsT = (props) => {
  return (
    <>
    <Header en={GLobalLinks["products/trips/travel"]['en']} fr={GLobalLinks["products/trips/travel"]['fr']} lang={GLobalLinks["products/trips/travel"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="tripsTravels">Travel Insurance Products</h1>
          <div>
            <div className="bannerInner header_travel"></div>
            <p>This product suite offers coverage for a variety of travellers with a valid Canadian government or university health insurance plan (GHIP/UHIP). Whether novice or experience, occasional or frequent, family or youth, you’ll find a plan to suit most travellers’ needs.</p>
            <div className="row">
              <div className="col-lg-8">
                <table className="tablestyle" width="100%" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td>
                        <h2>All Inclusive Plan</h2>
                      </td>
                      <td>
                        <h2>All Inclusive Canada</h2>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul className="list">
                          <li>Trip cancellation and interruption benefits</li>
                          <li>Trip delay up to $1,500</li>
                          <li><span style={{fontWeight: '400'}}>$10M emergency medical</span></li>
                          <li>$10M emergency medical evacuation/return home</li>
                          <li><span style={{fontWeight: '400'}}>$4,000 accidental dental</span></li>
                          <li>Baggage and personal effects up to $1,500</li>
                          <li>Travel accident up to $100,000</li>
                          <li>Worldwide destinations</li>
                          <li>24/7 travel assistance</li>
                        </ul>
                        <a href="https://travelance.box.com/v/trips-travel-policy" target="_blank" rel="noopener noreferrer">Click to review the policy.</a>
                      </td>
                      <td>
                        <ul className="list">
                          <li>For travel within Canada only</li>
                          <li>Trip cancellation and interruption benefits</li>
                          <li>Trip delay up to $1,500</li>
                          <li><span style={{fontWeight: 400}}>$10M emergency medical</span></li>
                          <li>$10M emergency medical evacuation/return home</li>
                          <li><span style={{fontWeight: 400}}>$4,000 accidental dental</span></li>
                          <li>Baggage and personal effects up to $1,500</li>
                          <li>Travel accident up to $100,000</li>
                          <li>24/7 travel assistance</li>
                        </ul>
                        <a href="https://travelance.box.com/v/all-inclusive-canada-policy" target="_blank" rel="noopener noreferrer">Click to review the policy.</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="tablestyle" width="100%" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td>
                        <h2>Single Trip Plans</h2>
                      </td>
                      <td>
                        <h2>Multi-Trip Option</h2>
                      </td>
                    </tr>
                    <tr>
                      <td>These are your go-to plans for the occasional travellers! Our single trip plans offer affordable way to protect you and your family/friends when going abroad. Choose from a variety of single trip plan options:
                        <ul className="list">
                          <li>Comprehensive packages</li>
                          <li>Stand-alone benefits (e.g. emergency medical or trip cancellation/interruption)*</li>
                        </ul>
                        <span style={{fontSize: '12px'}}>*stand-alone plans can be used to top up another plan.</span>
                      </td>
                      <td>We built these multi-trip options for frequent travellers. Anyone that has to travel frequently for work or vacation will definitely find these plans to be essential. Choose from a variety of multi-trip options:
                        <ul className="list">
                          <li>Annual Emergency Medical Option or an All-Inclusive Annual</li>
                          <li>Choose from 15 or 30 day plans options</li>
                          <li>Eligible for top-up coverage</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="tablestyle" width="100%" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td>
                        <h2>All Inclusive Annual Option</h2>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul className="list">
                          <li>Comprehensive coverage for one full year</li>
                          <li>Choose between a 15-day and a 30-day plan</li>
                          <li>Top-up coverage available for the amount over and above the maximums</li>
                          <li>Trip cancellation and interruption benefits (up to $1,500, $2,500 or $5,000 per trip depending on the plan chosen)</li>
                          <li>Trip delay up to $1,500</li>
                          <li>$10M emergency medical</li>
                          <li>$10M emergency medical evacuation/return home</li>
                          <li>Baggage and personal effects $1,500</li>
                          <li>Travel accident up to $100,000</li>
                          <li>24/7 travel assistance</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="tablestyle" width="100%" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td>
                        <h2>Emergency Medical Insurance Plan</h2>
                      </td>
                      <td>
                        <h2>Annual Emergency Medical Insurance Option</h2>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul className="list">
                          <li>$10M emergency medical coverage</li>
                          <li>$10M emergency medical evacuation/return home</li>
                          <li>$4,000 accidental dental</li>
                          <li>Worldwide destinations</li>
                          <li>24/7 travel assistance</li>
                        </ul>
                        <a href="https://travelance.box.com/v/emergency-medical-policy-trips" target="_blank" rel="noopener noreferrer">Click to review the policy.</a>
                      </td>
                      <td>
                        <ul className="list">
                          <li>Emergency medical insurance for one full year</li>
                          <li>Choose between a 15 or a 30 day plan</li>
                          <li>Top-up coverage available for the amount over and above the maximums</li>
                          <li>Unlimited emergency medical</li>
                          <li>$4,000 accidental dental</li>
                          <li>Worldwide destinations</li>
                          <li>24/7 travel assistance</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="tablestyle" width="100%" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td>
                        <h2>Trip Cancellation and Interruption Plan</h2>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul className="list" style={{display:'grid'}}>
                          <li>Trip cancellation and interruption benefits</li>
                          <li>Trip delay up to $1,000</li>
                          <li>24/7 travel assistance</li>
                        </ul>
                        <a href="https://travelance.box.com/v/interruption-policy-trips" target="_blank" rel="noopener noreferrer">Click to review the policy.</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>This is a brief description of coverage. Please see policy wording for conditions, definitions, exclusions, limitations, terms and conditions. *Please make sure the plan you are topping up allows for top-ups.</p>
                <em>Underwritten by Old Republic Insurance Company of Canada.</em>
              </div>
              <div className="col-lg-4">
                <div className="stickyDiv">
                  <div className="holder_block">
                    <a href="https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&returnurl=step1.aspx" target="_blank" rel="noopener noreferrer">
                      <button style={{fontSize: '15px',textAlign: 'right',display: 'inline-block',marginLeft: '25%',background: '#cc4037',border: '1px solid #cc4037',boxShadow: '0px 3px 0px #a72720',borderRadius: '3px',padding: '8px 12px',color: '#fff',cursor: 'pointer'}} >GET A FREE QUOTE</button>
                    </a>
                  </div>
                  <div className="holder_block">
                    <h3><a href="https://travelance.app.box.com/v/purchase-trips-plans" target="_blank" rel="noopener noreferrer">How to Quote or Purchase a TRIPs Plan from our Website</a></h3>
                  </div>
                  <div className="holder_block">
                    <h2>All Products</h2>
                    <ul className="list">
                      <li><a href="/products/trips/travel/">Travel</a></li>
                      <li><a href="/products/trips/youth/">Youth</a></li>
                      <li><a href="/products/visitors-canada-insurance/">Visitors to Canada Emergency Medical Insurance</a></li>
                      <li><a href="/products/student-accident-plan/">Student Accident Plan</a></li>
                      <li><a href="/products/international-student/">International Student Plan</a></li>
                    </ul>
                  </div>
                  <div className="holder_block">
                    <h2>Quick Links</h2>
                    <ul className="list">
                      <li><a href="https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&amp;returnurl=step1.aspx">Get a Quote</a></li>
                      <li><a href="/#faqSection">FAQs</a></li>
                      <li><a href="/about-our-underwriter/">Our Underwriter</a></li>
                      <li><a href="/claims/">Claims</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["products/trips/travel"]['lang']} />
    </>
  );
}

export default ProductsTripsT;
