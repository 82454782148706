import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const ThankYou = (props) => {
  return (
    <>
    <Header en={GLobalLinks["partner/form/thank-you"]['en']} fr={GLobalLinks["partner/form/thank-you"]['fr']} lang={GLobalLinks["partner/form/thank-you"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="thank-you">Thank You</h1>
          <div>
            <div className="row">
              <div className="bannerInner header-banner-WFG-partner"></div>
              <div className="col-lg-8">
                <p>Thank you for your interest in partnering with us. Your request has been sent.<br />
                You will receive an email in two business days inviting you to create an account and continue the sign-up process electronically.</p>
              </div>
              <div className="col-lg-4">
                <div className="stickyDiv">
                  <div className="holder_block">
                    <h2>WFG Partners</h2>
                    <ul className="list">
                      <li><a href="/partner/resources/">Travelance Resources</a></li>
                      <li><a className="scrollLink" href="#travelance-contact-info">Contact Information</a></li>
                    </ul>
                  </div>
                  <div className="holder_block">
                    <h2>Quick Links</h2>
                    <ul className="list">
                      <li><a href="/claims/">Claims</a></li>
                      <li><a href="https://www.getreliable.com/getreliable/">Online Portal Login</a></li>
                      <li><a href="/about-our-underwriter/">Our Underwriter</a></li>
                      <li><a href="/partner/faq-agents/">Partner FAQs</a></li>
                      <li><a href="/partner/online-training-sessions/">Register for Online Training</a></li>
                      <li><a href="https://www.travel.gc.ca/travelling/advisories/">Travel Advisories</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["partner/form/thank-you"]['lang']} />
    </>
  );
}

export default ThankYou;
