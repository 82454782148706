import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const PrivacyPolicy = (props) => {
  return (
    <>
    <Header en={GLobalLinks["privacy-policy"]['en']} fr={GLobalLinks["privacy-policy"]['fr']} lang={GLobalLinks["privacy-policy"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="privacy-policy">Privacy Policy</h1>
          <div>
            <h5>Travelance Online Privacy Policy V1.1</h5>
            <p>We collect information from you when you register on our website, place an order, subscribe to our newsletter, respond to a survey or fill out a form.</p>
            <p>When ordering or registering on our site, as appropriate, you may be asked to enter your name, e-mail address, phone number, credit card information, or other personal identifying information.</p>
            <h5>What do we use your information for?</h5>
            <p>Any of the information we collect from you may be used in the following ways:</p>
            <ol type="a" >
              <li>To personalize your experience (your information helps us to better respond to your individual needs)</li>
              <li>To improve our website (we continually strive to improve our website based on the information and feedback we receive from you)</li>
              <li>To improve customer service (your information helps us to effectively respond to your customer service requests and support needs)</li>
              <li>To process transactions
                <br />
                <blockquote>
                  <i>Your information, whether public or private, will not be sold, exchanged, transferred, or given to any unauthorized third party for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.</i>
                </blockquote>
              </li>
              <li>To administer a contest, promotion, survey or other site feature</li>
              <li>To send periodic emails
                <br />
                <blockquote><i>The email address you provide for order processing may be used to send you information and updates pertaining to your order, in addition to receiving company communications.&nbsp; </i></blockquote>
              </li>
            </ol>
            <p>Our e-mail marketing is permission based. If you received an email from us, our records indicate that (a) you have expressly provided your e-mail address for the purpose of receiving information in the future (“opt-in”), or (b) you have registered or purchased or otherwise have an existing relationship with us.</p>
            <p>Note: If at any time you would like to unsubscribe from receiving future commercial electronic messages (CEM), we include detailed unsubscribe instructions at the bottom of each email.</p>
            <h5>How do we protect your information?</h5>
            <p>We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information.
            <br />
            <br />
            We utilize a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers.</p>
            <h5>Do we use cookies?</h5>
            <p>Yes, Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the site’s or service provider’s systems to recognize your browser and capture and remember certain information. We use cookies to understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. We may contract with third-party service providers to assist us in better understanding our site visitors. These service providers are not permitted to use the information collected on our behalf except to help us conduct and improve our business.</p>
            <h5>Do we disclose any information to outside parties?</h5>
            <p>Other than disclosing information to insurers and payment processors to the extent needed to provide our services, we do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses. Because we value your privacy we have taken the necessary precautions to be in compliance with Canada’s Personal Information Protection and Electronic Documents Act. We therefore will not distribute your personal information to outside parties without your consent.</p>
            <h5>Scope</h5>
            <p>This privacy policy applies to information collected by any means, including customer information which is provided to us from our brokers and sales representatives.</p>
            <h5>Terms and Conditions</h5>
            <p>Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website at: <a href="/terms-conditions/">Terms and Conditions</a>.</p>
            <h5>Changes to our Privacy Policy</h5>
            <p>If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy modification date below. This policy was last modified on Apr 02, 2021.</p>
            <h5>Contacting Us</h5>
            <p>If you have any questions regarding this privacy policy, please contact us using this form: <a title="Contact Us" href="/contact-us/">www.travelance.ca/contact/</a>.</p>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["privacy-policy"]['lang']} />
    </>
  );
}

export default PrivacyPolicy;
