import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";
import $ from 'jquery';
import PolicyInfo from "../../../../assets/images/Page-1.webp"

const ProductsTripsYouth = (props) => {
  $(document).ready(function(){
    $('.mainDiv .nav-link').on('click',function(e){
      e.preventDefault();
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      var id = $(this).attr('href');
      $('.tab-pane').removeClass('active');
      $(id).addClass('active');
    });
    $('.accordion-header button').on('click',function(e){
      e.preventDefault();
      $('.accordion-header button').not(this).addClass('collapsed');
      $(this).toggleClass('collapsed');
      var id = $(this).attr('data-target');
      console.log(id);
      console.log($('.collapse').not(id));
      $('.collapse').not(id).removeClass('show');
      //$('.collapse').not(id).css('border-color','red');
      $(id).toggleClass('show');
    });
  });
  return (
    <>
    <Header en={GLobalLinks["products/student-accident-plan"]['en']} fr={GLobalLinks["products/student-accident-plan"]['fr']} lang={GLobalLinks["products/student-accident-plan"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="sap">Student Accident Plan (SAP)</h1>
          <div>
            <div className="bannerInner header_student-accident-plan"></div>
            <div className="row">
                <div className="col-lg-8">
                    <div className="row">
                        <div className="col-lg-12 content">
                            <ul className="nav nav-tabs" role="tablist">
                                <li style={{listStyleType: 'none'}}>
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a id="howdoesitwork-tab" className="nav-link active" role="tab" href="#howdoesitwork" data-toggle="tab" aria-controls="howdoesitwork" aria-selected="true"><strong>How Does it Work?</strong></a>
                                        </li>
                                        <li className="nav-item">
                                            <a id="benefits-tab" className="nav-link" role="tab" href="#benefits" data-toggle="tab" aria-controls="benefits" aria-selected="false"><strong>Plan Benefits</strong></a>
                                        </li>
                                        <li className="nav-item">
                                            <a id="policyinfo-tab" className="nav-link" role="tab" href="#policyinfo" data-toggle="tab" aria-controls="policyinfo" aria-selected="false"><strong>Policy Info</strong></a>
                                        </li>
                                        <li className="nav-item">
                                            <a id="getaquote-tab" className="nav-link" role="tab" href="#getaquote" data-toggle="tab" aria-controls="getaquote" aria-selected="false"><strong>Get a Quote</strong></a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 content">
                            <div className="tab-content">
                                <div id="howdoesitwork" className="tab-pane active" role="tabpanel" aria-labelledby="essentialplan-tab">
                                    <h2>Why do I need insurance for my child?</h2>
                                    <p>Did you know that the expenses from most physical accidents exceed the benefits provided by the government or group insurance plans? The most minor accidents can result in major financial responsibilities and some
                                    expenses may not even be insured under your government or group insurance policies. The result - the parent/guardian become financially responsible. To help identify the advantages of a Student Accident Plan, take a
                                    moment to review the following scenarios covered by the Student Accident Plan. These stories are real incidents where a Student Accident Plan made a large impact on each family. They could have had severe financial
                                    strain; instead, these families were protected by Travelance’s Student Accident Plan (SAP).</p>
                                    <strong>What kind of difference could the Student Accident Plan make?</strong>
                                    <h3>Snowboarding Fall</h3>
                                    <p>A 17-year-old girl was snowboarding. She fell and fractured her wrist and ankle.</p>
                                    <div className="container">
                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>&nbsp;</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>With Student Accident Plan</h3>
                                            </div>
                                            <div className="col-md-4">
                                                <h3>Without Student Accident Plan</h3>
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Annual Premium</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $49.99
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $0
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Ambulance</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $275
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Cast for Arm</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $45
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Cast for Leg</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $80
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Prescriptions</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $29
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Tutoring</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $3,150
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Physiotherapy</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $945
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>TV Rental</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $30
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Total Out of Pocket Expenses</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $49.99
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $4,554
                                            </div>
                                        </div>
                                    </div>

                                    <h3>Hockey Cracked Tooth</h3>
                                    <p>A 6-year-old boy was playing floor hockey in gym class. The plastic puck hit him in the face, cracked a tooth, and split his lip.</p>

                                    <div className="container">
                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>&nbsp;</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>With Student Accident Plan</h3>
                                            </div>
                                            <div className="col-md-4">
                                                <h3>Without Student Accident Plan</h3>
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Annual Premium</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $49.99
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $0
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Crown Work</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $1,189
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Lab Fees</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $294
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Fractured Jaw</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $250
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Ambulance</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $45
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Expense Reimbursement</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $180
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Total Out of Pocket Expenses</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $49.99
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $1,958
                                            </div>
                                        </div>
                                    </div>

                                    <h3>US Baseball Tournament</h3>
                                    <p>A 13-year-old boy was in a US playing in a baseball tournament. One of the other players ran into him and he tore the muscles in his leg.</p>
                                    <div className="container">
                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>&nbsp;</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>With Student Accident Plan</h3>
                                            </div>
                                            <div className="col-md-4">
                                                <h3>Without Student Accident Plan</h3>
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Annual Premium</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $49.99
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $0
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Hospital Room</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $1,390
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Pharmacy</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $1,167
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>IV Solutions</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $116
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Medical-Surgical Supplies</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $6,133
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Diagnostic Radiology</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $583
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Operating Room Services</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $3,909
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Anesthesia</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $1,144
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Emergency Room</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $1,186
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid', borderBottom: '0px'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Recovery Room</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $0
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $1,198
                                            </div>
                                        </div>

                                        <div className="row" style={{border: '1px solid'}}>
                                            <div className="col-md-4" style={{borderRight: '1px solid'}}>
                                                <h3>Total Out of Pocket Expenses</h3>
                                            </div>
                                            <div className="col-md-4" style={{borderRight: '1px solid', padding: '10px'}}>
                                                $49.99
                                            </div>
                                            <div className="col-md-4" style={{padding: '10px'}}>
                                                $16,826
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="benefits" className="tab-pane" role="tabpanel" aria-labelledby="benefits-tab">
                                    <h2>Plan Benefits</h2>
                                    <p>The Student Accident Plan is an inexpensive and comprehensive way to protect families from financial hardships they may face as a result of an accident. Each year, thousands of families are left unprotected when their child is injured at school or while participating in non-school activities. With over 20 benefits, the Student Accident Plan provides financial protection for expenses not normally covered under government health or group insurance plans. Peace of mind is affordable with a Student Accident Plan. Here are just some of the many benefits Travelance provides for less than a dollar a week:</p>
                                    <h2>Total &amp; Permanent Disability – $350,000</h2>
                                    <p>This benefit provides compensation for an injury sustained as a result of a physical accident, which causes total and permanent disability.</p>
                                    <h2>Dental Accident Coverage - Unlimited expenses paid for in the first 10 years of injury for individuals 18 years of age or younger (1 year for ages 19 – 25)</h2>
                                    <p>This benefit can provide coverage for a variety of dental injuries. For example, if your child were to crack a tooth, this benefit could cover the reasonable and necessary expenses for its repair and treatment by a licensed Dentist. Coverage is available for up to 10 years following a dental injury due to an accident (1 year for individuals 19 – 25 years of age). If further treatment is needed after this timeframe, coverage is available up to a limit of $1,500 per tooth.</p>
                                    <h2>Tutoring Benefit – Up to $6,000</h2>
                                    <p>If as a result of an accident, your child is confined to his or her residence for over 30 days, this benefit can cover expenses for tutorial services provided by a qualified teacher. This benefit can also pay for the rental of necessary equipment and required program software.</p>
                                    <h2>Prosthetic Appliances – Up to $5,500</h2>
                                    <p>This benefit can cover the necessary costs for artificial limbs, eyes and/or hearing aids (up to a maximum of $5,500) for each injury that results in a loss requiring such an appliance.</p>
                                    <h2>Out-of-Province/Country Emergency Medical – Up to $100,000</h2>
                                    <p>If your child sustains an injury while travelling outside of their province or territory of residence, this benefit provides coverage for required emergency treatment, including the services of a physician, hospital, x-ray clinic, ground ambulance, necessary and reasonable alternative ambulance service up to $1,000, and/or a registered nurse (other than a family member). This benefit may also cover the costs for crutches, braces, splints, trusses or other prosthetic appliances, emergency medicine, blood, and/or plasma, and the rental costs for a wheelchair and/or hospital-type bed. Coverage is for any number of trips up to 30 days in duration. This is a brief description of coverage. Please see policy wording for conditions, definitions, exclusions, limitations, terms, and conditions.</p>
                                </div>
                                <div id="faq" className="tab-pane" role="tabpanel" aria-labelledby="faq-tab">
                                    <h2>FAQ</h2>
                                    <p><strong>Why do I need this insurance for my child?</strong><br />
                                    The affordable Student Accident Plan protects your family financially against the possible expenses that could be incurred due to your child’s physical accident.<br /><br />

                                    <strong>If I have a group plan through my employer, what is the benefit of purchasing this insurance?</strong><br />
                                    Some plans may not provide coverage for all expenses incurred. The Student Accident Plan will help to provide coverage beyond what your government health and group insurance plans would cover.<br /><br />

                                    <strong>Will this insurance provide benefits for my child when they are on school field trips, summer holidays, or participating in recreational activities?</strong><br />
                                    Yes, this plan is available 24 hours per day, every day, during the term of the policy.<br /><br />

                                    <strong>Will this insurance provide benefits for my child while out of the province?</strong><br />
                                    The Student Accident Plan provides accident coverage outside the insured’s province or territory of residence for trips up to 30 days in duration. The coverage for emergency medical is limited to $100,000 and includes emergency medical expense coverage for sickness.<br /><br />

                                    <strong>Who is eligible?</strong><br />
                                    A person who resides in Canada, excluding the province of Quebec, and is: a. over 6 months of age and under 14 years of age on the date of purchase; or b. over 13 years of age and under 26 years of age on the date of purchase and who is a full-time student, which we define as being enrolled in a minimum of 3 concurrent courses.<br /><br />

                                    <strong>My child is attending a private school. Can he/she be insured through this plan?</strong><br />
                                    Yes, provided your child is between 6 months and 14 years of age or a student over 13 years of age and under 26 years of age who is a full-time student (minimum of 3 concurrent courses).<br /><br />

                                    <strong>My children are homeschooled. Are they eligible for this insurance?</strong><br />
                                    Yes, if your children are between 6 months and 14 years of age. If your children are over 13 years of age and under 26 years of age, they must be full-time students (minimum of 3 concurrent courses).<br /><br />

                                    <strong>Is there an age limit for the insurance?</strong><br />
                                    Yes, coverage under this policy is available for eligible students under 26 years of age. See ‘Who is eligible?’ above.<br /><br />

                                    <strong>Are University/College students eligible for this insurance?</strong><br />
                                    Yes, eligible students attending a Canadian University/College may purchase a Student Accident Plan. See ‘Who is eligible?’ above.<br /><br />

                                    <strong>Can my child who is attending University/College be enrolled on the same application as my other children?</strong><br />
                                    Yes.<br /><br />

                                    <strong>My children are with two different school boards, one is private and the other is public. Can I insure both of them on the same form?</strong><br />
                                    Yes, only one application is needed.<br /><br />

                                    <strong>Is the premium a monthly or annual rate?</strong><br />
                                    All premiums are paid in full, annually.<br /><br />

                                    <strong>When will this insurance take effect?</strong><br />
                                    Coverage under this policy begins on the date and time the application and the required premium are received by Old Republic Insurance Company of Canada or its authorized representative.<br /><br />

                                    <strong>When will my policy terminate?</strong><br />
                                    On the expiration date shown on the Confirmation of Coverage or the date the insured ceases to be a full-time student (enrollment in a minimum of 3 concurrent courses), whichever happens first.<br /><br />

                                    <strong>Will I receive a policy or confirmation of insurance?</strong><br />
                                    Yes. When purchasing the Student Accident Plan through a licensed Travelance broker or representative, you will be asked to provide your email address so the confirmation of coverage can be emailed to you
                                    immediately. You may request a printed version to be mailed to your home address.<br /><br />

                                    <strong>The school is requesting proof of insurance. Can you provide me with confirmation?</strong><br />
                                    Email confirmations are provided immediately if you provide your email address during an in-person or over the phone sale. Upon request, confirmations can also be mailed to your home address.<br /><br />

                                    <strong>Even if we move, will my child still be insured?</strong><br />
                                    Yes, as long as your child continues to reside in Canada (excluding Quebec) and remains a full-time attending student (if they are over the age of 13 and under the age of 26). However, it is important that you contact your broker or Travelance directly with the new address.<br /><br />

                                    <strong>How long has your company been providing this type of insurance?</strong><br />
                                    Old Republic Insurance Company of Canada has been providing this insurance for over 50 years.<br /><br />

                                    <strong>How can I apply?</strong><br />
                                    Contact a Travelance licensed insurance broker, or call 1-855-566-8555 to speak with a Travelance representative.</p>
                                </div>
                                <div id="policyinfo" className="tab-pane" role="tabpanel" aria-labelledby="policyinfo-tab">
                                    <h2>Policy Information</h2>
                                    <img className="alignnone size-medium wp-image-199 img-style" title="Travelance-Assurance-Accident-étudiant" src={PolicyInfo} alt="Travelance-Assurance-Accident-étudiant" width="256" height="300" />
                                    <p>Student Accident Policy 2016/2017

                                    <a className="btn-1" href="https://travelance.app.box.com/v/sap-policy" target="_blank" rel="noopener noreferrer">Download Policy Details.</a> (Adobe Acrobat Reader is required. Click here to download.)</p>
                                </div>
                                <div id="getaquote" className="tab-pane" role="tabpanel" aria-labelledby="getaquote-tab">
                                    <h2>Get a Quote</h2>
                                    <p>Student Accident Plan is $49.99 for 365 days of 24/7 coverage, as long as the student continues to meet the eligibility requirements during the period of coverage. That is less than a dollar a week for this comprehensive coverage! To get a quote, or to purchase a plan, contact a Travelance licensed broker or call Travelance 1-855-566-8555 to speak with a representative. You can also choose to submit your contact information <a href="/contact-us/">here</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <em>Underwritten by Old Republic Insurance Company of Canada. Not available in Quebec.</em>
                </div>
                <div className="col-lg-4">
                    <div className="stickyDiv">
                        <div className="holder_block">
                            <h2>All Products</h2>
                            <ul className="list">
                                <li><a href="/products/trips/travel/">Travel</a></li>
                                <li><a href="/products/trips/youth/">Youth</a></li>
                                <li><a href="/products/visitors-canada-insurance/">Visitors to Canada Emergency Medical Insurance</a></li>
                                <li><a href="/products/student-accident-plan/">Student Accident Plan</a></li>
                                <li><a href="/products/international-student/">International Student Plan</a></li>
                            </ul>
                        </div>
                        <div className="holder_block">
                            <h2>Quick Links</h2>
                            <ul className="list">
                                <li><a href="https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&amp;returnurl=step1.aspx">Get a Quote</a></li>
                                <li><a href="/#faqSection">FAQs</a></li>
                                <li><a href="/about-our-underwriter/">Our Underwriter</a></li>
                                <li><a href="/claims/">Claims</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["products/student-accident-plan"]['lang']} />
    </>
  );
}

export default ProductsTripsYouth;
