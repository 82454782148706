import React from 'react';
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';
import { usePromiseTracker } from "react-promise-tracker";
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './index.css';
import App from './App';
import {ThreeDots} from 'react-loader-spinner';
//import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
//import './fonts/LuciaSansUnicode/LuciaSansUnicode.ttf';



const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
  promiseInProgress &&
    <div style={{width: "100vw",height: "100vh",display: "flex",justifyContent: "center",alignItems: "center",zIndex:999,position:"fixed",top:0,left:0,background:"#fffffff0"}}>
      <ThreeDots type="ThreeDots" color="#cc4037" height="100" width="100" />
    </div>
  );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
const store = createStore({
  authName:'_auth',
  authType:'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'https:',
});
root.render(
  <AuthProvider store={store}>
    <Router>
      <App />
      <LoadingIndicator/>
    </Router>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
