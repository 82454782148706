import React from "react";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";
import Girl from "../../../../assets/images/girl-with-books.webp"
import Boy from "../../../../assets/images/boy-with-books.webp"
import Logo from "../../../../assets/images/logo.svg"

const ProductsISPP = (props) => {
  return (
    <>
      <div className="container mainDiv Landing">
        <div className="row">
          <div className="col-xs-12 content">
            <div>
              <div className="container">
              <div className="row">
                  <div className="col-lg-7">
                      <a href="/"><img className="logo" width="306" src={Logo} alt="Travelance" /></a>
                      <h1 className="hdr" style={{fontWeight: 'bold', borderStyle: 'none', color: '#485679'}}>Studying in Canada? <span className="hdr" style={{fontWeight: 'bold', borderStyle: 'none', color: '#cf4038'}}>Don't Forget</span> Insurance</h1>
                      <p className="emg" style={{fontWeight: 'bold', borderStyle: 'none', color: '#86899a'}}>Emergency Medical Insurance for International Students in Canada</p>
                      <a href="https://quote.travelance.ca/#/ISP" target="_blank" rel="noopener noreferrer">
                      <button className="button" style={{backgroundColor: '#cc4037',border: 'none',color: 'white',padding: '15px 50px',textAlign: 'center',textDecoration: 'none',display: 'inline-block',fontSize: '19px',margin: '0px 0px -20px 0px',cursor: 'pointer',borderRadius: '50px'}}>
                              Get a Quote
                          </button>
                      </a>
                      <p style={{fontSize: '14px', borderStyle: 'none', color: '#cf4038'}}>Click for a free quote in a minutes.</p>
                  </div>
                  <div className="col-lg-5"><img src={Girl} style={{width:'100%',marginBottom:'25px'}} alt="ISP"/></div>
              </div>
              <div className="row reverse">
                  <div className="col-lg-6"><img style={{marginTop: '-20px',  width:'100%'}} src={Boy}  alt="ISP"/></div>
                  <div className="col-lg-6">
                      <ul className="ab" style={{backgroundColor: '#fbedec', paddingTop: '30px', paddingBottom: '30px', paddingRight: '30px',  borderRadius: '20px'}}>
                          <li>$2,000,000 Emergency Medical</li>
                          <li>18 Benefits</li>
                          <li>$0 Base Deductible</li>
                          <li>Students may not be covered under Canada's universal healthcare system, meaning any medical emergency would be paid out-of-pocket.</li>
                          <li>Available for students coming to study at a Canadian school, including their eligible spouses and dependent children.</li>
                          <li>
                              Two Plans Available:
                              <ul className="ab">
                                  <li><em>Smart Plan</em> - a great option for those in good health, not taking any medication, with no pre-existing conditions.</li>
                                  <li><em>Smart-Plus Plan</em> - this option provides some coverage for pre-existing conditions that have been stable for more than 90 days (conditions apply).</li>
                              </ul>
                          </li>
                          <li>Affordable Rates</li>
                          <li><a href="/products/international-student/" target="_blank" rel="noopener noreferrer">Click Here</a> to View a List of Benefits</li>
                      </ul>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-12">
                      <div className="row rates-box-main">
                          <div className="col-lg-3"></div>
                          <div className="col-lg-3 rates-box">
                              <div className="rates-box-inner">
                                  <p className="title">Smart Plan</p>
                                  <p className="sub-title"><span className="ratesTitle">Daily Rate</span><span className="ratesRates">$2.09</span><span className="ratesTitle">Annual Rate</span><span className="ratesRates">$762.85</span></p>
                              </div>
                          </div>
                          <div className="col-lg-3 rates-box">
                              <div className="rates-box-inner">
                                  <p className="title">Smart-Plan Plan</p>
                                  <p className="sub-title"><span className="ratesTitle">Daily Rate</span><span className="ratesRates">$2.37</span><span className="ratesTitle">Annual Rate</span><span className="ratesRates">$865.05</span></p>
                              </div>
                          </div>
                          <div className="col-lg-3"></div>
                      </div>
                  </div>
              </div>
              <div className="row center">
                  <div className="col-lg-12">
                      <p className="dsc" style={{textAlign: 'center', fontSize: '12px'}}>This is a brief description of coverage. Please see policy wording for complete benefits, details, exclusions, limitations, terms and conditions.</p>
                      <p style={{textAlign: 'center'}}>
                          <a href="https://quote.travelance.ca/#/ISP" target="_blank" rel="noopener noreferrer">
                            <button className="button" style={{backgroundColor: '#cc4037',border: 'none',color: 'white',padding: '15px 50px',textAlign: 'center',textDecoration: 'none',display: 'inlineBlock',fontSize: '19px',margin: '0px 0px -20px 0px',cursor: 'pointer',borderRadius: '50px'}}>Get a Quote</button>
                          </a>
                      </p>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-12" style={{backgroundColor: '#485679',marginTop:'15px'}}>
                      <p className="dsc" style={{textAlign: 'center', color: '#ffffff', paddingTop: '12px'}}>Underwritten by Zurich Insurance Company Ltd (Canadian Branch).</p>
                  </div>
              </div>
          </div>


            </div>
          </div>
        </div>
      </div>
      <Footer lang={GLobalLinks['products/isp']['lang']} />
    </>
  );
}

export default ProductsISPP;
