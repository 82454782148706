import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";
import $ from 'jquery';

const Claims = (props) => {
  $(document).ready(function(){
    $('.mainDiv .nav-link').on('click',function(e){
      e.preventDefault();
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      var id = $(this).attr('href');
      $('.tab-pane').removeClass('active');
      $(id).addClass('active');
    });
  });

  return (
    <>
      <Header en={GLobalLinks["claims"]['en']} fr={GLobalLinks["claims"]['fr']} lang={GLobalLinks["claims"]['lang']} />
      <div className="container mainDiv ab">
        <div className="row">
          <div className="col-xs-12 content">
            <h1 className="claims">Claims</h1>
            <div>
              <p>If you’re ready to submit a claim, <a href="/claims/forms/" target="_blank" rel="noopener noreferrer">download the relevant forms</a>.</p>
              <div className="row">
                <div className="col-lg-12 content">
                  <ul id="myTab" className="nav nav-tabs" role="tablist">
                    <li className="nav-item"><a id="travelmedical-tab" className="nav-link active" role="tab" href="#travelmedical" data-toggle="tab" aria-controls="home" aria-selected="true"><strong>Travel Medical</strong></a></li>
                    <li className="nav-item"><a id="nonmedical-tab" className="nav-link" role="tab" href="#nonmedical" data-toggle="tab" aria-controls="profile" aria-selected="false"><strong>Non-Medical</strong></a></li>
                    <li className="nav-item"><a id="studentaccidental-tab" className="nav-link" role="tab" href="#studentaccidental" data-toggle="tab" aria-controls="messages" aria-selected="false"><strong>Student Accident</strong></a></li>
                    <li className="nav-item"><a id="internationalstudent-tab" className="nav-link" role="tab" href="#internationalstudent" data-toggle="tab" aria-controls="messages" aria-selected="false"><strong>International Student</strong></a></li>
                    <li className="nav-item"><a id="claimsprocess-tab" className="nav-link" role="tab" href="#claimsprocess" data-toggle="tab" aria-controls="settings" aria-selected="false"><strong>Claims Process</strong></a></li>
                    <li className="nav-item"><a id="claimsforms-tab" className="nav-link" role="tab" href="#claimsforms" data-toggle="tab" aria-controls="settings" aria-selected="false"><strong>Claims Forms</strong></a></li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 content">
                  <div className="tab-content">
                    <div id="travelmedical" className="tab-pane active" role="tabpanel" aria-labelledby="travelmedical-tab">
                      <ul className="list">
                        <li><span className="highlight"><strong>For Travel Right Insurance Plans (TRIPs) and Visitors to Canada (VTC) policies only</strong></span></li>
                        <li><span className="highlight">For the Student Accident policies (SAP), see the ‘Student Accident’ tab</span></li>
                        <li><span className="highlight">For International Student Travel Insurance Policies (ISP), see the ‘International Student’ tab</span></li>
                      </ul>
                      <h3>Are you Experiencing a Medical Emergency?</h3>
                      <ul className="list">
                        <li>Call the <strong>Emergency Assistance Provider</strong> prior to admission, or within 24 hours of a life or organ-threatening emergency</li>
                        <li><strong>Emergency Assistance Provider (24/7)</strong>
                          <ul>
                            <li><a href="tel:1-800-334-7787">1-800-334-7787</a> (in Canada &amp; USA)</li>
                            <li><a href="tel:1-905-667-0587">1-905-667-0587</a> (elsewhere operator assisted collect call)</li>
                            <li><a href="tel:1-905-667-0587">1-905-667-0587</a> (direct dial)</li>
                          </ul>
                        </li>
                      </ul>
                      <p>The insured or someone acting on their behalf must authorize the insurance company access to <strong>all relevant medical records</strong>. This includes current documents from the treating facility and those from the home physician(s) for the applicable pre-existing time frame that applies to their policy.</p>
                    </div>
                    <div id="nonmedical" className="tab-pane" role="tabpanel" aria-labelledby="nonmedical-tab">
                      <ul className="list">
                        <li><span className="highlight"><strong>For Travel Right Insurance Plans (TRIPs) policies only
                        </strong></span></li>
                        <li><span className="highlight">For Visitors to Canada (VTC) policies see the ‘Travel Medical’ tab
                        </span></li>
                        <li><span className="highlight">For Student Accident policies (SAP) see the ‘Student Accident’ tab
                        </span></li>
                        <li><span className="highlight">For International Student Travel Insurance Policies (ISP), see the ‘International Student’ tab</span></li>
                      </ul>
                      <h3>Do You Have a Non-Medical Claim (Trip Cancellation)?</h3>
                      <ul className="list">
                        <li>If you have any questions, please contact the Insurance Company’s <strong>Client Services Department</strong>
                          <ul>
                            <li><a href="tel:1-888-526-0111">1-888-526-0111</a></li>
                            <li>M-F 8:30 am – 8:00pm (ET), 5:30 am – 5:00 pm (PT)</li>
                          </ul>
                        </li>
                        <li>To avoid reduced benefits, you must cancel your trip with the travel agent/supplier within 72 hours of the event that caused the cancellation
                          <ul>
                            <li>Claim payment will be limited to the cancellation penalties that were in effect within 72 hours of the event that caused the cancellation</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div id="studentaccidental" className="tab-pane" role="tabpanel" aria-labelledby="studentaccidental-tab">
                      <ul className="list">
                        <li><span className="highlight"><strong>For Student Accident policies (SAP) only</strong></span></li>
                        <li><span className="highlight">For Travel Right Insurance Plans (TRIPs) policies see the ‘Travel Medical’ or ‘Non-Medical’ tab</span></li>
                        <li><span className="highlight">For Visitors to Canada (VTC) policies see the ‘Travel Medical’ tab</span></li>
                        <li><span className="highlight">For International Student Travel Insurance Policies (ISP), see the ‘International Student’ tab</span></li>
                      </ul>
                      <h3>Do you have a Student Accident Claim?</h3>
                      <ul className="list">
                        <li>If you have any questions, please contact <strong>the Insurance Company’s Client Services Department</strong>
                          <ul>
                            <li><a href="tel:1-888-526-0111">1-888-526-0111</a></li>
                            <li>Monday to Friday, 8:30 am – 6:00pm (ET)/ 5:30 am – 3:00 pm (PT)</li>
                          </ul>
                        </li>
                      </ul>
                      <h3>For Student Accident Travel Related Claims:</h3>
                      <ul className="list">
                        <li>If you experience a loss while travelling outside of your province/territory/country, call the <strong>Emergency Assistance Provider (24/7)</strong>
                          <ul>
                            <li><a href="tel:1-800-334-7787">1-800-334-7787</a> (Canada/USA)</li>
                            <li>Elsewhere collect <a href="tel:1-905-667-0587">1-905-667-0587</a></li>
                          </ul>
                        </li>
                      </ul>
                      <p>Note: All services must be ordered or prescribed by the attending Physician and pre-approved by the Company and its emergency medical assistance provider. The Insured or a travelling companion must notify the Company’s 24 hour Emergency Assistance Provider in the event of a loss.</p>
                      <ul className="list">
                        <li>If you need to cancel a trip reservation prior to departure due to Sickness, Injury, or death of the Insured.
                          <ul>
                            <li>To avoid reduced benefits, you must cancel your trip with the travel agent/supplier within 72 hours of the event that caused the cancellation/interruption</li>
                            <li>Claim payment will be limited to the cancellation penalties that were in effect within 72 hours of the event that caused the cancellation</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div id="internationalstudent" className="tab-pane" role="tabpanel" aria-labelledby="internationalstudent-tab">
                      <ul className="list">
                        <li><span className="highlight"><strong>For International Student Travel Insurance Policies (ISP) only</strong></span></li>
                        <li><span className="highlight">For Travel Right Insurance Plans (TRIPs) policies see the ‘Travel Medical’ or ‘Non-Medical’ tabs</span></li>
                        <li><span className="highlight">For Visitors to Canada (VTC) policies see the ‘Travel Medical’ tab</span></li>
                        <li><span className="highlight">For Student Accident policies (SAP) see the ‘Student Accident’ tab</span></li>
                      </ul>
                      <h3>Are you Experiencing a Medical Emergency?</h3>
                      <ul className="list">
                        <li>Call the Zurich Travel Assist before obtaining any treatment, so they may:
                          <ul>
                            <li>confirm your coverage; and</li>
                            <li>provide pre-approval for treatment.</li>
                          </ul>
                        </li>
                      </ul>
                      <h3>Emergency Assistance Provider (24/7)</h3>
                      <ul className="list">
                        <li><a href="tel:1-833-515-3317">1-833-515-3317</a> (toll-fee from Canada or USA)</li>
                        <li><a href="tel:1-416-479-8007">1-416-479-8007</a>(collect worldwide)</li>
                      </ul>
                      <p>The insured or someone on behalf of the insured must call Zurich Travel Assist in a medical emergency prior to treatment or you will have to pay 20% of the eligible medical expenses which would normally be paid under the policy. If it is medically impossible for you to call when the medical emergency happens, please have someone call on your behalf. For other insurance coverage, you must call Zurich Travel Assist within fourty eight (48) hours of the cause of your claim.</p>
                    </div>
                    <div id="claimsprocess" className="tab-pane" role="tabpanel" aria-labelledby="claimsprocess-tab">
                      <h3>How is a Claim Handled?</h3>
                      <p>When you call to report a claim, expect to provide the following information: contact details, policy information, and the nature of the claim.</p>
                      <p>Obtain a claim form(s):</p>
                      <ul className="list">
                        <li>Start a new claim online: <a href="https://www.oldrepubliccanada.com/eClaims/Account/Login?cc=tai&amp;lang=en" target="_blank" rel="noopener noreferrer">Click Here</a></li>
                        <li>Start a new claim on paper: <a href="/claims/forms/">Click Here</a></li>
                        <li>Contact Client Services at <a href="tel:1-888-526-0111">1-888-526-0111</a>, M-F 8:30 am – 8:00pm (ET), 5:30 am – 5:00 pm (PT)</li>
                        <li>Email: <a href="mailto:info@travelance.ca">info@travelance.ca</a></li>
                        <li>Mail: <span className="highlight">100 King St W Suite 1100, Hamilton, ON L8P 1A2</span></li>
                      </ul>
                      <p>Once a claim (including all necessary documentation) is received, the insurance company will begin the review process.</p>
                      <p>During the review process, the insurance company reviews the confirmation of coverage and policy wording and the validity of the claim is assessed.</p>
                      <p>You will be notified of the claim outcome by mail.</p>
                      <h3>Do you have a question about the claims process or the status of your claim?</h3>
                      <p>Contact <strong>the Insurance Company’s Client Services Department</strong> to speak to one of their experienced team members.</p>
                    </div>
                    <div id="claimsforms" className="tab-pane" role="tabpanel" aria-labelledby="claimsforms-tab">
                      <h3>Commonly Required Forms/Documents</h3>
                      <p>When a claim is submitted, additional documentation may be requested by the claims adjustor to help examine the claim. <strong>Please keep all your original documentation</strong> as it may be requested during the process. Having them readily available will help to expedite your claim.</p>
                      <p>You may be required to submit the following documents along with any claims forms:</p>
                      <ul className="list">
                        <li>Proof of loss</li>
                        <li>Original Bills and Receipts</li>
                        <li>GHIP release</li>
                        <li>Incident Report</li>
                        <li>Travel Invoices / Original Unused Tickets</li>
                        <li>Trip Itinerary</li>
                      </ul>
                      <p>Please note that this is not a complete list of documents/forms required in the event of a claim.</p>
                      <p><a className="btn-2" href="/claims/forms/">Download Forms</a>  <a className="btn-2" href="https://www.oldrepubliccanada.com/eClaims/Account/Login?cc=tai&amp;lang=en" target="_blank" rel="noopener noreferrer">Submit Claim Online</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer lang={GLobalLinks["claims"]['lang']} />
    </>
  );
}

export default Claims;
