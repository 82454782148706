import React, { useEffect, useState } from 'react';
//import { trackPromise } from 'react-promise-tracker';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
//import axios from 'axios';
import { LuSearch } from "react-icons/lu";
import "bootstrap/js/dist/collapse.js";
import "bootstrap/js/dist/dropdown.js";
import './NavbarMain.css';
import $ from 'jquery';

const NavbarMain = (props) => {

  const [menu, setMenu] = useState([]);
  const [srch, setSrch] = useState(0);

  useEffect(() => {
    const menu2 = {
      "248": {
        "title": "Contact",
        "link": "/contact-us/",
        "target": "",
        "menu_order": 18,
        "rel": "",
        "dropdown": {
          "23609": {
            "title": "Complaint Resolution Process",
            "link": "/complaint-resolution-process/",
            "target": "",
            "menu_order": 19,
            "rel": ""
          }
        }
      },
      "250": {
        "title": "Products",
        "link": "/products/",
        "target": "",
        "menu_order": 5,
        "rel": "",
        "dropdown": {
          "251": {
            "title": "Visitors to Canada Emergency Medical Insurance (VTC)",
            "link": "/products/visitors-canada-insurance/",
            "target": "",
            "menu_order": 6,
            "rel": ""
          },
          "252": {
            "title": "Travel Right Insurance Plans (TRIPS)",
            "link": "/products/trips/",
            "target": "",
            "menu_order": 7,
            "rel": "",
            "dropdown": {
              "253": {
                "title": "Travel",
                "link": "/products/trips/travel/",
                "target": "",
                "menu_order": 8,
                "rel": ""
              },
              "254": {
                "title": "Youth",
                "link": "/products/trips/youth/",
                "target": "",
                "menu_order": 9,
                "rel": ""
              }
            }
          },
          "255": {
            "title": "Student Accident Plan (SAP)",
            "link": "/products/student-accident-plan/",
            "target": "",
            "menu_order": 10,
            "rel": ""
          },
          "25723": {
            "title": "International Student Travel Insurance (ISP)",
            "link": "/products/international-student/",
            "target": "",
            "menu_order": 11,
            "rel": ""
          }
        }
      },
      "256": {
        "title": "Partners",
        "link": "/partner/",
        "target": "",
        "menu_order": 12,
        "rel": "",
        "dropdown": {
          "23890": {
            "title": "Online Training Sessions",
            "link": "/partner/online-training-sessions/",
            "target": "",
            "menu_order": 15,
            "rel": ""
          },
          "25803": {
            "title": "Partnership Request Form",
            "link": "/partner/form/",
            "target": "",
            "menu_order": 14,
            "rel": ""
          },
          "26027": {
            "title": "VTC Rate Calculator",
            "link": "https://quote.travelance.ca/#/VTC?language=en",
            "target": "_blank",
            "menu_order": 13,
            "rel": ""
          }
        }
      },
      "265": {
        "title": "Claims",
        "link": "/claims/",
        "target": "",
        "menu_order": 16,
        "rel": "",
        "dropdown": {
          "266": {
            "title": "Claims Submission and Forms",
            "link": "/claims/forms/",
            "target": "",
            "menu_order": 17,
            "rel": ""
          }
        }
      },
      "24947": {
        "title": "About",
        "link": "#",
        "target": "",
        "menu_order": 1,
        "rel": "",
        "dropdown": {
          "245": {
            "title": "About Us",
            "link": "/about-us/",
            "target": "",
            "menu_order": 2,
            "rel": ""
          },
          "246": {
            "title": "Mission & Vision",
            "link": "/about-us/mission-and-vision/",
            "target": "",
            "menu_order": 3,
            "rel": ""
          },
          "249": {
            "title": "Reviews",
            "link": "/contact-us/reviews/",
            "target": "",
            "menu_order": 4,
            "rel": ""
          }
        }
      },
      "21": {
        "title": "Blog",
        "link": "/blog/",
        "target": "",
        "menu_order": 19,
        "rel": ""
      },
    }
    const menu3 = {
      "247": {
        "title": "Produits",
        "link": "/produits/",
        "target": "",
        "menu_order": 3,
        "rel": "",
        "dropdown": {
          "22823": {
            "title": "Visiteurs au Canada Assurance médicale d’urgence",
            "link": "/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence/",
            "target": "",
            "menu_order": 4,
            "rel": ""
          },
          "22824": {
            "title": "L’assurance voyage",
            "link": "/produits/l-assurance-voyage/",
            "target": "",
            "menu_order": 5,
            "rel": "",
            "dropdown": {
              "22839": {
                "title": "Jeunesse",
                "link": "/produits/l-assurance-voyage/jeunes/",
                "target": "",
                "menu_order": 7,
                "rel": ""
              },
              "22840": {
                "title": "Voyage",
                "link": "/produits/l-assurance-voyage/voyage/",
                "target": "",
                "menu_order": 6,
                "rel": ""
              }
            }
          },
          "22825": {
            "title": "Assurance Accident étudiant",
            "link": "/produits/regime-dassurance-accidents-pour-etudiants/",
            "target": "",
            "menu_order": 8,
            "rel": ""
          },
          "26248": {
            "title": "Police d’assurance voyage pour les étudiants étrangers",
            "link": "/produits/police-dassurance-voyage-pour-les-etudiants-etrangers/",
            "target": "",
            "menu_order": 9,
            "rel": ""
          }
        }
      },
      "22826": {
        "title": "Partenaires",
        "link": "/partenaires/",
        "target": "",
        "menu_order": 10,
        "rel": "",
        "dropdown": {
          "23027": {
            "title": "S'associer avec Travelance",
            "link": "/partenaires/sassocier-avec-travelance/",
            "target": "",
            "menu_order": 11,
            "rel": ""
          },
          "24171": {
            "title": "Sessions de formation en ligne",
            "link": "/partenaires/sessions-de-formation-en-ligne/",
            "target": "",
            "menu_order": 13,
            "rel": ""
          }
        }
      },
      "22828": {
        "title": "Réclamations",
        "link": "/reclamations/",
        "target": "",
        "menu_order": 14,
        "rel": "",
        "dropdown": {
          "22830": {
            "title": "Formulaires",
            "link": "/reclamations/formulaires/",
            "target": "",
            "menu_order": 15,
            "rel": ""
          }
        }
      },
      "22829": {
        "title": "Contactez-nous",
        "link": "/contactez-nous/",
        "target": "",
        "menu_order": 16,
        "rel": "",
        "dropdown": {
          "23607": {
            "title": "Processus de résolution des plaintes",
            "link": "/processus-de-resolution-des-plaintes/",
            "target": "",
            "menu_order": 17,
            "rel": ""
          }
        }
      },
      "22833": {
        "title": "À propos de nous",
        "link": "/a-propos-de-nous/",
        "target": "",
        "menu_order": 1,
        "rel": "",
        "dropdown": {
          "22834": {
            "title": "Mission et vision",
            "link": "/a-propos-de-nous/mission-et-vision/",
            "target": "",
            "menu_order": 2,
            "rel": ""
          }
        }
      }
    }


    //var mnid = 2;
    //mnid = 2;
    const data1 = Object.values(menu2);
    data1.sort((a,b)=>{
      return (a.menu_order >b.menu_order)?'1':'-1';
    });
    setMenu(Object.values(data1));
    if(props.lang === 'fr'){
      //mnid = 3;
      const data1 = Object.values(menu3);
      data1.sort((a,b)=>{
        return (a.menu_order >b.menu_order)?'1':'-1';
      });
      setMenu(Object.values(data1));
    }else{
      //mnid = 2;
      const data1 = Object.values(menu2);
      data1.sort((a,b)=>{
        return (a.menu_order >b.menu_order)?'1':'-1';
      });
      setMenu(Object.values(data1));
    }

    /*
    let url = `${process.env.REACT_APP_API_ROOT}menu/?menu=${mnid}`;
    trackPromise(
      axios.get(url).then((res) => {
        const { data } = res;
        const data1 = Object.values(data);
        data1.sort((a,b)=>{
            return (a.menu_order >b.menu_order)?'1':'-1';
        });
        setMenu(Object.values(data1));
    }));
    */

  },[props.lang]);
  var search = "Search";
  if(props.lang === 'fr'){
    search = "Recherche";
  }else{
    search = "Search";
  }

  function closeBtn(e){
    $(e.target).parent().siblings('.navbar-toggler').trigger('click');
  }
  function handleHover(e){
    $(e.target).parent('.dropdown').addClass('show');
    $(e.target).parent('.dropdown').find('>.dropdown-menu').addClass('show');
    $(e.target).addClass('show');
  }
  function handleHover1(e){
    if($(e.target).parent('.dropdown').hasClass('subMenu')){
      $(e.target).parent('.dropdown').removeClass('show');
      $(e.target).parent('.dropdown').find('.dropdown-menu').removeClass('show');
      $(e.target).removeClass('show');
    }else{
      $('.dropdown').removeClass('show');
      $('.dropdown-menu').removeClass('show');
      $('.dropdown-toggle').removeClass('show');
    }
  }
  function showSrch(e){
    console.log(e);
    setSrch(!srch);
  }
  function redirectLink(e){
    if($(e.target).hasClass('dropdown-toggle')){
      if($(e.target).parent('.nav-item')){
        var href = $(e.target).parent('.nav-item').attr('href');
        if(window.innerWidth < 768 ){
          var navW = $('.navbar-nav').innerWidth();
          console.log(navW);
          var result = e.clientX/parseInt(navW);
          console.log(result);
          if(parseFloat(result) < 0.85){
            console.log('in');
            if(href !== '#'){
              e.preventDefault();
              window.location.href = href;
            }
          }else{
            console.log('out');
          }
        }else{
          if(href !== '#'){
            e.preventDefault();
            window.location.href = href;
          }
        }
      }
    }
  }


  return (
      <>
        <Navbar expand="lg" className="nav-justified">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <div className="cls-btn"  onClick={closeBtn} >X</div>
              <Row style={{width:"100%"}}>
                <Col sm={9}>
                  <Nav>
                    {
                      menu.map((item,i)=>{
                        if(typeof(item.dropdown) == 'object'){
                          var tt = item.title.toLowerCase().replace(/\W/g, '')
                          var submenu = Object.values(item.dropdown)
                          submenu.sort((a,b)=>{
                              return (a.menu_order >b.menu_order)?'1':'-1';
                          });
                          return (
                            <NavDropdown onMouseOver={handleHover} onMouseLeave={handleHover1} onClick={redirectLink} key={i} href={item.link.replace('https://www.travelance.ca','')} rel={item.rel} target={item.target}  title={item.title} id={`${tt}-dropdown`} renderMenuOnMount={true} >
                            {
                              submenu.map((submenuitem,j)=>{
                                var ij = i.toString()+j.toString()
                                if(typeof(submenuitem.dropdown) == 'object'){
                                  var ttt = submenuitem.title.toLowerCase().replace(/\W/g, '')
                                  var subsubmenu = Object.values(submenuitem.dropdown)
                                  subsubmenu.sort((a,b)=>{
                                      return (a.menu_order >b.menu_order)?'1':'-1';
                                  });
                                  return (
                                    <NavDropdown onMouseOver={handleHover} onMouseLeave={handleHover1} onClick={redirectLink} key={ij} href={submenuitem.link.replace('https://www.travelance.ca','')} rel={submenuitem.rel} target={submenuitem.target}  title={submenuitem.title} id={`${ttt}-dropdown`} renderMenuOnMount={true} className="subMenu">
                                      {
                                          subsubmenu.map((it,k)=>{
                                            var ijk = ij.toString()+k.toString()
                                            return (
                                              <NavDropdown.Item key={ijk} rel={it.rel} target={it.target} href={it.link.replace('https://www.travelance.ca','')}>{it.title}</NavDropdown.Item>
                                            )
                                          })
                                      }
                                    </NavDropdown>
                                  )
                                }else{
                                  return(
                                    <NavDropdown.Item key={ij} rel={submenuitem.rel} target={submenuitem.target} href={submenuitem.link.replace('https://www.travelance.ca','')}>{submenuitem.title}</NavDropdown.Item>
                                  )
                                }
                              })
                            }
                            </NavDropdown>
                          )
                        }else{
                          return ( <Nav.Link key={i} href={item.link.replace('https://www.travelance.ca','')} rel={item.rel} target={item.target}>{item.title}</Nav.Link> )
                        }
                      })
                    }
                  </Nav>
                </Col>
                <Col sm={3}>
                  <Form action="/search" method="GET" className="searchform" rel={{srch}} >
                    {
                      // eslint-disable-next-line
                      srch==1 ? <Form.Control type="search" placeholder={search} name="s" className="search" aria-label="Search" />:""
                    }
                    <LuSearch className="1" onClick={showSrch} />
                  </Form>
                </Col>
              </Row>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );

}


export default NavbarMain;
