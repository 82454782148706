import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PrevNext = (props) => {

  let links = [];
  if(props.next !==''){ links.push({'text':'Next','link':props.next})}
  if(props.prev !==''){ links.push({'text':'Previous','link':props.prev})}

  return (
    <>
      <Pagination>
        {
          links.map((link,i)=>{
            return(
              <Pagination.Item href={ link.link } key={i}>{link.text}</Pagination.Item>
            )
          })
        }
      </Pagination>
    </>
  );
}

export default PrevNext;
