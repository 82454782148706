import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
//import { trackPromise } from 'react-promise-tracker';
//import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import './Banner.css'
import LazyBackgroundImage from "../LazyBackgroundImage/LazyBackgroundImage"
import Banner1 from "../../../assets/images/travelance-SAP-student-accident-plan.webp"
import Banner2 from "../../../assets/images/travelance-TRIP-travel-right-insurance-plans.webp"
import Banner3 from "../../../assets/images/travelance-VTC-visitors-to-canada-emergency-medical-insurance.webp"
import Banner4 from "../../../assets/images/International-Student-Plan-ISP.webp"
import Banner5 from "../../../assets/images/4-1.webp"
import Banner6 from "../../../assets/images/5-1.webp"

const Banner = (props) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const [banners1, setBanners] = useState([]);
  useEffect(() => {
    const banneren = [
      {
        "id": 25758,
        "image": Banner4,
        "content": "<h1 class=\"seofix_heading\">International Student Travel Insurance Policy</h1>\r\nInternational Student Travel Insurance Policy (ISP) Coverage for students studying in Canada.\r\n<div><a class=\"btn-learn-more scrollto\" href=\"/products/international-student/\">Learn More</a> <a class=\"btn-getaquote scrollto\" href=\"https://quote.travelance.ca/#/ISP\" target=\"_blank\" rel=\"noopener noreferrer\">Get a Quote</a></div>\r\n\r\n"
      },
      {
        "id": 20,
        "image": Banner3,
        "content": "<h2 class=\"seofix_heading\">Visitors to Canada Emergency Medical Insurance (VTC)</h2>\r\nSuper visa eligible, when purchased according to Canadian government requirements!\r\n<div><a class=\"btn-learn-more scrollto\" href=\"/products/visitors-canada-insurance/\">Learn More</a> <a class=\"btn-getaquote scrollto\" href=\"https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&amp;returnurl=step1.aspx\" target=\"_blank\" rel=\"noopener\">Get a Quote</a></div>"
      },
      {
        "id": 18,
        "image": Banner1,
        "content": "<h2 class=\"seofix_heading\">Student Accident Plan</h2>\r\n$49.99 per year!\r\n<div><a class=\"btn-learn-more scrollto\" href=\"/products/student-accident-plan/\">Learn More</a></div>"
      },
      {
        "id": 17,
        "image": Banner2,
        "content": "<h2 class=\"seofix_heading\">Travelling Out of Your Province?</h2>\r\nDon't forget to buy travel insurance before you go!\r\n<div><a class=\"btn-learn-more scrollto\" href=\"/products/trips/\">Learn More</a> <a class=\"btn-getaquote scrollto\" href=\"https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&amp;returnurl=step1.aspx\">Get a Quote</a></div>"
      },
      {
        "id": 16,
        "image": Banner5,
        "content": "<h2 class=\"seofix_heading\">Buy Online</h2>\r\nFast, secure, and easy to use!\r\n<div><a class=\"btn-getaquote scrollto\" href=\"https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&amp;returnurl=step1.aspx\">Buy Now</a></div>"
      },
      {
        "id": 7,
        "image": Banner6,
        "content": "<h2 class=\"seofix_heading\">Have a Question?</h2>\r\nWe want to hear from you!\r\n<div><a class=\"btn-getaquote scrollto\" href=\"/contact-us/\">Contact Us</a></div>"
      }
    ]
    const bannerfr = [
      {
        "id": 22990,
        "image": Banner3,
        "content": "<h2 class=\"seofix_heading\">Visiteurs au Canada Assurance médicale d’urgence</h2>\r\nAdmissible au super visa!\r\n<div><a class=\"btn-learn-more scrollto\" href=\"/fr/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence/\">En savoir plus</a> <a class=\"btn-getaquote scrollto\" href=\"https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&lang=fr&returnurl=step1.aspx\" target=\"_blank\">Obtenir Un Devis</a></div>"
      },
      {
        "id": 23036,
        "image": Banner1,
        "content": "<h2 class=\"seofix_heading\">L’assurance Accident étudiant</h2>\r\n49,99 $ par année\r\n<div><a class=\"btn-learn-more scrollto\" href=\"/fr/produits/regime-dassurance-accidents-pour-etudiants/\">En savoir plus</a></div>"
      },
      {
        "id": 23038,
        "image": Banner2,
        "content": "<h2 class=\"seofix_heading\">Vous voyagez à l’extérieur du Canada?</h2>\r\nTrouvez-moi le meilleur plan possible\r\n<div><a class=\"btn-learn-more scrollto\" href=\"/fr/produits/l-assurance-voyage/\">En savoir plus</a> <a class=\"btn-getaquote scrollto\" href=\"https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&lang=fr&returnurl=step1.aspx\">Obtenir Un Devis</a></div>"
      },
      {
        "id": 23039,
        "image": Banner5,
        "content": "<h2 class=\"seofix_heading\">Acheter en ligne</h2>\r\nRapide, sécuritaire et facile!\r\n<div><a class=\"btn-getaquote scrollto\" href=\"https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&lang=fr&returnurl=step1.aspx\">Acheter en ligne</a></div>"
      },
      {
        "id": 23040,
        "image": Banner6,
        "content": "<h2 class=\"seofix_heading\">Vous avez une question?</h2>\r\nNous sommes à l’écoute!\r\n<div><a class=\"btn-getaquote scrollto\" href=\"/fr/contactez-nous/\">Contactez-nous</a></div>"
      }
    ]

    if(props.lang === 'fr'){
      setBanners(Object.values(bannerfr));
    }else{
      setBanners(Object.values(banneren));
    }



    /*  let url = `${process.env.REACT_APP_API_ROOT}banner/?banner=${props.lang}`;
      trackPromise(
        axios.get(url).then((res) => {
          const { data } = res;
          setBanners(Object.values(data));
      }));*/
  }, [props.lang]);
  return (
    <>
    <Helmet>
      <link rel="preload" as="image" href={Banner4} />
    </Helmet>
    <Carousel style={{height:'525px'}} activeIndex={index} onSelect={handleSelect} className="banner" indicators={false} touch={true} prevIcon={ <FaArrowLeft />}  nextIcon={ <FaArrowRight /> }>
    {
      banners1.map((item,i)=>{
        return(
          <Carousel.Item key={i}  interval={300000}>
            <Container>
              <Row className="align-center">
                <Col className="col-md-6 col-12">
                  <div className="caption" dangerouslySetInnerHTML={{__html:item.content}}>

                  </div>
                </Col>

                <Col className="col-md-6 col-12">
                <div className="box">
                  <LazyBackgroundImage src={item.image} placeholder="" >

                  </LazyBackgroundImage>
                  {/*<div className="box-inner" style={{backgroundImage:`url(${item.image})`,transition: "background-image 0.5s ease-in-out",backgroundPosition:'center',backgroundSize:'cover'}} >
                  </div>*/}
                  <div className="box-background"></div>
                </div>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
        )
      })
    }
    </Carousel>
    </>
  );
}

export default Banner;
