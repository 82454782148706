import React from "react";
import Header from "../../commons/Header/Header";
import Footer from "../../commons/Footer/Footer";
import BlogsList from "../../commons/BlogsList/BlogsList";

const Blogs = (props) => {

  return (
    <>
      <Header en="/blogs" fr="/fr" lang="en" />
        <BlogsList />
      <Footer lang="en" />
    </>
  );
}

export default Blogs;
