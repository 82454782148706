import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const ReasonsTPWTL = (props) => {
  return (
    <>
    <Header en={GLobalLinks["partner/reasons-to-partner-with-travelance"]['en']} fr={GLobalLinks["partner/reasons-to-partner-with-travelance"]['fr']} lang={GLobalLinks["partner/reasons-to-partner-with-travelance"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="reasons-to-partner-with-travelance">9 Reasons to Partner with Travelance</h1>
          <div>
            <div className="header_partnerwithus bannerInner"></div>
            <h3><span className="highlight">Your clients are asking for Travelance by name. Are you ready to deliver?</span></h3>
            <div className="row">
              <div className="col-lg-6 content">
                <h3>About Travelance</h3>
                <p>Nine Reasons to Partner with Travelance</p>
                <ul className="list">
                  <li><a href="/about-us/">Read More</a></li>
                </ul>
              </div>
              <div className="col-lg-6 content">
                <h3>Our Underwriter</h3>
                <p>Travelance products are underwritten by Old Republic Insurance Company of Canada, based in Hamilton, ON.</p>
                <ul className="list">
                  <li><a href="/about-our-underwriter/">Read More</a></li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2 content"></div>
              <div className="col-lg-8 content">
                <div className="holder_block">
                  <h2>Partner with Us</h2>
                  <p>If you have questions, would like to request an agreement, or speak with Travelance Team Member, email <a href="mailto:info@travelance.ca"><strong>info@travelance.ca</strong></a> or call <a href="tel:18555668555" target="_blank" rel="noopener noreferrer"><strong>1-855-566-8555</strong></a></p>
                </div>
              </div>
              <div className="col-lg-2 content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["partner/reasons-to-partner-with-travelance"]['lang']} />
    </>
  );
}

export default ReasonsTPWTL;
