import React from "react";
import { Routes, Route } from 'react-router-dom';

import Login from "./Login/Login";

import Home from "./English/Home/Home";
import AboutUs from "./English/AboutUs/AboutUs";
import OurMission from "./English/AboutUs/OurMission";
import ReviewPage from "./English/ReviewPage/ReviewPage";
import Products from "./English/Products/Products";
import ProductsVCI from "./English/ProductsVCI/ProductsVCI";
import ProductsTrips from "./English/ProductsTrips/ProductsTrips";
import ProductsTripsT from "./English/ProductsTripsT/ProductsTripsT";
import ProductsTripsY from "./English/ProductsTripsY/ProductsTripsY";
import ProductsSAP from "./English/ProductsSAP/ProductsSAP";
import ProductsISP from "./English/ProductsISP/ProductsISP";
import ProductsISPP from "./English/ProductsISPP/ProductsISPP";
import ProductsSVIC from "./English/ProductsSVIC/ProductsSVIC";
import ProductsVCIM from "./English/ProductsVCIM/ProductsVCIM";
import Claims from "./English/Claims/Claims";
import ClaimForms from "./English/Claims/ClaimForms";
import PartnerPage from "./English/PartnerPage/PartnerPage";
import AgencyForm from "./English/AgencyForm/AgencyForm";
import Resources from "./English/Resources/Resources";
import PartnerWfgPage from "./English/PartnerWfgPage/PartnerWfgPage";
import WfgThankYou from "./English/PartnerWfgPage/WfgThankYou";
import PartnerGficPage from "./English/PartnerGficPage/PartnerGficPage";
import PartnerFormPage from "./English/PartnerFormPage/PartnerFormPage";
import ThankYou from "./English/PartnerFormPage/ThankYou";
import PartnerEfgPage from "./English/PartnerEfgPage/PartnerEfgPage";
import ProductsFaqs from "./English/ProductsFaqs/ProductsFaqs";
import Sitemap from "./English/Sitemap/Sitemap";
import SitemapFr from "./French/Sitemap/SitemapFr";


import AboutOUW from "./English/AboutOUW/AboutOUW";
import Blogs from "./Blogs/Blogs";
import Search from "./Search/Search";
import ComplaintResPro from "./English/ComplaintResPro/ComplaintResPro"
import Contact from "./English/Contact/Contact";
import ClientReffral from "./English/ClientReffral/ClientReffral";
import FaqAgents from "./English/FaqAgents/FaqAgents";

//import OnlineTSessions from "./English/OnlineTSessions/OnlineTSessions";
import ReasonsTPWTL from "./English/ReasonsTPWTL/ReasonsTPWTL";
import PrivacyPolicy from "./English/PrivacyPolicy/PrivacyPolicy";


import TermsConditions from "./English/TermsConditions/TermsConditions";
import AuthOutlet from '@auth-kit/react-router/AuthOutlet';
import SingleBlog from "./SingleBlog/SingleBlog";

import Reffral from "./English/Reffral/Reffral";

/* French Page */
import About from "./CommonPage/About";
import HomeFr from "./French/HomeFr/HomeFr";
import ContactFr from "./French/ContactFr/ContactFr";
import PartnerPageFr from "./French/PartnerPageFr/PartnerPageFr";
import NotFound from "./NotFound/NotFound";
import BackToTop from "../commons/BackToTop/BackToTop";

const Pages = () => {
  return (
    <>
      <Routes basename="/">
        <Route path="/login" element={<Login />} />

        <Route path='/' element={<Home/>} />
        <Route path='/about-us' element={<AboutUs/>} />
        <Route path='/about-us/mission-and-vision' element={<OurMission/>} />
        <Route path="/contact-us/reviews"  element={<ReviewPage />} />
        <Route path="/products"  element={<Products />} />
        <Route path="/products/visitors-canada-insurance"  element={<ProductsVCI />} />
        <Route path="/products/trips"  element={<ProductsTrips />} />
        <Route path="/products/trips/travel"  element={<ProductsTripsT />} />
        <Route path="/products/trips/youth"  element={<ProductsTripsY />} />
        <Route path="/products/student-accident-plan"  element={<ProductsSAP />} />
        <Route path="/products/international-student"  element={<ProductsISP />} />
        <Route path="/products/isp"  element={<ProductsISPP />} />
        <Route path="/products/insurance-for-visitors-to-canada-with-super-visa"  element={<ProductsSVIC />} />
        <Route path="/products/visitors-canada-insurance/monthly"  element={<ProductsVCIM />} />
        <Route path="/products/faqs"  element={<ProductsFaqs />} />
        <Route path='/about-our-underwriter' element={<AboutOUW/>} />
        <Route path='/claims' element={<Claims/>} />
        <Route path='/claims/forms' element={<ClaimForms/>} />
        <Route path="/partner"  element={<PartnerPage />} />
        <Route path="/partner/agency-form"  element={<AgencyForm />} />
        <Route path="/partner/experiorfinancialgroup"  element={<PartnerEfgPage />} />
        <Route path="/partner/reasons-to-partner-with-travelance"  element={<ReasonsTPWTL />} />
        <Route path="/partner/form"  element={<PartnerFormPage />} />
        <Route path="/partner/form/thank-you"  element={<ThankYou />} />
        <Route path="/partner/greatwayfinancial"  element={<PartnerGficPage />} />
        <Route path="/partner/wfg-information"  element={<PartnerWfgPage />} />
        <Route path="/partner/wfg-information/thank-you"  element={<WfgThankYou />} />
        <Route path='/complaint-resolution-process' element={<ComplaintResPro/>} />
        <Route path="/partner/online-training-sessions"  element={<About path="partner/online-training-sessions" />} />
        <Route path="/partner/client-referral-program"  element={<ClientReffral />} />
        <Route path="/contact-us"  element={<Contact />} />
        <Route path="/partner/faq-agents"  element={<FaqAgents />} />
        <Route path="/partner/resources"  element={<Resources />} />
        <Route path="/privacy-policy"  element={<PrivacyPolicy />} />
        <Route path="/blog"  element={<Blogs />} />
        <Route path="/terms-conditions"  element={<TermsConditions />} />
        <Route path="/partner/referral-form"  element={<Reffral />} />
        <Route path="/sitemap"  element={<Sitemap />} />
        <Route path="/search"  element={<Search />} />

        {/*French*/}
        <Route path='/fr' element={<HomeFr/>} />
        <Route path='/a-propos-de-nous' element={<About path="a-propos-de-nous" />} />
        <Route path='/a-propos-de-nous/mission-et-vision' element={<About path="a-propos-de-nous/mission-et-vision" />} />
        <Route path='/produits' element={<About path="produits" />} />
        <Route path='/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence' element={<About path="produits/assurance-visiteurs-au-canada-frair-medicaux-durgence" />} />
        <Route path='/produits/l-assurance-voyage' element={<About path="produits/l-assurance-voyage" />} />
        <Route path='/produits/l-assurance-voyage/voyage' element={<About path="produits/l-assurance-voyage/voyage" />} />
        <Route path='/produits/l-assurance-voyage/jeunes' element={<About path="produits/l-assurance-voyage/jeunes" />} />
        <Route path='/produits/regime-dassurance-accidents-pour-etudiants' element={<About path="produits/regime-dassurance-accidents-pour-etudiants" />} />
        <Route path='/produits/police-dassurance-voyage-pour-les-etudiants-etrangers' element={<About path="produits/police-dassurance-voyage-pour-les-etudiants-etrangers" />} />
        <Route path='/au-sujet-de-notre-assureur' element={<About path="au-sujet-de-notre-assureur" />} />
        <Route path='/produits/produits-faq' element={<About path="produits/produits-faq" />} />
        <Route path='/reclamations' element={<About path="reclamations" />} />
        <Route path='/reclamations/formulaires' element={<About path="reclamations/formulaires" />} />
        <Route path="/partenaires"  element={<PartnerPageFr />} />
        <Route path="/partenaires/sassocier-avec-travelance"  element={<About path="partenaires/sassocier-avec-travelance" />} />
        <Route path="/processus-de-resolution-des-plaintes"  element={<About path="processus-de-resolution-des-plaintes" />} />
        <Route path="/contactez-nous"  element={<ContactFr />} />
        <Route path="/plan-du-site"  element={<SitemapFr />} />
        <Route path="/modalites-et-conditions"  element={<About path="modalites-et-conditions" />} />
        <Route path="/politique-de-confidentialite"  element={<About path="politique-de-confidentialite" />} />
        <Route path="/partenaires/sessions-de-formation-en-ligne"  element={<About path="partenaires/sessions-de-formation-en-ligne" />} />


        <Route path="/blog/:slug"  element={<SingleBlog />} />
        <Route path="/blogs/page/:id"  element={<Blogs />} />

        <Route path="*" element={<NotFound />} />

        <Route element={<AuthOutlet fallbackPath='/login' />}>

        </Route>
      </Routes>
      <BackToTop />
    </>
  );
}

export default Pages;
