import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import Header from "../../commons/Header/Header";
import Footer from "../../commons/Footer/Footer";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PrevNext from "../../commons/PrevNext/PrevNext";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo_1.svg";


const SingleBlog = (props) => {

  const { slug } = useParams();
  const [content, setContent] = useState([]);
  const [img, setImg] = useState('');
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [prev, setPrev] = useState('');
  const [next, setNext] = useState('');
  const location = useLocation();
  console.log(location);

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_ROOT}post/?slug=${slug}`;

      trackPromise(
        axios.get(url).then((res) => {
          const { data } = res;
          setContent(data);
          if(!data.featured_image.img_src){
            setImg(logo);
          }else{
            setImg(data.featured_image.img_src[0]);
            setWidth(data.featured_image.img_src[1]);
            setHeight(data.featured_image.img_src[2]);
          }
          if(data.next){
            setNext(data.next);
          }
          if(data.prev){
            setPrev(data.prev);
          }

      }));
  }, [slug]);




  return (
    <>
      <Header en={`/blog/${slug}`} fr={`/blog/${slug}`} lang="en" />
      <Helmet>
        <title>{content.metaTitle}</title>
        <meta name="description" content={content.metaDesc} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={content.metaTitle} />
        <meta property="og:description" content={content.metaDesc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="Travelance" />
        <link rel="alternate" href={window.location.href} hreflang="en" />
      </Helmet>
      <div className="container mainDiv singleBlog">
        <div className="row">
          <div className="col-xs-12 content">
            <Link to='/blog'><IoArrowBackOutline /> Back</Link>
            <h1>{content.title}</h1>
            <img src={img} alt={content.title} className={`featuredSingle blog-${content.id}`} width={width} height={height}/>
            <div dangerouslySetInnerHTML={{__html:content.html}}></div>
          </div>
        </div>
        <div className="row g-5 footer-pagination">
          <PrevNext prev={prev} next={next} />
        </div>
      </div>

      <Footer lang="en" />
    </>
  );
}

export default SingleBlog;
