import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";

import './Topheader.css'

const Topheader = (props) => {

    return (
      <section id="topbar">
        <div className="container clearfix">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-sm-6 col-6">
              <Link className="float-left" to="tel:1-855-566-8555"><strong><FaPhoneAlt /> 1-855-566-8555</strong></Link>
            </div>
            <div className="col-sm-6 col-md-6 col-sm-6 col-6 float-end">
              <span className="float-end">
                <NavLink className={({ isActive, isPending }) =>isPending ? "pending" : isActive ? "active" : ""} to={props.en} >English</NavLink>
                |
                <NavLink className={({ isActive, isPending }) =>isPending ? "pending" : isActive ? "active" : ""} to={props.fr} >Français</NavLink>

              </span>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Topheader;
