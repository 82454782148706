import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { FaQuoteLeft,FaQuoteRight } from "react-icons/fa";
import axios from 'axios';
import "./Reviews.css"


const Reviews = (props) => {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    /*
    const reviews = [
      {
        "id": "165",
        "created_time_stamp": "1733443200",
        "reviewer_name": "Veronica Merkli",
        "rating": "5",
        "review_text": "The porta is so easy to use. Extending client's policy takes few minutes.",
        "userpic": "https://lh3.googleusercontent.com/a/ACg8ocLhx19TCa83haOtEN5m6NF8wshjwSg-oEUAa3LF2yvfcjxA4Q=s100-c-rp-mo-br100",
        "review_length": "13"
      },
      {
        "id": "163",
        "created_time_stamp": "1729468800",
        "reviewer_name": "Obineche Ukonu",
        "rating": "5",
        "review_text": "Great company, easy to transact business with, and responsive to both clients and advisors' needs.",
        "userpic": "https://lh3.googleusercontent.com/a-/ALV-UjXPpsl4UjdkYVwYvPI1xtZW3A65qZ6L7Vqb29q5nyVgtqkBzwuZkQ=s100-c-rp-mo-br100",
        "review_length": "15"
      },
      {
        "id": "160",
        "created_time_stamp": "1721779200",
        "reviewer_name": "Praveen Arora",
        "rating": "5",
        "review_text": "Wonderful customer service! And a great company!",
        "userpic": "https://lh3.googleusercontent.com/a/ACg8ocJejKFEgGP1YIq3h17mVdCTOblf_ytisF69BXTbZEEuQLDdpQ=s100-c-rp-mo-br100",
        "review_length": "7"
      },
      {
        "id": "158",
        "created_time_stamp": "1719619200",
        "reviewer_name": "Harmanjit Singh (Harman)",
        "rating": "5",
        "review_text": "Got insurance for my mom. Received hassle free reimbursement when I had to take her to the ER. Great insurance",
        "userpic": "https://lh3.googleusercontent.com/a/ACg8ocJykL86TDFZcQPrFMxCpBDC4OYviWHo3hucYKRQkV7n_9J0jg=s100-c-rp-mo-br100",
        "review_length": "20"
      },
      {
        "id": "156",
        "created_time_stamp": "1719273600",
        "reviewer_name": "Jayasri Kannan",
        "rating": "5",
        "review_text": "Megha from Travelance and travelance team is always there to help and answer any questions you have and over all I have excellent support and help from Travelance and I would recommend anyone to purchase theirs policy it is competitive and affordable to anyone who is looking for their visitors to Canada policy or traveling Canadians.",
        "userpic": "https://lh3.googleusercontent.com/a/ACg8ocINc-QPL0f3SD8FIwgq1c_qn6mU0BG2G1teypUF6oP4y0_Oeg=s100-c-rp-mo-br100",
        "review_length": "56"
      },
      {
        "id": "154",
        "created_time_stamp": "1712966400",
        "reviewer_name": "Shaminder K.",
        "rating": "4",
        "review_text": "Initially I got an email saying policy is cancelled. But subsequently I found that another policy was issued and I am quite satisfied. Suggestion: 2-3 lines on how to use will be appreciated in the email with policy.",
        "userpic": "https://lh3.googleusercontent.com/a-/ALV-UjUPNhYLEZ8HWlM6-lufelqsCnj8Ve_Qrnx09SmGVF-9g6A3twE=s100-c-rp-mo-br100",
        "review_length": "38"
      },
      {
        "id": "152",
        "created_time_stamp": "1709424000",
        "reviewer_name": "Balwant Natt",
        "rating": "5",
        "review_text": "I like to do business with Travelance because it’s rates are very good, products like Visitors to Canada Emergency Medical Insurance and Travel Insurance for Canadians travelling out of Province and worldwide are very good in terms of benefits I have a very good experience regarding the Claims too.",
        "userpic": "https://lh3.googleusercontent.com/a-/ALV-UjVClaGbTjOJz8l8PRVczNAwY7_W9H9i_zZGOeniWNL_LA=s100-c-rp-mo-br100",
        "review_length": "50"
      },
      {
        "id": "151",
        "created_time_stamp": "1709596800",
        "reviewer_name": "Yemisi Legunsen",
        "rating": "5",
        "review_text": "Customer service is excellent",
        "userpic": "https://lh3.googleusercontent.com/a/ACg8ocLrmlttk0M7nluZsoM-7PP640hKi2JKCjvS7xCQ0k7t=s100-c-rp-mo-br100",
        "review_length": "4"
      },
      {
        "id": "144",
        "created_time_stamp": "1700092800",
        "reviewer_name": "Shahzad Ali",
        "rating": "5",
        "review_text": "Got reimbursed for the claim. Document submitted and claim was accepted. no questions asked and no issues.",
        "userpic": "https://lh3.googleusercontent.com/a/ACg8ocImU128Ht9tgC-iJ-Pu1-sMQhYlPwDc1GhguohcLok0=s240-c-rp-mo-ba2-br100",
        "review_length": "17"
      },
      {
        "id": "139",
        "created_time_stamp": "1697673600",
        "reviewer_name": "Denis Kondratev",
        "rating": "5",
        "review_text": "I've had very good experience with this company. I purchased travel medical insurance for Canada",
        "userpic": "https://lh3.googleusercontent.com/a-/ALV-UjU6LKJgQ5YhkFLtA0m4lgQS7PjakVCNHdKKUv4coCTJgi1k=s100-c-rp-mo-br100",
        "review_length": "15"
      },
      {
        "id": "134",
        "created_time_stamp": "1695168000",
        "reviewer_name": "Sharanjit Taggar",
        "rating": "5",
        "review_text": "Great company, one of the best customer service, very easy to reach, no long wait when you call them. Very very cheap Rates for Super visa insurance",
        "userpic": "https://lh3.googleusercontent.com/a-/ALV-UjU0adfNstIpxM557W2BVom-0p7l3lBkQWbi16_gcGGsw7Y=s240-c-rp-mo-ba4-br100",
        "review_length": "27"
      },
      {
        "id": "131",
        "created_time_stamp": "1692316800",
        "reviewer_name": "Kamal Kaur",
        "rating": "5",
        "review_text": "Great service ,, I would love too use this over  again .. Deserves 5 stars .",
        "userpic": "https://lh3.googleusercontent.com/a/AAcHTte0Q88K8l4R6EpwlkSXGjTm0NpaNgTAC-aKyDwcVfna=s100-c-rp-mo-br100",
        "review_length": "12"
      }
    ]
    setReviews(reviews);
    */
    let url = `${process.env.REACT_APP_API_ROOT}reviews`;
    trackPromise(
      axios.get(url).then((res) => {
        const { data } = res;
        setReviews(data);
      })
    );
  }, []);
  const options ={
    'items':2,
    'loop':true,
    'margin':50,
    'center':false,
    'stagePadding':20,
    'nav':false,
    'dots':false,
    'autoplay':true,
    'autoplayTimeout': 3000,
    'responsive':{
      0:{
        'items':1,
        'margin':15,
      },
      768:{
        'items':2,
        'margin':50,
      }
    }
  }
  if(reviews.length){
    return (
      <div className="reviewSection" >
        <Container>
          <Row className="align-items-center">
            <Col className="text text-center center"><h3>Testimonials</h3></Col>
          </Row>
          <Row>
            <Col>
              <OwlCarousel className='owl-theme' {...options} >
              {
                reviews && reviews.map((review,i)=>{
                  //let date = new Date();
                  let formattedDate = moment.unix(review.created_time_stamp).format("MM/DD/YYYY");
                  return(
                    <div className={`item item-${review.id}`} key={review.id}>
                      <div className="reviewText">
                        <div className="text align-middle">
                          <FaQuoteLeft className="left" />
                          <p>{review.review_text}</p><FaQuoteRight  className="right"  />
                        </div>
                      </div>
                      <div className="reviewDetails text text-center center align-items-center">
                        <img loading="lazy" src={review.userpic.replace('s240-c-rp-mo-br100','s100-c-rp-mo-br100')} alt={review.reviewer_name} width="100" height="100" />
                        <h4>{review.reviewer_name}</h4>
                        <p>{formattedDate}</p>
                      </div>
                    </div>
                  )
                })
              }
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }else{
    return (
      <></>
    )
  }

}

export default Reviews;
