import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import Header from "../../commons/Header/Header";
import Footer from "../../commons/Footer/Footer";
import GLobalLinks from "../GLobalLinks/GLobalLinks";
import axios from 'axios';
import './About.css';
import $ from 'jquery';

const About = (props) => {

  let path = props.path;

  /*
  const { slug } = useParams();
  const { slug1 } = useParams();
  const { slug2 } = useParams();
  const { slug3 } = useParams();
  */
  const [content, setContent] = useState([]);
  //const [html, setHtml] = useState([]);
  /*
  let path = `${slug}`;
  if(slug3 !== undefined){
    path = `${slug}/${slug1}/${slug2}/${slug3}`;
  }else if(slug2 !== undefined){
    path = `${slug}/${slug1}/${slug2}`;
  }else if(slug1 !== undefined){
    path = `${slug}/${slug1}`;
  }else{
    path = `${slug}`;
  }*/
  console.log(path);


  useEffect(() => {
    let url = `${process.env.REACT_APP_API_ROOT}page/?path=${path}`;
    /*
    if(slug3 !== undefined){
      url = `${process.env.REACT_APP_API_ROOT}page/?path=${slug}/${slug1}/${slug2}/${slug3}`;
    }else if(slug2 !== undefined){
      url = `${process.env.REACT_APP_API_ROOT}page/?path=${slug}/${slug1}/${slug2}`;
    }else if(slug1 !== undefined){
      url = `${process.env.REACT_APP_API_ROOT}page/?path=${slug}/${slug1}`;
    }else{
      url = `${process.env.REACT_APP_API_ROOT}page/?path=${slug}`;
    }
    */
    trackPromise(
      axios.get(url).then((res) => {
        const { data } = res;
        data.cssclass = data.title.toLowerCase().split(" ").join("-");
        setContent(data);
    }));
  }, [path]);

  $(document).ready(function(){
    $('.mainDiv .nav-link').on('click',function(e){
      e.preventDefault();
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      var id = $(this).attr('href');
      $('.tab-pane').removeClass('active');
      $(id).addClass('active');
    });
    $('.accordion-header button').on('click',function(e){
      e.preventDefault();
      $('.accordion-header button').not(this).addClass('collapsed');
      $(this).toggleClass('collapsed');
      var id = $(this).attr('data-target');
      console.log(id);
      console.log($('.collapse').not(id));
      $('.collapse').not(id).removeClass('show');
      //$('.collapse').not(id).css('border-color','red');
      $(id).toggleClass('show');
    });
  });

  return (
    <>
      <Header en={GLobalLinks[path]['en']} fr={GLobalLinks[path]['fr']} lang={GLobalLinks[path]['lang']} />
      <div className="container mainDiv ab">
        <div className="row">
          <div className="col-xs-12 content">
            <h1 className={content.cssclass}>{content.title}</h1>
            <div dangerouslySetInnerHTML={{__html:content.html}}>
            </div>
          </div>
        </div>
      </div>
      <Footer lang={GLobalLinks[path]['lang']} />
    </>
  );
}

export default About;
