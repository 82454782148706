import React from 'react';
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import PartnerForm from "../../../commons/PartnerForm/PartnerForm";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AgencyForm = (props) => {

  return (
    <>
      <Header en='/partner/agency-form' fr='/fr' lang='en' />
      <Container className="mainDiv partnerFormPage">
        <Row className="">
          <Col xl={12} className="content">
            <h1>Partner with Travelance</h1>
            <Container>
              <Row className="">
                <Col xl={8} className="">
                  <PartnerForm heading="Complete this form to begin the sign-up process with Travelance"/>
                </Col>
                <Col xl={4} className="">
                  <div className="stickyDiv">
                    <div className="holder_block">
                      <h2>All Products</h2>
                      <ul className="list">
                        <li><a href="/products/trips/travel/">Travel</a></li>
                        <li><a href="/products/trips/youth/">Youth</a></li>
                        <li><a href="/products/visitors-canada-insurance/">VTC Emergency Medical Insurance</a></li>
                        <li><a href="/products/student-accident-plan/">Student Accident Plan</a></li>
                        <li><a href="/products/international-student/">International Student Plan</a></li>
                      </ul>
                    </div>
                    <div className="holder_block">
                      <h2>Quick Links</h2>
                      <ul className="list">
                        <li><a href="https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&returnurl=step1.aspx">Get a Quote</a></li>
                        <li><a href="/#faqSection">FAQ</a></li>
                        <li><a href="/about-our-underwriter/">Our Underwriter</a></li>
                      </ul>
                    </div>
                    <div className="holder_block">
                      <h2><a href="https://travelance.box.com/v/Travelance-Product-Portfolio">View Travelance's Product Portfolio</a></h2>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer lang="en" />
    </>
  );
}

export default AgencyForm;
