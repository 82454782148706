import React, { useEffect, useState } from 'react';
//import { trackPromise } from 'react-promise-tracker';
//import axios from 'axios';
import Container  from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "./Footer.css";
import { FaPhone } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
//import { FaRss } from "react-icons/fa";
import img from "../../../assets/images/Footer_Logo.svg";
import img1 from "../../../assets/images/Thia_bill.webp";
import img2 from "../../../assets/images/dmca_premi_badge_1.webp";


const Footer = (props) => {
  var link = '/';
  var btnText = 'Get Quote';
  if(props.lang === 'fr'){
    link = '/fr';
    btnText = 'Obtenir Un Devis';
  }else{
    link = '/';
    btnText = 'Get Quote';
  }
  var fbLink = 'http://www.facebook.com/travelance.inc';
  var lnLink = 'https://www.linkedin.com/company/travelance-insurance';
  //var rssLink = 'http://feeds.feedburner.com/Travelance';
  var ytLink = 'https://www.youtube.com/@TravelanceInc';
  var inLink = 'https://www.instagram.com/travelance/';
  var clText = 'Client Number';
  var lICText = 'Licensed Sectors :<a href="https://lautorite.qc.ca/en/general-public/registers/register-of-firms-and-individuals-authorized-to-practise/" target="_blank" style="color:#d3d3d3;">Insurance of Persons</a>';
  var copyRightMenu = '<ul><li><a href="/sitemap">Sitemap</a></li><li><a href="/privacy-policy">Privacy Policy</a></li><li><a href="/terms-conditions">Terms and Conditions</a></li></ul>';
  if(props.lang === 'fr'){
    clText = 'Numéro client';
    lICText = 'Secteurs agréés: <a href="https://lautorite.qc.ca/en/general-public/registers/register-of-firms-and-individuals-authorized-to-practise/" target="_blank">Assurance des personnes</a>';
    copyRightMenu = '<ul><li><a href="/plan-du-site/">Plan du site</a></li><li><a href="/politique-de-confidentialite/">Politique de confidentialité</a></li><li><a href="/modalites-et-conditions/">Modalités et conditions</a></li></ul>';
  }else{
    clText = 'Client Number';
    lICText = 'Licensed Sectors :<a href="https://lautorite.qc.ca/en/general-public/registers/register-of-firms-and-individuals-authorized-to-practise/" target="_blank" style="color:#d3d3d3;">Insurance of Persons</a>';
    copyRightMenu = '<ul><li><a href="/sitemap">Sitemap</a></li><li><a href="/privacy-policy">Privacy Policy</a></li><li><a href="/terms-conditions">Terms and Conditions</a></li></ul>';
  }

  const [menu, setMenu] = useState([]);
  useEffect(() => {
    const menuen = {
      "26617": {
        "title": "Travel",
        "link": "/products/trips/travel/",
        "target": "",
        "menu_order": 1,
        "rel": ""
      },
      "26618": {
        "title": "Youth",
        "link": "/products/trips/youth/",
        "target": "",
        "menu_order": 2,
        "rel": ""
      },
      "26620": {
        "title": "Visitors to Canada Insurance",
        "link": "/products/visitors-canada-insurance/",
        "target": "",
        "menu_order": 3,
        "rel": ""
      },
      "26621": {
        "title": "Student Accident Plan",
        "link": "/products/student-accident-plan/",
        "target": "",
        "menu_order": 4,
        "rel": ""
      },
      "26725": {
        "title": "International Student Plan",
        "link": "/products/international-student/",
        "target": "",
        "menu_order": 5,
        "rel": ""
      }
    }
    const menufr = {
      "26632": {
        "title": "Voyage",
        "link": "/produits/l-assurance-voyage/voyage/",
        "target": "",
        "menu_order": 1,
        "rel": ""
      },
      "26633": {
        "title": "Jeunesse",
        "link": "/produits/l-assurance-voyage/jeunes/",
        "target": "",
        "menu_order": 2,
        "rel": ""
      },
      "26634": {
        "title": "Assurance médicale d’urgence",
        "link": "/produits/assurance-visiteurs-au-canada-frair-medicaux-durgence/",
        "target": "",
        "menu_order": 3,
        "rel": ""
      },
      "26635": {
        "title": "Assurance Accident étudiant",
        "link": "/produits/regime-dassurance-accidents-pour-etudiants/",
        "target": "",
        "menu_order": 4,
        "rel": ""
      }
    }
    const data1 = Object.values(menuen);
    data1.sort((a,b)=>{
        return (a.menu_order >b.menu_order)?'1':'-1';
    });
    setMenu(Object.values(data1));

    //var mnid = 4;
    if(props.lang === 'fr'){
      const data1 = Object.values(menufr);
      data1.sort((a,b)=>{
          return (a.menu_order >b.menu_order)?'1':'-1';
      });
      setMenu(Object.values(data1));
      //mnid = 5;
    }else{
      const data1 = Object.values(menuen);
      data1.sort((a,b)=>{
          return (a.menu_order >b.menu_order)?'1':'-1';
      });
      setMenu(Object.values(data1));
      //mnid = 4;
    }
      /*
      let url = `${process.env.REACT_APP_API_ROOT}menu/?menu=${mnid}`;
      trackPromise(
        axios.get(url).then((res) => {
          const { data } = res;
          const data1 = Object.values(data);
          data1.sort((a,b)=>{
              return (a.menu_order >b.menu_order)?'1':'-1';
          });
          setMenu(Object.values(data1));
      }));
      */
  }, [props.lang]);




    return (
      <>
      <div id="footer-sticky-wrapper" className="footer-wrapper" >
        <footer id="footer" >
          <Container>
            <Row>
              <Col md={6} xl={3}>
                <Link className="float-left logo" to={link}>
                  <img loading="lazy" className="img_footer" src ={img} alt="logo" width="250" height="52"/>
                </Link>
                <ul>
                  <li id="socialicons">
                    <Link target="_blank" rel="noopener noreferrer" to={fbLink} aria-label="facebook"><FaFacebookF/></Link>
                    <Link target="_blank" rel="noopener noreferrer" to={lnLink} aria-label="Linkedin" ><FaLinkedinIn/></Link>
                    <Link target="_blank" rel="noopener noreferrer" to={inLink} aria-label="Instagram" ><FaInstagramSquare /></Link>
                    <Link target="_blank" rel="noopener noreferrer" to={ytLink} aria-label="Youtube" ><FaYoutube /></Link>
                    {/*<Link target="_blank" rel="noopener noreferrer" to={rssLink}><FaRss /></Link>*/}
                  </li>
                  <li id="footerbutton" ><Link to='https://www.getreliable.com/direct/login.aspx?ag=4d7fba7f-6ce0-45d2-8978-f1eb892585e7&returnurl=step1.aspx'><Button>{btnText}</Button></Link></li>
                </ul>
              </Col>
              <Col  md={6} xl={3}>
              <h5>Our Products</h5>
                <ul>
                  {
                    menu.map((item,i)=>{

                      return(
                        <li key={i}><Link key={`${i}-menu`} rel={item.rel} target={item.target} to={item.link.replace('https://www.travelance.ca','')}>{item.title}</Link></li>
                      )
                    })
                  }
                </ul>
              </Col>
              <Col  md={6} xl={3}>
                <h5>Contact Us</h5>
                <ul>
                  <li><Link to="tel:18555668555"><FaPhone /> 1-855-566-8555</Link></li>
                  <li><Link to="mailto:info@travelance.ca"><MdMail /> info@travelance.ca</Link></li>
                  <li><FaLocationDot />126 Catharine St. N., Hamilton, ON L8R 1J4 Canada</li>
                </ul>
              </Col>
              <Col  md={6} xl={3}>
                <h5>AMF Infomation</h5>
                <ul>
                  <li id="firm">Distributing Firm Name : Travelance Incorporated</li>
                  <li>{clText} : 601870 </li>
                  <li dangerouslySetInnerHTML={{__html:lICText}}></li>
                </ul>
                <img loading="lazy" src={img1} alt="Thia" width="132" height="132"/>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
      <div id="footer-copyright" >
        <Container>
          <Row>
            <Col md={6}>
              <a href="https://www.dmca.com/Protection/Status.aspx?ID=18a7ab1f-4222-4598-a5e6-2ec6f674ab8f&amp;refurl=https://www.travelance.ca/processus-de-resolution-des-plaintes/" title="DMCA.com Protection Status" className="dmca-badge">
              <img loading="lazy" src={`${img2}?ID=18a7ab1f-4222-4598-a5e6-2ec6f674ab8f`} alt="DMCA.com Protection Status" width="55" height="58" /></a>
      			  <script src="//images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script> © 2013 - 2025 Travelance Inc. | All rights reserved.
      		  </Col>
            <Col md={6} dangerouslySetInnerHTML={{__html:copyRightMenu}}>
            </Col>
          </Row>
        </Container>
      </div>
      </>
    );
}

export default Footer;
