import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const OurMission = (props) => {
  return (
    <>
    <Header en={GLobalLinks["about-us/mission-and-vision"]['en']} fr={GLobalLinks["about-us/mission-and-vision"]['fr']} lang={GLobalLinks["about-us/mission-and-vision"]['lang']} />
    <div className="container mainDiv aboutUs">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="mission-and-vision">Our Mission</h1>
          <div>
            <p>With integrity as our cornerstone, we are dedicated to providing insurance solutions that exemplify trust, reliability, and excellence. Our mission is to offer products and services that <a href="https://travelance.ca/about-us/">our team</a> proudly stands behind, reflecting the highest standards of ethics and professionalism.</p>
            <p>We aim to be the first choice for brokers, fostering strong <a href="https://travelance.ca/partner/">partnerships</a> through transparency, consistent support, and innovative solutions tailored to meet their clients’ needs. By building trust and maintaining open communication, we ensure that brokers feel confident recommending <a href="https://travelance.ca/products/">our products</a>, knowing they align with the best interests of their clients.</p>
            <p>For our customers, we strive to create a seamless and reassuring experience, making it easy to purchase insurance with complete confidence. Our solutions are designed to offer comprehensive protection, addressing real-world travel and health concerns while providing peace of mind.</p>
            <p>By upholding our commitment to integrity, we ensure that every interaction —whether with <a href="https://travelance.ca/about-us/">our team</a>, brokers, or clients—reflects our dedication to honesty, accountability, and excellence in the insurance industry.</p>

            <h2 className="seofix_heading main-title">Our Vision</h2>
            <p>Our commitment is to <b>deliver exemplary client experiences</b> by combining exceptional customer service with industry expertise. We believe that every interaction is an opportunity to demonstrate our dedication to our clients, ensuring their needs are met with care, precision, and professionalism.</p>
            <p>Through <b>superior support</b>, we aim to go beyond the transactional nature of insurance, building lasting relationships founded on trust and reliability. Whether addressing a simple inquiry or guiding clients through complex claims, our team is driven by a client-first approach that prioritizes clarity, compassion, and prompt solutions.</p>
            <p>Leveraging our <b>diverse knowledge and expertise</b>, we stay at the forefront of the Canadian insurance industry. Our understanding of the unique challenges faced by individuals, families, and businesses allows us to craft tailored solutions that meet a wide range of needs. This dedication to innovation and adaptability enables us to provide insurance products that not only protect but also empower our clients.</p>
            <p>Our ultimate goal is to establish ourselves as a <b>preferred leader in the Canadian insurance industry</b>. By consistently delivering value, trust, and exceptional experiences, we aim to set new benchmarks in service and product excellence, earning the confidence of clients and partners alike.</p>
          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["about-us/mission-and-vision"]['lang']} />
    </>
  );
}

export default OurMission;
