import React from "react";
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import GLobalLinks from "../../GLobalLinks/GLobalLinks";

const ComplaintResPro = (props) => {
  return (
    <>
    <Header en={GLobalLinks["complaint-resolution-process"]['en']} fr={GLobalLinks["complaint-resolution-process"]['fr']} lang={GLobalLinks["complaint-resolution-process"]['lang']} />
    <div className="container mainDiv ab">
      <div className="row">
        <div className="col-xs-12 content">
          <h1 className="complaint-resolution-process">Complaint Resolution Process</h1>
          <div>
            <p>The purpose of our Complaint Resolution Process is to ensure that all of our customers are able to process a complaint.</p>
            <h2>Step One: Contact Us</h2>
            <p>If you are not satisfied with our level of service, or the outcome of your claim, please contact the employee or representative who served you. The employee’s contact information can be found on the documents you received.</p>
            <p>Prior to contacting us, gather the following:</p>
              <ul>
                <li>All of the relevant documents;</li>
                <li>The name(s) of the employee(s) involved;</li>
                <li>The reason for your dissatisfaction;</li>
                <li>The questions you would like to ask; and</li>
                <li>The case you would like to present and the solution you are seeking.</li>
              </ul>
            <p>Decide how you will contact us.</p>
              <ul>
                <li>By telephone
                  <ul>
                    <li>If you choose to contact the employee or representative by phone, we ask that you call us to set up an appointment. This will allow the employee to review your file and set aside the necessary time.</li>
                  </ul>
                </li>
                <li>In writing
                  <ul>
                    <li>If you choose to write to us, be sure to include a detailed explanation of your complaint and photocopies of your documents. Please do not send originals.</li>
                  </ul>
                </li>
              </ul>
            <p>When communicating with the company, be sure to:</p>
              <ol type="i">
                <li>State the reason for your complaint and ask questions or raise any concerns you may have;</li>
                <li>Take note of the name of the person you are speaking with, keep a record of your conversation and copies of all correspondence; and</li>
                <li>Allow adequate time for a response to your complaint; if the employee or representative does not provide you with a satisfactory outcome, request to speak with the manager in that department.</li>
              </ol>
            <h2>Step 2: Filing a formal complaint.</h2>
            <p>If you are not satisfied with the outcome of your complaint, you may address your complaint to the Complaints Officer/Ombudsman:</p>
            <p><u>For Visitors to Canada Emergency Medical Insurance Plans, Travel Right Insurance Plans, and the Student Accident Plan:</u><br/>
            Old Republic Insurance Company of Canada, Box 557, Hamilton, ON  L8N 3K9<br/>
            E-mail: <a href="mailto:compliance@orican.com">compliance@orican.com</a><br/>
            Fax: 1-866-551-1702</p>
            <p><u>For the International Student Travel Insurance Policy: </u><br/>
            Zurich Insurance Company Ltd (Canadian Branch), 5500-100 King Street West, Toronto, ON M5X 1C9<br/>
            Email: ombudsman.zurich.canada@zurich.com<br/>
            Phone: 1-416-586-6773</p>
            <p>When communicating with the Complaints Officer/Ombudsman:</p>
              <ol type="i">
                <li>We ask that you make your complaint to us in writing. At the top of your letter write the word “Complaint”;</li>
                <li>Provide your contact information;</li>
                <li>Describe the reason for your complaint, the steps you have already taken, and the response you have received; and </li>
                <li>Present your cas
                e and describe your desired outcome.</li>
              </ol>
            <p>The Complaints Officer/Ombudsman will conduct a review and ensure that the company’s decision and reasons are communicated to you in writing. The response will also notify you of your right to have your complaint reviewed by an independent third party.</p>
            <h2>Contact for independent dispute resolution service for Life &amp; Health (Travel) insurance complaints:</h2>
            <p>If you wish to pursue the matter further, you may contact the OmbudService for Life & Health Insurance (OLHI) which deals with concerns about life and health insurance products and services that have not been resolved through the company’s dispute resolution system.</p>
            <p>To learn more about the complaint services of the OLHI visit <a href="https://olhi.ca/" target="_blank" rel="noreferrer">www.olhi.ca</a></p>
            <p>Note: All correspondence should be sent to OLHI Toronto office.</p>
            <p>OmbudService for Life &amp; Health Insurance (OLHI)<br/>
            2 Bloor Street West, Suite 700<br/>
            Toronto, ON M4W 3E2</p>
            <p>Phone:<br/>
            Toronto 416-777-9002<br/>
            Montreal 514-282-2088<br/>
            Edmonton 780-643-6165<br/>
            Toll Free Canada 1-888-295-8112<br/>
            Toll Free Quebec 1-866-582-2088<br/>
            Bell Relay Service (hearing impaired): 1-800-855-0511</p>
            <p>Note: OLHI may take on your case if, after receiving our final response, you would like to pursue the matter further, or if you have not received our final response in 90 days.</p>
            <h2>Regulatory bodies and other resources</h2>
            <p>There are a number of resources available to consumers. Depending on the nature of your complaint you may wish to contact government regulators at the provincial or federal level.</p>
            <h2>Financial Services Regulatory Authority of Ontario (FSRA) - ONTARIO</h2>
            <p>The Financial Services Regulatory Authority of Ontario (FSRA) is an independent regulatory agency created to improve consumer protections in Ontario. FSRA was established to replace the Financial Services Commission of Ontario (FSCO) and the Deposit Insurance Corporation of Ontario (DICO). The agency is flexible, self-funded and designed to respond rapidly to an evolving commercial and consumer environment.</p>
            <p>Ontario residents wishing to involve the FSRA with a complaint need to complete an online form, which is found here: <a href="https://www.fsrao.ca/submit-complaint-fsra" target="_blank" rel="noreferrer">https://www.fsrao.ca/consumers/ask-question-file-complaint-or-report-fraud</a></p>
            <p>Financial Services Regulatory Authority of Ontario<br/>
            25 Sheppard Ave W Suite 100,<br/>
            North York, ON M2N 6S6</p>
            <p>Website: <a href="https://www.fsrao.ca" target="_blank" rel="noreferrer">https://www.fsrao.ca/</a><br/>
            Email: <a href="mailto:contactcentre@fsrao.ca" target="_blank" rel="noreferrer">contactcentre@fsrao.ca</a><br/>
            Telephone: 416-250-7250<br/>
            Toll-free: 1-800-668-0128</p>
            <h2>Autorité des marches financiers (AMF) - QUEBEC</h2>
            <p>The Autorité des marches financiers (AMF) is the body mandated by the Québec government to regulate Québec’s financial markets and assist consumers of financial products and services.</p>
            <p>If you are not satisfied with our company position and the outcome of the OLHI review, and wish to pursue the matter further, you may choose to have your complaint file transferred to The Autorité des marches financiers (AMF). You can contact the AMF, by calling toll free, <a href="tel:1-877-525-0337" target="_blank" rel="noreferrer">1-877-525-0337</a> on their website at <a href="https://www.lautorite.qc.ca" target="_blank" rel="noreferrer">www.lautorite.qc.ca</a>, by e-mail to: <a href="mailto:information@lautorite.qc.ca" target="_blank" rel="noreferrer">information@lautorite.qc.ca</a> or in writing to:</p>

            <p>Autorité des marches financiers<br/>
            Place de la cité, Tour Cominar<br/>
            2640 boulevard Laurier, 3e étage<br/>
            Sainte-Foy, Québec G1V 5C1</p>

          </div>
        </div>
      </div>
    </div>
    <Footer lang={GLobalLinks["complaint-resolution-process"]['lang']} />
    </>
  );
}

export default ComplaintResPro;
